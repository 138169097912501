import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RutserviceService {

  constructor() { }

  RutHelper:any = {

    formatearFull:  function(rut: string, skipDots: boolean): any
    {
        var noFormat = this.quitarFormato(rut);
        var run = [noFormat.slice(0, noFormat.length-1), '-', noFormat.slice(noFormat.length-1)].join('');
        var formattedRun = run.split('-');
        return this.formatear(formattedRun[0]+formattedRun[1],formattedRun[1],skipDots);
    },

    formatear:  function(rut: string, digitoVerificador: any,skipDots: boolean): any
    {
        var sRut = new String(rut);
        var sRutFormateado = '';
        sRut = this.quitarFormato(sRut);
        var sDV;
        if(digitoVerificador){
            sDV = sRut.charAt(sRut.length-1);
            sRut = sRut.substring(0, sRut.length-1);
        }
        if(!skipDots){
            while( sRut.length > 3 )
            {
                sRutFormateado = "." + sRut.substr(sRut.length - 3) + sRutFormateado;
                sRut = sRut.substring(0, sRut.length - 3);
            }
        }
        sRutFormateado = sRut + sRutFormateado;
        if(sRutFormateado != "" && digitoVerificador)
        {
            sRutFormateado += "-" + sDV;
        }
        else if(digitoVerificador)
        {
            sRutFormateado += sDV;
        }

        return sRutFormateado;
    },

    quitarFormato: function(rut: string): any
    {
        var strRut = new String(rut);
        while( strRut.indexOf(".") != -1 )
        {
            strRut = strRut.replace(".","");
        }
        while( strRut.indexOf("-") != -1 )
        {
            strRut = strRut.replace("-","");
        }

        return strRut;
    },

    digitoValido: function(dv: string): any
    {
        if ( dv != '0' && dv != '1' && dv != '2' && dv != '3' && dv != '4'
            && dv != '5' && dv != '6' && dv != '7' && dv != '8' && dv != '9'
            && dv != 'k'  && dv != 'K')
        {
            return false;
        }
        return true;
    },


    digitoCorrecto:   function(crut: string)
    {
        let largo = crut.length;
        let rut;
        if ( largo < 2 )
        {
            return false;
        }
        if(largo > 2)
        {
            rut = crut.substring(0, largo - 1);
        }
        else
        {
            rut = crut.charAt(0);
        }
        let dv = crut.charAt(largo-1);
        
      
        this.digitoValido(dv);

        if(rut == null || dv == null)
        {
            return 0;
        }

        let dvr = this.getDigito(rut);

        if (dvr != dv.toLowerCase())
        {
            return false;
        }
        return true;
    },

    getDigito:    function(rut: any)
    {
        var dvr = '0';
        let suma = 0;
        let mul  = 2;
        for(let i=rut.length -1;i >= 0;i--)
        {
            suma = suma + rut.charAt(i) * mul;
            if (mul == 7)
            {
                mul = 2;
            }
            else
            {
                mul++;
            }
        }
        let res = suma % 11;
        if (res==1)
        {
            return 'k';
        }
        else if(res==0)
        {
            return '0';
        }
        else
        {
            return 11-res;
        }
    },

    isValid:    function(rut: any) {
        if(rut.length > 2){
            let tRut = this.formatearFull(rut, true).split("-");
            let run = tRut[0];
            let dv = tRut[1];
    
            return this.getDigito(run) == dv.toLowerCase();
        } else {
            return false;
        }
    }

};



}
