import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../shared/users.service'
import { SettingsService } from '../shared/settings.service'
import { LanguageApp } from '../shared/dataTableLang';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { OrganizacionesService } from '../shared/organizaciones.service';

import { environment } from 'src/environments/environment';

import {
  allUsers,
} from '../store/users.selector'
import { UsersModel } from './users.model';

import { RutserviceService } from '../rutservice.service';
import { validate, format } from 'rut.js';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import userdata from '../store/userdata/userdata.interface';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { selectUserData } from '../store/userdata/userdata.selector';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})

export class UsersComponent implements OnInit {
  loading: boolean = true;
  @Input() orgId!: any;
  users!: any[];
  usrObj!: any;
  closeModal: string = "";
  currentUser: any = {};
  thisUser: any = {};
  organizaciones: any = [];
  id_organizacion: string = '';
  nuevoUsuario: any = {
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    rol: -1,
    organizacion: -1,
    region: ''
  };
  usrData: any;
  canCreate: boolean = false;
  isAdmin: boolean = false;
  showMsg = false;
  uiMsg = '';
  regiones: any = [];
  public List$: Observable<userdata>
  constructor(
    private usersService: UsersService,
    private orgServices: OrganizacionesService,
    private settingsService: SettingsService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private elemRef: ElementRef,
    private rutval: RutserviceService,
    private store: Store<IUserDataState>
  ) {

    this.validatorRut = rutval
    this.List$ = this.store.pipe(select(selectUserData))
  }
  private validatorRut: RutserviceService;

  crearUsuarioForm!: FormGroup;
  editarUsuarioForm!: FormGroup;
  borrarUsuarioForm!: FormGroup;

  closeMsg() {
    this.showMsg = false;
    this.uiMsg = '';
  }

  triggerModal(content: any) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
      this.closeModal = `Closed with: ${res}`;
    }, (res) => {
      this.closeModal = `Dismissed`;
    });
  }

  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: []
  }

  async getRol(rol: number) {
    let rolObj = await this.settings.roles.find((r: any) => r.type == 'rol' && r.id == rol);
    if (rolObj) {
      return rolObj.glosa;
    }
  }

  getUsers() {
    this.usersService.loadUsers().subscribe((users: any) => {
      this.users = !this.orgId ? users : (users.filter((u: any) => u.organizacion == this.orgId));
      this.users.sort((a, b) => a.rol > b.rol ? 1 : -1);
      this.users.forEach((u) => {
        this.getRol(u.rol).then(v => {
          u.roleName = v;
        })

      });
      $('#dataTable').DataTable().destroy();
      let that = this;
      setTimeout(function () {
        $('#dataTable').DataTable({
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          lengthMenu: [5, 10, 15],
          language: LanguageApp.spanish_datatables
        });
      }, 0);

      that.loading = false;

    });
  }

  changeOrg(): void {
    //console.log(this.nuevoUsuario);
  }



  checkUserRole() {
    this.List$.subscribe(data => {
      //let d = JSON.parse(atob(sessionStorage.boToken.split(".")[1]));
      this.usrData = data;

      switch (this.usrData.rol.codigo) {
        case 0:
          this.isAdmin = true;
          this.canCreate = true;
          break;
        case 1:
          this.canCreate = true;
          break;
      }


    })

  }

  valRut(evt: Event, context: string) {
    let el: any = evt.target;
    if (validate(el.value.trim())) {
      el.value = format(el.value, { dots: false });
    }
    if (!validate(el.value.trim())) {
      this.uiMsg = "Debe ingresar un RUN válido"
      this.showMsg = true;
      el.focus();
    } else {
      this.uiMsg = ""
      this.showMsg = false;
      if (context === "current") this.currentUser.username = el.value;
      if (context === "nuevoUsuario") this.nuevoUsuario.username = el.value;
    }
  }

  valEmail(evt: Event) {
    let el: any = evt.target;
    if (el.value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/)) {
      this.uiMsg = ""
      this.showMsg = false;
      this.nuevoUsuario.email = el.value;
    } else {
      this.uiMsg = "Debe ingresar un correo electrónico válido para el usuario a crear"
      this.showMsg = true;

    }
  }

  ngOnInit(): void {
    this.checkUserRole();
    this.settingsService.loadSettings().toPromise().then((settings: any) => {

      this.settings.roles = settings.filter((s: any) => s.type == 'rol');
      this.settings.estados = settings.filter((s: any) => s.type == 'estado');
      this.settings.materias = settings.filter((s: any) => s.type == 'materia');
      this.settings.slas = settings.filter((s: any) => s.type == 'sla');

      this.getUsers();

      this.nuevoUsuario.organizacion = this.orgId || 0;
    });

    this.crearUsuarioForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      rol: [-1, [Validators.required]],

    });

    this.editarUsuarioForm = this.formBuilder.group({
      username: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required]],
      rol: [[Validators.required]],

    });
    /*
        this.borrarUsuarioForm = this.formBuilder.group({
          username: ['', [Validators.required]],
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          email: ['', [Validators.required]],
          rol: [ [Validators.required]],

    });*/

    let sesUsr: any = sessionStorage.getItem("boToken");
    this.usrObj = JSON.parse(atob(sesUsr.split(".")[1] || ''));
    if (this.usrObj.rol.codigo == 0) {
      this.orgServices.loadOrganizaciones().toPromise().then((orgs: any) => {
        this.organizaciones = orgs;
      });
      this.nuevoUsuario.organizacion = this.usrObj.organizacion.id_organizacion;
    } else {
      this.nuevoUsuario.organizacion = sessionStorage.idOrganizacion;
    }

    this.regiones = environment.regiones;

  }

  crearUsuario(content: any) {
    this.id_organizacion = sessionStorage.idOrganizacion;
    this.triggerModal(content);
  }

  editarUsuario(content: any, userId: string,) {


    this.currentUser = this.users.find(u => u.username == userId);


    // console.log(userId, this.currentUser);

    this.triggerModal(content);
  }

  crearUsuarioSubmit(evt: Event) {

    evt.preventDefault();

    if (this.nuevoUsuario.username.trim() == '' || this.nuevoUsuario.firstName.trim() == '' ||
      this.nuevoUsuario.lastName.trim() == '' || this.nuevoUsuario.rol == -1 || this.nuevoUsuario.email == ''
    ) {
      this.uiMsg = "Debe completar los campos requeridos para crear un nuevo usuario"
      this.showMsg = true;
      this.nuevoUsuario.organizacion = 0;
    } else if (this.nuevoUsuario.organizacion == -1 && this.nuevoUsuario.region == -1) {
      this.uiMsg = "Al crear un usuario para un funcionario del SRCeI se debe indicar la región de atención."
      this.showMsg = true;

    } else {

      this.uiMsg = "";
      this.showMsg = false;
      this.nuevoUsuario.organizacion = this.nuevoUsuario.organizacion == -1 ?
        this.usrObj.organizacion.id_organizacion :
        this.nuevoUsuario.organizacion;

      //FIX A PASSWORD = RUT

      if (this.rutval.RutHelper.isValid(this.nuevoUsuario.username)) {
        this.uiMsg = "";
        this.showMsg = false;

        this.nuevoUsuario.password = this.nuevoUsuario.username.split('-')[0];

        // this.nuevoUsuario.organizacion = this.orgId;
        this.usersService.createUser(this.nuevoUsuario).subscribe((user: any) => {

          this.getUsers();
          this.nuevoUsuario = {
            username: '',
            firstName: '',
            lastName: '',
            email: '',
            rol: -1,
            organizacion: -1,
            region: ''
          };
          this.modalService.dismissAll();
        },
          (err: any) => {
            this.uiMsg = "Se ha producido un error creando el usuario: " + err.message;
            this.showMsg = true;
          }
        );

      } else {

        this.uiMsg = "El RUN ingresado no es válido";
        this.showMsg = true;
      }
    }

  }

  editarUsuarioSubmit(evt: Event) {
    evt.preventDefault();

    this.usersService.updateUser({ ...this.currentUser }).subscribe((user: any) => {
      this.getRol(parseInt(this.currentUser.rol)).then(r => {
        this.currentUser.roleName = r;
        this.users.forEach(u => {
          if (u.username == this.currentUser.username) {
            u = this.currentUser;
          }
        })
      });
    });

    this.modalService.dismissAll();
  }

  borrarUsuario(content: any, username: string) {

    this.currentUser = this.users.find(u => u.username == username);

    this.triggerModal(content);

    const userData = {
      username
    }

    return;
    this.usersService.borrarUser(userData)
      .subscribe((user: any) => {

        if (user.success) {
          this.getUsers()

        }
        // this.getRol(parseInt(this.currentUser.rol)).then(r => {
        //   this.currentUser.roleName = r;

        // });
      });
  }

  deleteUsuarioSubmit(evt: Event) {

    evt.preventDefault();
    const userData = {
      username: this.currentUser.username
    }


    this.usersService.borrarUser(userData)
      .subscribe((user: any) => {

        if (user.success) {
          this.getUsers()

        }
        this.modalService.dismissAll();
      });
  }

  confirEmail = (evt: any) => {
    let confirEmail: HTMLInputElement = evt.target;
    if (confirEmail.value === '') {
      this.uiMsg = "Debe completar el campo";
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else if (confirEmail.value.toLowerCase().trim() != this.nuevoUsuario.email.toLowerCase().trim()) {
      this.uiMsg = "Los correos deben coincidir";
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else {
      this.uiMsg = "";
      this.showMsg = false;
      document.removeEventListener('click', this.cancelClickEvent);
    }
  }

  cancelClickEvent = (event: Event) => {
    if (this.showMsg) {
      event.preventDefault();
    }
  }

  confirEmail_update = (evt: any) => {
    let confirEmail_update: HTMLInputElement = evt.target;

    if (confirEmail_update.value === '') {
      this.uiMsg = "Debe completar el campo";
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else if (confirEmail_update.value.toLowerCase().trim() != this.currentUser.email.toLowerCase().trim()) {
      this.uiMsg = "Los correos deben coincidir";
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else {
      this.uiMsg = "";
      this.showMsg = false;
      document.removeEventListener('click', this.cancelClickEvent);
    }
  }

}
