export const environment = {
    production: true,
    API_GESTION_ART44: 'https://gestion.articulo44.srcei.cl/api/art44/',
    apiSRCEI: 'https://apis.srcei.cl/apis/articulo44/solicitudes',
    boBackendAPI: 'https://solicitarun.srcei.cl/api',
    hora_inicio_atencion: '12:00',
    hora_fin_atencion: '13:00',
    url_lista_oficinas: 'https://www.registrocivil.cl/PortalOI/articulo44/',
    plazo_presentacion: 60,
    api_oficinas: 'https://agenda.qa.registrocivil.cl/api/backend/oficinas/region',
    captchaSiteKey: '6LcgiGYaAAAAAJnICEmrC4Yxo6KOkm-Qk3tqn_TV',
    appTitle: 'Artículo 44',
    appDescription: 'Sistema de gestión de solicitudes de RUN para extranjeros.',
    claveUnica_client_id: 'e3e84c62a62b45cba01cbc8326665a50',
    claveUnica_client_secret: '2499064d941949c485626769586f3e09',
    claveUnica_scope: 'openid run name',
    claveUnica_redirect_uri: 'https://solicitarunqa.registrocivil.cl',
    claveUnica_state: '754b4e6174dd9e23cc383a44befe37',
    regiones: [
        {
            "codigo_region": 1,
            "nombre_region": "Region De Tarapaca",
            "comunas": [
                {
                    "codigo_comuna": 5,
                    "codigo_region": 1,
                    "nombre_comuna": "Iquique"
                },
                {
                    "codigo_comuna": 6,
                    "codigo_region": 1,
                    "nombre_comuna": "Huara"
                },
                {
                    "codigo_comuna": 7,
                    "codigo_region": 1,
                    "nombre_comuna": "Camiña"
                },
                {
                    "codigo_comuna": 8,
                    "codigo_region": 1,
                    "nombre_comuna": "Colchane"
                },
                {
                    "codigo_comuna": 9,
                    "codigo_region": 1,
                    "nombre_comuna": "Pica"
                },
                {
                    "codigo_comuna": 10,
                    "codigo_region": 1,
                    "nombre_comuna": "Pozo Almonte"
                },
                {
                    "codigo_comuna": 394,
                    "codigo_region": 1,
                    "nombre_comuna": "Alto Hospicio"
                }
            ]
        },
        {
            "codigo_region": 2,
            "nombre_region": "Region De Antofagasta",
            "comunas": [
                {
                    "codigo_comuna": 11,
                    "codigo_region": 2,
                    "nombre_comuna": "Tocopilla"
                },
                {
                    "codigo_comuna": 12,
                    "codigo_region": 2,
                    "nombre_comuna": "María Elena"
                },
                {
                    "codigo_comuna": 13,
                    "codigo_region": 2,
                    "nombre_comuna": "Calama"
                },
                {
                    "codigo_comuna": 14,
                    "codigo_region": 2,
                    "nombre_comuna": "Ollagüe"
                },
                {
                    "codigo_comuna": 15,
                    "codigo_region": 2,
                    "nombre_comuna": "San Pedro De Atacama"
                },
                {
                    "codigo_comuna": 16,
                    "codigo_region": 2,
                    "nombre_comuna": "Antofagasta"
                },
                {
                    "codigo_comuna": 17,
                    "codigo_region": 2,
                    "nombre_comuna": "Mejillones"
                },
                {
                    "codigo_comuna": 18,
                    "codigo_region": 2,
                    "nombre_comuna": "Sierra Gorda"
                },
                {
                    "codigo_comuna": 19,
                    "codigo_region": 2,
                    "nombre_comuna": "Taltal"
                }
            ]
        },
        {
            "codigo_region": 3,
            "nombre_region": "Region De Atacama",
            "comunas": [
                {
                    "codigo_comuna": 20,
                    "codigo_region": 3,
                    "nombre_comuna": "Chañaral"
                },
                {
                    "codigo_comuna": 21,
                    "codigo_region": 3,
                    "nombre_comuna": "Diego De Almagro"
                },
                {
                    "codigo_comuna": 22,
                    "codigo_region": 3,
                    "nombre_comuna": "Copiapó"
                },
                {
                    "codigo_comuna": 23,
                    "codigo_region": 3,
                    "nombre_comuna": "Caldera"
                },
                {
                    "codigo_comuna": 24,
                    "codigo_region": 3,
                    "nombre_comuna": "Tierra Amarilla"
                },
                {
                    "codigo_comuna": 25,
                    "codigo_region": 3,
                    "nombre_comuna": "Vallenar"
                },
                {
                    "codigo_comuna": 26,
                    "codigo_region": 3,
                    "nombre_comuna": "Freirina"
                },
                {
                    "codigo_comuna": 27,
                    "codigo_region": 3,
                    "nombre_comuna": "Huasco"
                },
                {
                    "codigo_comuna": 28,
                    "codigo_region": 3,
                    "nombre_comuna": "Alto Del Carmen"
                }
            ]
        },
        {
            "codigo_region": 4,
            "nombre_region": "Region De Coquimbo",
            "comunas": [
                {
                    "codigo_comuna": 29,
                    "codigo_region": 4,
                    "nombre_comuna": "La Serena"
                },
                {
                    "codigo_comuna": 30,
                    "codigo_region": 4,
                    "nombre_comuna": "La Higuera"
                },
                {
                    "codigo_comuna": 31,
                    "codigo_region": 4,
                    "nombre_comuna": "Coquimbo"
                },
                {
                    "codigo_comuna": 32,
                    "codigo_region": 4,
                    "nombre_comuna": "Andacollo"
                },
                {
                    "codigo_comuna": 33,
                    "codigo_region": 4,
                    "nombre_comuna": "Vicuña"
                },
                {
                    "codigo_comuna": 34,
                    "codigo_region": 4,
                    "nombre_comuna": "Paiguano"
                },
                {
                    "codigo_comuna": 35,
                    "codigo_region": 4,
                    "nombre_comuna": "Ovalle"
                },
                {
                    "codigo_comuna": 36,
                    "codigo_region": 4,
                    "nombre_comuna": "Río Hurtado"
                },
                {
                    "codigo_comuna": 37,
                    "codigo_region": 4,
                    "nombre_comuna": "Monte Patria"
                },
                {
                    "codigo_comuna": 38,
                    "codigo_region": 4,
                    "nombre_comuna": "Combarbalá"
                },
                {
                    "codigo_comuna": 39,
                    "codigo_region": 4,
                    "nombre_comuna": "Punitaqui"
                },
                {
                    "codigo_comuna": 40,
                    "codigo_region": 4,
                    "nombre_comuna": "Illapel"
                },
                {
                    "codigo_comuna": 41,
                    "codigo_region": 4,
                    "nombre_comuna": "Salamanca"
                },
                {
                    "codigo_comuna": 42,
                    "codigo_region": 4,
                    "nombre_comuna": "Los Vilos"
                },
                {
                    "codigo_comuna": 43,
                    "codigo_region": 4,
                    "nombre_comuna": "Canela"
                }
            ]
        },
        {
            "codigo_region": 5,
            "nombre_region": "Region De Valparaiso",
            "comunas": [
                {
                    "codigo_comuna": 44,
                    "codigo_region": 5,
                    "nombre_comuna": "La Ligua"
                },
                {
                    "codigo_comuna": 45,
                    "codigo_region": 5,
                    "nombre_comuna": "Petorca"
                },
                {
                    "codigo_comuna": 46,
                    "codigo_region": 5,
                    "nombre_comuna": "Cabildo"
                },
                {
                    "codigo_comuna": 47,
                    "codigo_region": 5,
                    "nombre_comuna": "Zapallar"
                },
                {
                    "codigo_comuna": 48,
                    "codigo_region": 5,
                    "nombre_comuna": "Papudo"
                },
                {
                    "codigo_comuna": 49,
                    "codigo_region": 5,
                    "nombre_comuna": "Los Andes"
                },
                {
                    "codigo_comuna": 50,
                    "codigo_region": 5,
                    "nombre_comuna": "San Esteban"
                },
                {
                    "codigo_comuna": 51,
                    "codigo_region": 5,
                    "nombre_comuna": "Calle Larga"
                },
                {
                    "codigo_comuna": 52,
                    "codigo_region": 5,
                    "nombre_comuna": "Rinconada"
                },
                {
                    "codigo_comuna": 53,
                    "codigo_region": 5,
                    "nombre_comuna": "San Felipe"
                },
                {
                    "codigo_comuna": 54,
                    "codigo_region": 5,
                    "nombre_comuna": "Putaendo"
                },
                {
                    "codigo_comuna": 55,
                    "codigo_region": 5,
                    "nombre_comuna": "Santa María"
                },
                {
                    "codigo_comuna": 56,
                    "codigo_region": 5,
                    "nombre_comuna": "Panquehue"
                },
                {
                    "codigo_comuna": 57,
                    "codigo_region": 5,
                    "nombre_comuna": "Llay Llay"
                },
                {
                    "codigo_comuna": 58,
                    "codigo_region": 5,
                    "nombre_comuna": "Catemu"
                },
                {
                    "codigo_comuna": 59,
                    "codigo_region": 5,
                    "nombre_comuna": "Quillota"
                },
                {
                    "codigo_comuna": 60,
                    "codigo_region": 5,
                    "nombre_comuna": "La Cruz"
                },
                {
                    "codigo_comuna": 61,
                    "codigo_region": 5,
                    "nombre_comuna": "Calera"
                },
                {
                    "codigo_comuna": 62,
                    "codigo_region": 5,
                    "nombre_comuna": "Nogales"
                },
                {
                    "codigo_comuna": 63,
                    "codigo_region": 5,
                    "nombre_comuna": "Hijuelas"
                },
                {
                    "codigo_comuna": 64,
                    "codigo_region": 5,
                    "nombre_comuna": "Limache"
                },
                {
                    "codigo_comuna": 65,
                    "codigo_region": 5,
                    "nombre_comuna": "Olmué"
                },
                {
                    "codigo_comuna": 66,
                    "codigo_region": 5,
                    "nombre_comuna": "Valparaíso"
                },
                {
                    "codigo_comuna": 67,
                    "codigo_region": 5,
                    "nombre_comuna": "Viña Del Mar"
                },
                {
                    "codigo_comuna": 68,
                    "codigo_region": 5,
                    "nombre_comuna": "Quintero"
                },
                {
                    "codigo_comuna": 69,
                    "codigo_region": 5,
                    "nombre_comuna": "Puchuncaví"
                },
                {
                    "codigo_comuna": 70,
                    "codigo_region": 5,
                    "nombre_comuna": "Quilpué"
                },
                {
                    "codigo_comuna": 71,
                    "codigo_region": 5,
                    "nombre_comuna": "Villa Alemana"
                },
                {
                    "codigo_comuna": 72,
                    "codigo_region": 5,
                    "nombre_comuna": "Casablanca"
                },
                {
                    "codigo_comuna": 73,
                    "codigo_region": 5,
                    "nombre_comuna": "Juan Fernández"
                },
                {
                    "codigo_comuna": 74,
                    "codigo_region": 5,
                    "nombre_comuna": "San Antonio"
                },
                {
                    "codigo_comuna": 75,
                    "codigo_region": 5,
                    "nombre_comuna": "Cartagena"
                },
                {
                    "codigo_comuna": 76,
                    "codigo_region": 5,
                    "nombre_comuna": "El Tabo"
                },
                {
                    "codigo_comuna": 77,
                    "codigo_region": 5,
                    "nombre_comuna": "El Quisco"
                },
                {
                    "codigo_comuna": 78,
                    "codigo_region": 5,
                    "nombre_comuna": "Algarrobo"
                },
                {
                    "codigo_comuna": 79,
                    "codigo_region": 5,
                    "nombre_comuna": "Santo Domingo"
                },
                {
                    "codigo_comuna": 80,
                    "codigo_region": 5,
                    "nombre_comuna": "Isla De Pascua"
                },
                {
                    "codigo_comuna": 377,
                    "codigo_region": 5,
                    "nombre_comuna": "Concón"
                },
                {
                    "codigo_comuna": 382,
                    "codigo_region": 5,
                    "nombre_comuna": "Llay Llay"
                }
            ]
        },
        {
            "codigo_region": 6,
            "nombre_region": "Region Del Libertador General Bernardo Ohiggins",
            "comunas": [
                {
                    "codigo_comuna": 132,
                    "codigo_region": 6,
                    "nombre_comuna": "Rancagua"
                },
                {
                    "codigo_comuna": 133,
                    "codigo_region": 6,
                    "nombre_comuna": "Graneros"
                },
                {
                    "codigo_comuna": 134,
                    "codigo_region": 6,
                    "nombre_comuna": "Mostazal"
                },
                {
                    "codigo_comuna": 135,
                    "codigo_region": 6,
                    "nombre_comuna": "Codegua"
                },
                {
                    "codigo_comuna": 136,
                    "codigo_region": 6,
                    "nombre_comuna": "Machalí"
                },
                {
                    "codigo_comuna": 137,
                    "codigo_region": 6,
                    "nombre_comuna": "Olivar"
                },
                {
                    "codigo_comuna": 138,
                    "codigo_region": 6,
                    "nombre_comuna": "Requínoa"
                },
                {
                    "codigo_comuna": 139,
                    "codigo_region": 6,
                    "nombre_comuna": "Rengo"
                },
                {
                    "codigo_comuna": 140,
                    "codigo_region": 6,
                    "nombre_comuna": "Malloa"
                },
                {
                    "codigo_comuna": 141,
                    "codigo_region": 6,
                    "nombre_comuna": "Quinta De Tilcoco"
                },
                {
                    "codigo_comuna": 142,
                    "codigo_region": 6,
                    "nombre_comuna": "San Vicente De Tagua Tagua"
                },
                {
                    "codigo_comuna": 143,
                    "codigo_region": 6,
                    "nombre_comuna": "Pichidegua"
                },
                {
                    "codigo_comuna": 144,
                    "codigo_region": 6,
                    "nombre_comuna": "Peumo"
                },
                {
                    "codigo_comuna": 145,
                    "codigo_region": 6,
                    "nombre_comuna": "Coltauco"
                },
                {
                    "codigo_comuna": 146,
                    "codigo_region": 6,
                    "nombre_comuna": "Coinco"
                },
                {
                    "codigo_comuna": 147,
                    "codigo_region": 6,
                    "nombre_comuna": "Doñihue"
                },
                {
                    "codigo_comuna": 148,
                    "codigo_region": 6,
                    "nombre_comuna": "Las Cabras"
                },
                {
                    "codigo_comuna": 149,
                    "codigo_region": 6,
                    "nombre_comuna": "San Fernando"
                },
                {
                    "codigo_comuna": 150,
                    "codigo_region": 6,
                    "nombre_comuna": "Chimbarongo"
                },
                {
                    "codigo_comuna": 151,
                    "codigo_region": 6,
                    "nombre_comuna": "Placilla"
                },
                {
                    "codigo_comuna": 152,
                    "codigo_region": 6,
                    "nombre_comuna": "Nancagua"
                },
                {
                    "codigo_comuna": 153,
                    "codigo_region": 6,
                    "nombre_comuna": "Chépica"
                },
                {
                    "codigo_comuna": 154,
                    "codigo_region": 6,
                    "nombre_comuna": "Santa Cruz"
                },
                {
                    "codigo_comuna": 155,
                    "codigo_region": 6,
                    "nombre_comuna": "Lolol"
                },
                {
                    "codigo_comuna": 156,
                    "codigo_region": 6,
                    "nombre_comuna": "Pumanque"
                },
                {
                    "codigo_comuna": 157,
                    "codigo_region": 6,
                    "nombre_comuna": "Palmilla"
                },
                {
                    "codigo_comuna": 158,
                    "codigo_region": 6,
                    "nombre_comuna": "Peralillo"
                },
                {
                    "codigo_comuna": 159,
                    "codigo_region": 6,
                    "nombre_comuna": "Pichilemu"
                },
                {
                    "codigo_comuna": 160,
                    "codigo_region": 6,
                    "nombre_comuna": "Navidad"
                },
                {
                    "codigo_comuna": 161,
                    "codigo_region": 6,
                    "nombre_comuna": "Litueche"
                },
                {
                    "codigo_comuna": 162,
                    "codigo_region": 6,
                    "nombre_comuna": "La Estrella"
                },
                {
                    "codigo_comuna": 163,
                    "codigo_region": 6,
                    "nombre_comuna": "Marchihue"
                },
                {
                    "codigo_comuna": 164,
                    "codigo_region": 6,
                    "nombre_comuna": "Paredones"
                },
                {
                    "codigo_comuna": 384,
                    "codigo_region": 6,
                    "nombre_comuna": "San Vicente De Tagua Tagua"
                }
            ]
        },
        {
            "codigo_region": 7,
            "nombre_region": "Region Del Maule",
            "comunas": [
                {
                    "codigo_comuna": 165,
                    "codigo_region": 7,
                    "nombre_comuna": "Curicó"
                },
                {
                    "codigo_comuna": 166,
                    "codigo_region": 7,
                    "nombre_comuna": "Teno"
                },
                {
                    "codigo_comuna": 167,
                    "codigo_region": 7,
                    "nombre_comuna": "Romeral"
                },
                {
                    "codigo_comuna": 168,
                    "codigo_region": 7,
                    "nombre_comuna": "Molina"
                },
                {
                    "codigo_comuna": 169,
                    "codigo_region": 7,
                    "nombre_comuna": "Sagrada Familia"
                },
                {
                    "codigo_comuna": 170,
                    "codigo_region": 7,
                    "nombre_comuna": "Hualañé"
                },
                {
                    "codigo_comuna": 171,
                    "codigo_region": 7,
                    "nombre_comuna": "Licantén"
                },
                {
                    "codigo_comuna": 172,
                    "codigo_region": 7,
                    "nombre_comuna": "Vichuquén"
                },
                {
                    "codigo_comuna": 173,
                    "codigo_region": 7,
                    "nombre_comuna": "Rauco"
                },
                {
                    "codigo_comuna": 174,
                    "codigo_region": 7,
                    "nombre_comuna": "Talca"
                },
                {
                    "codigo_comuna": 175,
                    "codigo_region": 7,
                    "nombre_comuna": "Pelarco"
                },
                {
                    "codigo_comuna": 176,
                    "codigo_region": 7,
                    "nombre_comuna": "Río Claro"
                },
                {
                    "codigo_comuna": 177,
                    "codigo_region": 7,
                    "nombre_comuna": "San Clemente"
                },
                {
                    "codigo_comuna": 178,
                    "codigo_region": 7,
                    "nombre_comuna": "Maule"
                },
                {
                    "codigo_comuna": 179,
                    "codigo_region": 7,
                    "nombre_comuna": "Empedrado"
                },
                {
                    "codigo_comuna": 180,
                    "codigo_region": 7,
                    "nombre_comuna": "Pencahue"
                },
                {
                    "codigo_comuna": 181,
                    "codigo_region": 7,
                    "nombre_comuna": "Constitución"
                },
                {
                    "codigo_comuna": 182,
                    "codigo_region": 7,
                    "nombre_comuna": "Curepto"
                },
                {
                    "codigo_comuna": 183,
                    "codigo_region": 7,
                    "nombre_comuna": "Linares"
                },
                {
                    "codigo_comuna": 184,
                    "codigo_region": 7,
                    "nombre_comuna": "Yerbas Buenas"
                },
                {
                    "codigo_comuna": 185,
                    "codigo_region": 7,
                    "nombre_comuna": "Colbún"
                },
                {
                    "codigo_comuna": 186,
                    "codigo_region": 7,
                    "nombre_comuna": "Longaví"
                },
                {
                    "codigo_comuna": 187,
                    "codigo_region": 7,
                    "nombre_comuna": "Parral"
                },
                {
                    "codigo_comuna": 188,
                    "codigo_region": 7,
                    "nombre_comuna": "Retiro"
                },
                {
                    "codigo_comuna": 189,
                    "codigo_region": 7,
                    "nombre_comuna": "Villa Alegre"
                },
                {
                    "codigo_comuna": 190,
                    "codigo_region": 7,
                    "nombre_comuna": "San Javier"
                },
                {
                    "codigo_comuna": 191,
                    "codigo_region": 7,
                    "nombre_comuna": "Cauquenes"
                },
                {
                    "codigo_comuna": 192,
                    "codigo_region": 7,
                    "nombre_comuna": "Pelluhue"
                },
                {
                    "codigo_comuna": 193,
                    "codigo_region": 7,
                    "nombre_comuna": "Chanco"
                },
                {
                    "codigo_comuna": 378,
                    "codigo_region": 7,
                    "nombre_comuna": "San Rafael"
                }
            ]
        },
        {
            "codigo_region": 8,
            "nombre_region": "Region Del Bio Bio",
            "comunas": [
                {
                    "codigo_comuna": 214,
                    "codigo_region": 8,
                    "nombre_comuna": "Los Angeles"
                },
                {
                    "codigo_comuna": 215,
                    "codigo_region": 8,
                    "nombre_comuna": "Cabrero"
                },
                {
                    "codigo_comuna": 216,
                    "codigo_region": 8,
                    "nombre_comuna": "Tucapel"
                },
                {
                    "codigo_comuna": 217,
                    "codigo_region": 8,
                    "nombre_comuna": "Antuco"
                },
                {
                    "codigo_comuna": 218,
                    "codigo_region": 8,
                    "nombre_comuna": "Quilleco"
                },
                {
                    "codigo_comuna": 219,
                    "codigo_region": 8,
                    "nombre_comuna": "Santa Bárbara"
                },
                {
                    "codigo_comuna": 220,
                    "codigo_region": 8,
                    "nombre_comuna": "Quilaco"
                },
                {
                    "codigo_comuna": 221,
                    "codigo_region": 8,
                    "nombre_comuna": "Mulchén"
                },
                {
                    "codigo_comuna": 222,
                    "codigo_region": 8,
                    "nombre_comuna": "Negrete"
                },
                {
                    "codigo_comuna": 223,
                    "codigo_region": 8,
                    "nombre_comuna": "Nacimiento"
                },
                {
                    "codigo_comuna": 224,
                    "codigo_region": 8,
                    "nombre_comuna": "Laja"
                },
                {
                    "codigo_comuna": 225,
                    "codigo_region": 8,
                    "nombre_comuna": "San Rosendo"
                },
                {
                    "codigo_comuna": 226,
                    "codigo_region": 8,
                    "nombre_comuna": "Yumbel"
                },
                {
                    "codigo_comuna": 227,
                    "codigo_region": 8,
                    "nombre_comuna": "Concepción"
                },
                {
                    "codigo_comuna": 228,
                    "codigo_region": 8,
                    "nombre_comuna": "Talcahuano"
                },
                {
                    "codigo_comuna": 229,
                    "codigo_region": 8,
                    "nombre_comuna": "Penco"
                },
                {
                    "codigo_comuna": 230,
                    "codigo_region": 8,
                    "nombre_comuna": "Tomé"
                },
                {
                    "codigo_comuna": 231,
                    "codigo_region": 8,
                    "nombre_comuna": "Florida"
                },
                {
                    "codigo_comuna": 232,
                    "codigo_region": 8,
                    "nombre_comuna": "Hualqui"
                },
                {
                    "codigo_comuna": 233,
                    "codigo_region": 8,
                    "nombre_comuna": "Santa Juana"
                },
                {
                    "codigo_comuna": 234,
                    "codigo_region": 8,
                    "nombre_comuna": "Lota"
                },
                {
                    "codigo_comuna": 235,
                    "codigo_region": 8,
                    "nombre_comuna": "Coronel"
                },
                {
                    "codigo_comuna": 236,
                    "codigo_region": 8,
                    "nombre_comuna": "Lebu"
                },
                {
                    "codigo_comuna": 237,
                    "codigo_region": 8,
                    "nombre_comuna": "Arauco"
                },
                {
                    "codigo_comuna": 238,
                    "codigo_region": 8,
                    "nombre_comuna": "Curanilahue"
                },
                {
                    "codigo_comuna": 239,
                    "codigo_region": 8,
                    "nombre_comuna": "Los Alamos"
                },
                {
                    "codigo_comuna": 240,
                    "codigo_region": 8,
                    "nombre_comuna": "Cañete"
                },
                {
                    "codigo_comuna": 241,
                    "codigo_region": 8,
                    "nombre_comuna": "Contulmo"
                },
                {
                    "codigo_comuna": 242,
                    "codigo_region": 8,
                    "nombre_comuna": "Tirúa"
                },
                {
                    "codigo_comuna": 372,
                    "codigo_region": 8,
                    "nombre_comuna": "Chiguayante"
                },
                {
                    "codigo_comuna": 380,
                    "codigo_region": 8,
                    "nombre_comuna": "San Pedro De La Paz"
                },
                {
                    "codigo_comuna": 392,
                    "codigo_region": 8,
                    "nombre_comuna": "Hualpén"
                },
                {
                    "codigo_comuna": 393,
                    "codigo_region": 8,
                    "nombre_comuna": "Alto Biobío"
                }
            ]
        },
        {
            "codigo_region": 9,
            "nombre_region": "Region De La Araucania",
            "comunas": [
                {
                    "codigo_comuna": 243,
                    "codigo_region": 9,
                    "nombre_comuna": "Angol"
                },
                {
                    "codigo_comuna": 244,
                    "codigo_region": 9,
                    "nombre_comuna": "Renaico"
                },
                {
                    "codigo_comuna": 245,
                    "codigo_region": 9,
                    "nombre_comuna": "Collipulli"
                },
                {
                    "codigo_comuna": 246,
                    "codigo_region": 9,
                    "nombre_comuna": "Lonquimay"
                },
                {
                    "codigo_comuna": 247,
                    "codigo_region": 9,
                    "nombre_comuna": "Curacautín"
                },
                {
                    "codigo_comuna": 248,
                    "codigo_region": 9,
                    "nombre_comuna": "Ercilla"
                },
                {
                    "codigo_comuna": 249,
                    "codigo_region": 9,
                    "nombre_comuna": "Victoria"
                },
                {
                    "codigo_comuna": 250,
                    "codigo_region": 9,
                    "nombre_comuna": "Traiguén"
                },
                {
                    "codigo_comuna": 251,
                    "codigo_region": 9,
                    "nombre_comuna": "Lumaco"
                },
                {
                    "codigo_comuna": 252,
                    "codigo_region": 9,
                    "nombre_comuna": "Purén"
                },
                {
                    "codigo_comuna": 253,
                    "codigo_region": 9,
                    "nombre_comuna": "Los Sauces"
                },
                {
                    "codigo_comuna": 254,
                    "codigo_region": 9,
                    "nombre_comuna": "Temuco"
                },
                {
                    "codigo_comuna": 255,
                    "codigo_region": 9,
                    "nombre_comuna": "Lautaro"
                },
                {
                    "codigo_comuna": 256,
                    "codigo_region": 9,
                    "nombre_comuna": "Perquenco"
                },
                {
                    "codigo_comuna": 257,
                    "codigo_region": 9,
                    "nombre_comuna": "Vilcún"
                },
                {
                    "codigo_comuna": 258,
                    "codigo_region": 9,
                    "nombre_comuna": "Cunco"
                },
                {
                    "codigo_comuna": 259,
                    "codigo_region": 9,
                    "nombre_comuna": "Melipeuco"
                },
                {
                    "codigo_comuna": 260,
                    "codigo_region": 9,
                    "nombre_comuna": "Curarrehue"
                },
                {
                    "codigo_comuna": 261,
                    "codigo_region": 9,
                    "nombre_comuna": "Pucón"
                },
                {
                    "codigo_comuna": 262,
                    "codigo_region": 9,
                    "nombre_comuna": "Villarrica"
                },
                {
                    "codigo_comuna": 263,
                    "codigo_region": 9,
                    "nombre_comuna": "Freire"
                },
                {
                    "codigo_comuna": 264,
                    "codigo_region": 9,
                    "nombre_comuna": "Pitrufquén"
                },
                {
                    "codigo_comuna": 265,
                    "codigo_region": 9,
                    "nombre_comuna": "Gorbea"
                },
                {
                    "codigo_comuna": 266,
                    "codigo_region": 9,
                    "nombre_comuna": "Loncoche"
                },
                {
                    "codigo_comuna": 267,
                    "codigo_region": 9,
                    "nombre_comuna": "Toltén"
                },
                {
                    "codigo_comuna": 268,
                    "codigo_region": 9,
                    "nombre_comuna": "Teodoro Schmidt"
                },
                {
                    "codigo_comuna": 269,
                    "codigo_region": 9,
                    "nombre_comuna": "Saavedra"
                },
                {
                    "codigo_comuna": 270,
                    "codigo_region": 9,
                    "nombre_comuna": "Carahue"
                },
                {
                    "codigo_comuna": 271,
                    "codigo_region": 9,
                    "nombre_comuna": "Nueva Imperial"
                },
                {
                    "codigo_comuna": 272,
                    "codigo_region": 9,
                    "nombre_comuna": "Galvarino"
                },
                {
                    "codigo_comuna": 376,
                    "codigo_region": 9,
                    "nombre_comuna": "Padre Las Casas"
                },
                {
                    "codigo_comuna": 391,
                    "codigo_region": 9,
                    "nombre_comuna": "Cholchol"
                }
            ]
        },
        {
            "codigo_region": 10,
            "nombre_region": "Region De Los Lagos",
            "comunas": [
                {
                    "codigo_comuna": 285,
                    "codigo_region": 10,
                    "nombre_comuna": "Osorno"
                },
                {
                    "codigo_comuna": 286,
                    "codigo_region": 10,
                    "nombre_comuna": "San Pablo"
                },
                {
                    "codigo_comuna": 288,
                    "codigo_region": 10,
                    "nombre_comuna": "Puerto Octay"
                },
                {
                    "codigo_comuna": 289,
                    "codigo_region": 10,
                    "nombre_comuna": "Purranque"
                },
                {
                    "codigo_comuna": 290,
                    "codigo_region": 10,
                    "nombre_comuna": "Río Negro"
                },
                {
                    "codigo_comuna": 291,
                    "codigo_region": 10,
                    "nombre_comuna": "San Juan De La Costa"
                },
                {
                    "codigo_comuna": 292,
                    "codigo_region": 10,
                    "nombre_comuna": "Puerto Montt"
                },
                {
                    "codigo_comuna": 293,
                    "codigo_region": 10,
                    "nombre_comuna": "Puerto Varas"
                },
                {
                    "codigo_comuna": 294,
                    "codigo_region": 10,
                    "nombre_comuna": "Cochamó"
                },
                {
                    "codigo_comuna": 295,
                    "codigo_region": 10,
                    "nombre_comuna": "Calbuco"
                },
                {
                    "codigo_comuna": 296,
                    "codigo_region": 10,
                    "nombre_comuna": "Maullín"
                },
                {
                    "codigo_comuna": 297,
                    "codigo_region": 10,
                    "nombre_comuna": "Los Muermos"
                },
                {
                    "codigo_comuna": 298,
                    "codigo_region": 10,
                    "nombre_comuna": "Fresia"
                },
                {
                    "codigo_comuna": 299,
                    "codigo_region": 10,
                    "nombre_comuna": "Llanquihue"
                },
                {
                    "codigo_comuna": 300,
                    "codigo_region": 10,
                    "nombre_comuna": "Frutillar"
                },
                {
                    "codigo_comuna": 301,
                    "codigo_region": 10,
                    "nombre_comuna": "Castro"
                },
                {
                    "codigo_comuna": 302,
                    "codigo_region": 10,
                    "nombre_comuna": "Ancud"
                },
                {
                    "codigo_comuna": 303,
                    "codigo_region": 10,
                    "nombre_comuna": "Quemchi"
                },
                {
                    "codigo_comuna": 304,
                    "codigo_region": 10,
                    "nombre_comuna": "Dalcahue"
                },
                {
                    "codigo_comuna": 305,
                    "codigo_region": 10,
                    "nombre_comuna": "Curaco De Vélez"
                },
                {
                    "codigo_comuna": 306,
                    "codigo_region": 10,
                    "nombre_comuna": "Quinchao"
                },
                {
                    "codigo_comuna": 307,
                    "codigo_region": 10,
                    "nombre_comuna": "Puqueldón"
                },
                {
                    "codigo_comuna": 308,
                    "codigo_region": 10,
                    "nombre_comuna": "Chonchi"
                },
                {
                    "codigo_comuna": 309,
                    "codigo_region": 10,
                    "nombre_comuna": "Queilén"
                },
                {
                    "codigo_comuna": 310,
                    "codigo_region": 10,
                    "nombre_comuna": "Quellón"
                },
                {
                    "codigo_comuna": 311,
                    "codigo_region": 10,
                    "nombre_comuna": "Chaitén"
                },
                {
                    "codigo_comuna": 312,
                    "codigo_region": 10,
                    "nombre_comuna": "Hualaihué"
                },
                {
                    "codigo_comuna": 313,
                    "codigo_region": 10,
                    "nombre_comuna": "Futaleufú"
                },
                {
                    "codigo_comuna": 314,
                    "codigo_region": 10,
                    "nombre_comuna": "Palena"
                },
                {
                    "codigo_comuna": 373,
                    "codigo_region": 10,
                    "nombre_comuna": "Puyehue"
                }
            ]
        },
        {
            "codigo_region": 11,
            "nombre_region": "Region De Aysen Del General Carlos Ibañez Del Campo",
            "comunas": [
                {
                    "codigo_comuna": 315,
                    "codigo_region": 11,
                    "nombre_comuna": "Coihaique"
                },
                {
                    "codigo_comuna": 316,
                    "codigo_region": 11,
                    "nombre_comuna": "Lago Verde"
                },
                {
                    "codigo_comuna": 317,
                    "codigo_region": 11,
                    "nombre_comuna": "Aisén"
                },
                {
                    "codigo_comuna": 318,
                    "codigo_region": 11,
                    "nombre_comuna": "Cisnes"
                },
                {
                    "codigo_comuna": 319,
                    "codigo_region": 11,
                    "nombre_comuna": "Guaitecas"
                },
                {
                    "codigo_comuna": 320,
                    "codigo_region": 11,
                    "nombre_comuna": "Chile Chico"
                },
                {
                    "codigo_comuna": 321,
                    "codigo_region": 11,
                    "nombre_comuna": "Río Ibáñez"
                },
                {
                    "codigo_comuna": 322,
                    "codigo_region": 11,
                    "nombre_comuna": "Cochrane"
                },
                {
                    "codigo_comuna": 323,
                    "codigo_region": 11,
                    "nombre_comuna": "Ohiggins"
                },
                {
                    "codigo_comuna": 324,
                    "codigo_region": 11,
                    "nombre_comuna": "Torte"
                }
            ]
        },
        {
            "codigo_region": 12,
            "nombre_region": "Region De Magallanes Y De La Antartica Chilena",
            "comunas": [
                {
                    "codigo_comuna": 325,
                    "codigo_region": 12,
                    "nombre_comuna": "Natales"
                },
                {
                    "codigo_comuna": 326,
                    "codigo_region": 12,
                    "nombre_comuna": "Torres Del Paine"
                },
                {
                    "codigo_comuna": 327,
                    "codigo_region": 12,
                    "nombre_comuna": "Punta Arenas"
                },
                {
                    "codigo_comuna": 328,
                    "codigo_region": 12,
                    "nombre_comuna": "Río Verde"
                },
                {
                    "codigo_comuna": 329,
                    "codigo_region": 12,
                    "nombre_comuna": "Laguna Blanca"
                },
                {
                    "codigo_comuna": 330,
                    "codigo_region": 12,
                    "nombre_comuna": "San Gregorio"
                },
                {
                    "codigo_comuna": 331,
                    "codigo_region": 12,
                    "nombre_comuna": "Porvenir"
                },
                {
                    "codigo_comuna": 332,
                    "codigo_region": 12,
                    "nombre_comuna": "Primavera"
                },
                {
                    "codigo_comuna": 333,
                    "codigo_region": 12,
                    "nombre_comuna": "Timaukel"
                },
                {
                    "codigo_comuna": 334,
                    "codigo_region": 12,
                    "nombre_comuna": "Navarino"
                },
                {
                    "codigo_comuna": 335,
                    "codigo_region": 12,
                    "nombre_comuna": "Antártica"
                },
                {
                    "codigo_comuna": 389,
                    "codigo_region": 12,
                    "nombre_comuna": "Cabo De Hornos"
                }
            ]
        },
        {
            "codigo_region": 13,
            "nombre_region": "Region Metropolitana De Santiago",
            "comunas": [
                {
                    "codigo_comuna": 81,
                    "codigo_region": 13,
                    "nombre_comuna": "Santiago"
                },
                {
                    "codigo_comuna": 82,
                    "codigo_region": 13,
                    "nombre_comuna": "Independencia"
                },
                {
                    "codigo_comuna": 83,
                    "codigo_region": 13,
                    "nombre_comuna": "Conchalí"
                },
                {
                    "codigo_comuna": 84,
                    "codigo_region": 13,
                    "nombre_comuna": "Huechuraba"
                },
                {
                    "codigo_comuna": 85,
                    "codigo_region": 13,
                    "nombre_comuna": "Recoleta"
                },
                {
                    "codigo_comuna": 86,
                    "codigo_region": 13,
                    "nombre_comuna": "Providencia"
                },
                {
                    "codigo_comuna": 87,
                    "codigo_region": 13,
                    "nombre_comuna": "Vitacura"
                },
                {
                    "codigo_comuna": 88,
                    "codigo_region": 13,
                    "nombre_comuna": "Lo Barnechea"
                },
                {
                    "codigo_comuna": 89,
                    "codigo_region": 13,
                    "nombre_comuna": "Las Condes"
                },
                {
                    "codigo_comuna": 90,
                    "codigo_region": 13,
                    "nombre_comuna": "Ñuñoa"
                },
                {
                    "codigo_comuna": 91,
                    "codigo_region": 13,
                    "nombre_comuna": "La Reina"
                },
                {
                    "codigo_comuna": 92,
                    "codigo_region": 13,
                    "nombre_comuna": "Macul"
                },
                {
                    "codigo_comuna": 93,
                    "codigo_region": 13,
                    "nombre_comuna": "Peñalolén"
                },
                {
                    "codigo_comuna": 94,
                    "codigo_region": 13,
                    "nombre_comuna": "La Florida"
                },
                {
                    "codigo_comuna": 95,
                    "codigo_region": 13,
                    "nombre_comuna": "San Joaquín"
                },
                {
                    "codigo_comuna": 96,
                    "codigo_region": 13,
                    "nombre_comuna": "La Granja"
                },
                {
                    "codigo_comuna": 97,
                    "codigo_region": 13,
                    "nombre_comuna": "La Pintana"
                },
                {
                    "codigo_comuna": 98,
                    "codigo_region": 13,
                    "nombre_comuna": "San Ramón"
                },
                {
                    "codigo_comuna": 99,
                    "codigo_region": 13,
                    "nombre_comuna": "San Miguel"
                },
                {
                    "codigo_comuna": 100,
                    "codigo_region": 13,
                    "nombre_comuna": "La Cisterna"
                },
                {
                    "codigo_comuna": 101,
                    "codigo_region": 13,
                    "nombre_comuna": "El Bosque"
                },
                {
                    "codigo_comuna": 102,
                    "codigo_region": 13,
                    "nombre_comuna": "Pedro Aguirre Cerda"
                },
                {
                    "codigo_comuna": 103,
                    "codigo_region": 13,
                    "nombre_comuna": "Lo Espejo"
                },
                {
                    "codigo_comuna": 104,
                    "codigo_region": 13,
                    "nombre_comuna": "Estación Central"
                },
                {
                    "codigo_comuna": 105,
                    "codigo_region": 13,
                    "nombre_comuna": "Cerrillos"
                },
                {
                    "codigo_comuna": 106,
                    "codigo_region": 13,
                    "nombre_comuna": "Maipú"
                },
                {
                    "codigo_comuna": 107,
                    "codigo_region": 13,
                    "nombre_comuna": "Quinta Normal"
                },
                {
                    "codigo_comuna": 108,
                    "codigo_region": 13,
                    "nombre_comuna": "Lo Prado"
                },
                {
                    "codigo_comuna": 109,
                    "codigo_region": 13,
                    "nombre_comuna": "Pudahuel"
                },
                {
                    "codigo_comuna": 110,
                    "codigo_region": 13,
                    "nombre_comuna": "Cerro Navia"
                },
                {
                    "codigo_comuna": 111,
                    "codigo_region": 13,
                    "nombre_comuna": "Renca"
                },
                {
                    "codigo_comuna": 112,
                    "codigo_region": 13,
                    "nombre_comuna": "Quilicura"
                },
                {
                    "codigo_comuna": 113,
                    "codigo_region": 13,
                    "nombre_comuna": "Colina"
                },
                {
                    "codigo_comuna": 114,
                    "codigo_region": 13,
                    "nombre_comuna": "Lampa"
                },
                {
                    "codigo_comuna": 115,
                    "codigo_region": 13,
                    "nombre_comuna": "Tiltil"
                },
                {
                    "codigo_comuna": 116,
                    "codigo_region": 13,
                    "nombre_comuna": "Puente Alto"
                },
                {
                    "codigo_comuna": 117,
                    "codigo_region": 13,
                    "nombre_comuna": "San José De Maipo"
                },
                {
                    "codigo_comuna": 118,
                    "codigo_region": 13,
                    "nombre_comuna": "Pirque"
                },
                {
                    "codigo_comuna": 119,
                    "codigo_region": 13,
                    "nombre_comuna": "San Bernardo"
                },
                {
                    "codigo_comuna": 120,
                    "codigo_region": 13,
                    "nombre_comuna": "Buin"
                },
                {
                    "codigo_comuna": 121,
                    "codigo_region": 13,
                    "nombre_comuna": "Paine"
                },
                {
                    "codigo_comuna": 122,
                    "codigo_region": 13,
                    "nombre_comuna": "Calera De Tango"
                },
                {
                    "codigo_comuna": 123,
                    "codigo_region": 13,
                    "nombre_comuna": "Melipilla"
                },
                {
                    "codigo_comuna": 124,
                    "codigo_region": 13,
                    "nombre_comuna": "María Pinto"
                },
                {
                    "codigo_comuna": 125,
                    "codigo_region": 13,
                    "nombre_comuna": "Curacaví"
                },
                {
                    "codigo_comuna": 126,
                    "codigo_region": 13,
                    "nombre_comuna": "Alhué"
                },
                {
                    "codigo_comuna": 127,
                    "codigo_region": 13,
                    "nombre_comuna": "San Pedro"
                },
                {
                    "codigo_comuna": 128,
                    "codigo_region": 13,
                    "nombre_comuna": "Talagante"
                },
                {
                    "codigo_comuna": 129,
                    "codigo_region": 13,
                    "nombre_comuna": "Peñaflor"
                },
                {
                    "codigo_comuna": 130,
                    "codigo_region": 13,
                    "nombre_comuna": "Isla De Maipo"
                },
                {
                    "codigo_comuna": 131,
                    "codigo_region": 13,
                    "nombre_comuna": "El Monte"
                },
                {
                    "codigo_comuna": 381,
                    "codigo_region": 13,
                    "nombre_comuna": "Padre Hurtado"
                }
            ]
        },
        {
            "codigo_region": 14,
            "nombre_region": "Region De Los Rios",
            "comunas": [
                {
                    "codigo_comuna": 273,
                    "codigo_region": 14,
                    "nombre_comuna": "Valdivia"
                },
                {
                    "codigo_comuna": 274,
                    "codigo_region": 14,
                    "nombre_comuna": "Mariquina"
                },
                {
                    "codigo_comuna": 275,
                    "codigo_region": 14,
                    "nombre_comuna": "Lanco"
                },
                {
                    "codigo_comuna": 276,
                    "codigo_region": 14,
                    "nombre_comuna": "Los Lagos"
                },
                {
                    "codigo_comuna": 277,
                    "codigo_region": 14,
                    "nombre_comuna": "Futrono"
                },
                {
                    "codigo_comuna": 278,
                    "codigo_region": 14,
                    "nombre_comuna": "Corral"
                },
                {
                    "codigo_comuna": 279,
                    "codigo_region": 14,
                    "nombre_comuna": "Máfil"
                },
                {
                    "codigo_comuna": 280,
                    "codigo_region": 14,
                    "nombre_comuna": "Panguipulli"
                },
                {
                    "codigo_comuna": 281,
                    "codigo_region": 14,
                    "nombre_comuna": "La Unión"
                },
                {
                    "codigo_comuna": 282,
                    "codigo_region": 14,
                    "nombre_comuna": "Paillaco"
                },
                {
                    "codigo_comuna": 283,
                    "codigo_region": 14,
                    "nombre_comuna": "Río Bueno"
                },
                {
                    "codigo_comuna": 284,
                    "codigo_region": 14,
                    "nombre_comuna": "Lago Ranco"
                }
            ]
        },
        {
            "codigo_region": 15,
            "nombre_region": "Region De Arica Y Parinacota",
            "comunas": [
                {
                    "codigo_comuna": 1,
                    "codigo_region": 15,
                    "nombre_comuna": "Arica"
                },
                {
                    "codigo_comuna": 2,
                    "codigo_region": 15,
                    "nombre_comuna": "Camarones"
                },
                {
                    "codigo_comuna": 3,
                    "codigo_region": 15,
                    "nombre_comuna": "Putre"
                },
                {
                    "codigo_comuna": 4,
                    "codigo_region": 15,
                    "nombre_comuna": "General Lagos"
                }
            ]
        },
        {
            "codigo_region": 16,
            "nombre_region": "Region Del Ñuble",
            "comunas": [
                {
                    "codigo_comuna": 194,
                    "codigo_region": 16,
                    "nombre_comuna": "Chillán"
                },
                {
                    "codigo_comuna": 195,
                    "codigo_region": 16,
                    "nombre_comuna": "San Carlos"
                },
                {
                    "codigo_comuna": 196,
                    "codigo_region": 16,
                    "nombre_comuna": "Ñiquén"
                },
                {
                    "codigo_comuna": 197,
                    "codigo_region": 16,
                    "nombre_comuna": "San Fabián"
                },
                {
                    "codigo_comuna": 198,
                    "codigo_region": 16,
                    "nombre_comuna": "Coihueco"
                },
                {
                    "codigo_comuna": 199,
                    "codigo_region": 16,
                    "nombre_comuna": "Pinto"
                },
                {
                    "codigo_comuna": 200,
                    "codigo_region": 16,
                    "nombre_comuna": "San Ignacio"
                },
                {
                    "codigo_comuna": 201,
                    "codigo_region": 16,
                    "nombre_comuna": "El Carmen"
                },
                {
                    "codigo_comuna": 202,
                    "codigo_region": 16,
                    "nombre_comuna": "Yungay"
                },
                {
                    "codigo_comuna": 203,
                    "codigo_region": 16,
                    "nombre_comuna": "Pemuco"
                },
                {
                    "codigo_comuna": 204,
                    "codigo_region": 16,
                    "nombre_comuna": "Bulnes"
                },
                {
                    "codigo_comuna": 205,
                    "codigo_region": 16,
                    "nombre_comuna": "Quillón"
                },
                {
                    "codigo_comuna": 206,
                    "codigo_region": 16,
                    "nombre_comuna": "Ranquil"
                },
                {
                    "codigo_comuna": 207,
                    "codigo_region": 16,
                    "nombre_comuna": "Portezuelo"
                },
                {
                    "codigo_comuna": 208,
                    "codigo_region": 16,
                    "nombre_comuna": "Coelemu"
                },
                {
                    "codigo_comuna": 209,
                    "codigo_region": 16,
                    "nombre_comuna": "Treguaco"
                },
                {
                    "codigo_comuna": 210,
                    "codigo_region": 16,
                    "nombre_comuna": "Cobquecura"
                },
                {
                    "codigo_comuna": 211,
                    "codigo_region": 16,
                    "nombre_comuna": "Quirihue"
                },
                {
                    "codigo_comuna": 212,
                    "codigo_region": 16,
                    "nombre_comuna": "Ninhue"
                },
                {
                    "codigo_comuna": 213,
                    "codigo_region": 16,
                    "nombre_comuna": "San Nicolás"
                },
                {
                    "codigo_comuna": 379,
                    "codigo_region": 16,
                    "nombre_comuna": "Chillán Viejo"
                }
            ]
        }
    ],
    paises: [
        { "id": 1, "name": "APATRIDA" },
        { "id": 2, "name": "DESCONOCIDO" },
        { "id": 3, "name": "NACIONALIDAD OMITIDA" },
        { "id": 4, "name": "ÁFRICA" },
        { "id": 5, "name": "ÁFRICA DEL SUDOESTE" },
        { "id": 6, "name": "NAMIBIA" },
        { "id": 7, "name": "ALTO VOLTA" },
        { "id": 8, "name": "ANGOLA" },
        { "id": 9, "name": "ARGELIA" },
        { "id": 10, "name": "BENIN" },
        { "id": 11, "name": "DAHOMEY" },
        { "id": 12, "name": "BOPHUTHATSWANA" },
        { "id": 13, "name": "BOTSWANA" },
        { "id": 14, "name": "BURUNDÍ" },
        { "id": 15, "name": "CABO VERDE" },
        { "id": 16, "name": "CAMERÚN" },
        { "id": 17, "name": "CHAD" },
        { "id": 18, "name": "REPÚBLICA COMORAS" },
        { "id": 19, "name": "REPÚBLICA POPULAR DEL CONGO" },
        { "id": 20, "name": "COSTA DE MARFIL" },
        { "id": 21, "name": "EGIPTO" },
        { "id": 22, "name": "ETIOPÍA" },
        { "id": 23, "name": "GABÓN" },
        { "id": 24, "name": "GAMBIA" },
        { "id": 25, "name": "GHANA" },
        { "id": 26, "name": "GUINEA" },
        { "id": 27, "name": "GUINEA BISSAU" },
        { "id": 28, "name": "GUINEA PORTUGUESA" },
        { "id": 29, "name": "GUINEA ECUATORIAL" },
        { "id": 30, "name": "IMPERIO CENTROAFRICANO" },
        { "id": 31, "name": "REPÚBLICA CENTROAFRICANA" },
        { "id": 32, "name": "ISLA ASCENCIÓN" },
        { "id": 33, "name": "ISLAS AZORES" },
        { "id": 34, "name": "ISLAS CANARIAS" },
        { "id": 35, "name": "ISLAS DE LA REUNIÓN" },
        { "id": 36, "name": "ISLAS MADEIRA" },
        { "id": 37, "name": "ISLAS MAYOTTE" },
        { "id": 38, "name": "ISLA SANTA ELENA" },
        { "id": 39, "name": "ISLAS TRISTAN DA CUNHA" },
        { "id": 40, "name": "JIBUTI" },
        { "id": 41, "name": "AFAR E ISSAS" },
        { "id": 42, "name": "DJIBOUTI" },
        { "id": 43, "name": "KENIA" },
        { "id": 44, "name": "LESOTHO" },
        { "id": 45, "name": "LIBERIA" },
        { "id": 46, "name": "LIBIA" },
        { "id": 47, "name": "MALAWI" },
        { "id": 48, "name": "REPÚBLICA MALGACHE" },
        { "id": 49, "name": "MADAGASCAR" },
        { "id": 50, "name": "MALI" },
        { "id": 51, "name": "MARRUECOS" },
        { "id": 52, "name": "MAURICIO" },
        { "id": 53, "name": "MAURITANIA" },
        { "id": 54, "name": "MOZAMBIQUE" },
        { "id": 55, "name": "NGWANE" },
        { "id": 56, "name": "SUAZILANDIA" },
        { "id": 57, "name": "NIGER" },
        { "id": 58, "name": "NIGERIA" },
        { "id": 59, "name": "RUANDA" },
        { "id": 60, "name": "R.D. DE SAO TOME Y PRINCIPE" },
        { "id": 61, "name": "SENEGAL" },
        { "id": 62, "name": "SEYCHELLES" },
        { "id": 63, "name": "SIERRA LEONA" },
        { "id": 64, "name": "SOMALIA" },
        { "id": 65, "name": "REPÚBLICA DE SUDÁFRICA" },
        { "id": 66, "name": "SUDÁN" },
        { "id": 67, "name": "TANZANIA" },
        { "id": 68, "name": "TOGO" },
        { "id": 69, "name": "TRANSKEI" },
        { "id": 70, "name": "TUNEZ" },
        { "id": 71, "name": "UGANDA" },
        { "id": 72, "name": "ZAIRE" },
        { "id": 73, "name": "ZAMBIA" },
        { "id": 74, "name": "ZIMBABWE" },
        { "id": 75, "name": "RHODESIA" },
        { "id": 76, "name": "AMÉRICA" },
        { "id": 77, "name": "ALASKA" },
        { "id": 78, "name": "ANTIGUA Y BARBUDA" },
        { "id": 79, "name": "ARGENTINA" },
        { "id": 80, "name": "BAHAMAS" },
        { "id": 81, "name": "BARBADOS" },
        { "id": 82, "name": "BELICE" },
        { "id": 83, "name": "BOLIVIA" },
        { "id": 84, "name": "BRASIL" },
        { "id": 85, "name": "CANADÁ" },
        { "id": 86, "name": "CHILE" },
        { "id": 87, "name": "COLOMBIA" },
        { "id": 88, "name": "COSTA RICA" },
        { "id": 89, "name": "CUBA" },
        { "id": 90, "name": "DOMINICA" },
        { "id": 91, "name": "REPÚBLICA DOMINICANA" },
        { "id": 92, "name": "ECUADOR" },
        { "id": 93, "name": "EL SALVADOR" },
        { "id": 94, "name": "ESTADOS UNIDOS DE AMERICA" },
        { "id": 95, "name": "GRANADA" },
        { "id": 96, "name": "GRENADA" },
        { "id": 97, "name": "GUATEMALA" },
        { "id": 98, "name": "GUAYANA FRANCESA" },
        { "id": 99, "name": "GUYANA" },
        { "id": 100, "name": "HAITÍ" },
        { "id": 101, "name": "HONDURAS" },
        { "id": 102, "name": "ISLA ANGUILLA" },
        { "id": 103, "name": "ISLA ARUBA" },
        { "id": 104, "name": "ISLAS BERMUDAS" },
        { "id": 105, "name": "ISLAS BONAIRE" },
        { "id": 106, "name": "ISLAS CAIMANES" },
        { "id": 107, "name": "ISLA CURAZAO" },
        { "id": 108, "name": "ISLA DE PASCUA" },
        { "id": 109, "name": "ISLAS FALKLAND" },
        { "id": 110, "name": "ISLAS GALÁPAGOS" },
        { "id": 111, "name": "ISLA GUADALUPE" },
        { "id": 112, "name": "ISLAS JUAN FERNANDEZ" },
        { "id": 113, "name": "ISLAS MALVINAS" },
        { "id": 114, "name": "ISLA MARTINICA" },
        { "id": 115, "name": "ISLA MONTSERRAT" },
        { "id": 116, "name": "ISLAS SAN PEDRO Y MIQUELÓN" },
        { "id": 117, "name": "ISLA TERRANOVA" },
        { "id": 118, "name": "ISLAS TURCAS Y CAICOS" },
        { "id": 119, "name": "ISLAS VÍRGENES AMERICANAS" },
        { "id": 120, "name": "ISLAS VÍRGENES" },
        { "id": 121, "name": "JAMAICA" },
        { "id": 122, "name": "MÉXICO" },
        { "id": 123, "name": "ANTILLAS NEERLANDESAS" },
        { "id": 124, "name": "NICARAGUA" },
        { "id": 125, "name": "PANAMÁ" },
        { "id": 126, "name": "PARAGUAY" },
        { "id": 127, "name": "PERÚ" },
        { "id": 128, "name": "PUERTO RICO" },
        { "id": 129, "name": "SANTA LUCIA" },
        { "id": 130, "name": "SAN CRISTOBAL-NEVIS" },
        { "id": 131, "name": "SAN VICENTE Y LAS GRANADINAS" },
        { "id": 132, "name": "SURINAM" },
        { "id": 133, "name": "GUAYANA HOLANDESA" },
        { "id": 134, "name": "TRINIDAD Y TOBAGO" },
        { "id": 135, "name": "URUGUAY" },
        { "id": 136, "name": "VENEZUELA" },
        { "id": 137, "name": "AMÉRICA DEL NORTE" },
        { "id": 138, "name": "AMÉRICA CENTRAL" },
        { "id": 139, "name": "AMÉRICA DEL SUR" },
        { "id": 140, "name": "CARIBE" },
        { "id": 141, "name": "LAS ANTILLAS" },
        { "id": 142, "name": "ASIA" },
        { "id": 143, "name": "AFGANISTÁN" },
        { "id": 144, "name": "ARABIA SAUDITA" },
        { "id": 145, "name": "BAHREIN" },
        { "id": 146, "name": "BANGLADESH" },
        { "id": 147, "name": "BIRMANIA" },
        { "id": 148, "name": "BRUNEI" },
        { "id": 149, "name": "BUTÁN" },
        { "id": 150, "name": "KAMPUCHEA" },
        { "id": 151, "name": "CAMBOYA" },
        { "id": 152, "name": "REPÚBLICA POPULAR CHINA" },
        { "id": 153, "name": "CHINA NACIONALISTA" },
        { "id": 154, "name": "FORMOSA" },
        { "id": 155, "name": "TAIWAN" },
        { "id": 156, "name": "CHIPRE" },
        { "id": 157, "name": "COREA DEL NORTE" },
        { "id": 158, "name": "COREA DEL SUR" },
        { "id": 159, "name": "EMIRATOS ARABES UNIDOS" },
        { "id": 160, "name": "REPÚBLICA DE FILIPINAS" },
        { "id": 161, "name": "HONG KONG" },
        { "id": 162, "name": "INDIA" },
        { "id": 163, "name": "INDONESIA" },
        { "id": 164, "name": "IRAK" },
        { "id": 165, "name": "IRÁN" },
        { "id": 166, "name": "ISLAS ANDAMAN" },
        { "id": 167, "name": "ISLAS BORNEO" },
        { "id": 168, "name": "ISLAS CELEBES" },
        { "id": 169, "name": "ISLAS HAINAN" },
        { "id": 170, "name": "ISLAS HOKKAIDO" },
        { "id": 171, "name": "ISLAS NIPON" },
        { "id": 172, "name": "ISLA IWOJIMA" },
        { "id": 173, "name": "ISLA JAVA" },
        { "id": 174, "name": "ISLA KIUSIU" },
        { "id": 175, "name": "ISLA LUZON" },
        { "id": 176, "name": "ISLA MINDANAO" },
        { "id": 177, "name": "ISLA OKINAWA" },
        { "id": 178, "name": "ISLAS SIKOKU" },
        { "id": 179, "name": "ISLA SUMATRA" },
        { "id": 180, "name": "ISLA TIMOR" },
        { "id": 181, "name": "ISRAEL" },
        { "id": 182, "name": "JAPÓN" },
        { "id": 183, "name": "JORDANIA" },
        { "id": 184, "name": "KATAR" },
        { "id": 185, "name": "KOWEIT" },
        { "id": 186, "name": "LAOS" },
        { "id": 187, "name": "LÍBANO" },
        { "id": 188, "name": "MACAO" },
        { "id": 189, "name": "FEDERACIÓN DE MALASIA" },
        { "id": 190, "name": "MALDIVAS" },
        { "id": 191, "name": "MONGOLIA" },
        { "id": 192, "name": "NEPAL" },
        { "id": 193, "name": "SULTANATO DE OMAN" },
        { "id": 194, "name": "MASCATE Y OMAN" },
        { "id": 195, "name": "PAKISTÁN" },
        { "id": 196, "name": "SINGAPUR" },
        { "id": 197, "name": "SIRIA" },
        { "id": 198, "name": "SIKKIM" },
        { "id": 199, "name": "SRI LANKA" },
        { "id": 200, "name": "CEILÁN" },
        { "id": 201, "name": "TAILANDIA" },
        { "id": 202, "name": "TURQUÍA" },
        { "id": 203, "name": "UNIÓN SOVIÉTICA" },
        { "id": 204, "name": "VIETNAM" },
        { "id": 205, "name": "YEMEN DEL NORTE" },
        { "id": 206, "name": "YEMEN DEL SUR" },
        { "id": 207, "name": "SIBERIA" },
        { "id": 208, "name": "PALESTINA" },
        { "id": 209, "name": "EUROPA" },
        { "id": 210, "name": "ALBANIA" },
        { "id": 211, "name": "ALEMANIA DEMOCRÁTICA" },
        { "id": 212, "name": "ALEMANIA FEDERAL" },
        { "id": 213, "name": "ANDORRA" },
        { "id": 214, "name": "AUSTRIA" },
        { "id": 215, "name": "BÉLGICA" },
        { "id": 216, "name": "BERLÍN OCCIDENTAL" },
        { "id": 217, "name": "BULGARIA" },
        { "id": 218, "name": "CHECOSLOVAQUIA" },
        { "id": 219, "name": "DINAMARCA" },
        { "id": 220, "name": "ESCOCIA" },
        { "id": 221, "name": "ESPA@A" },
        { "id": 222, "name": "FINLANDIA" },
        { "id": 223, "name": "FRANCIA" },
        { "id": 224, "name": "GALES" },
        { "id": 225, "name": "GIBRALTAR" },
        { "id": 226, "name": "GRECIA" },
        { "id": 227, "name": "HUNGRÍA" },
        { "id": 228, "name": "INGLATERRA" },
        { "id": 229, "name": "REPÚBLICA DE IRLANDA" },
        { "id": 230, "name": "EIRE" },
        { "id": 231, "name": "IRLANDA DEL NORTE" },
        { "id": 232, "name": "ISLANDIA" },
        { "id": 233, "name": "ISLAS BALEARES" },
        { "id": 234, "name": "ISLA CERDENA" },
        { "id": 235, "name": "ISLA CORCEGA" },
        { "id": 236, "name": "ISLA CRETA" },
        { "id": 237, "name": "ISLAS FEROÉ" },
        { "id": 238, "name": "ISLAS GRAN BRETA@A" },
        { "id": 239, "name": "ISLAS HEBRIDAS" },
        { "id": 240, "name": "ISLAS ORCADAS" },
        { "id": 241, "name": "ISLAS SHETLAND" },
        { "id": 242, "name": "ISLA SICILIA" },
        { "id": 243, "name": "ITALIA" },
        { "id": 244, "name": "LIECHTENSTEIN" },
        { "id": 245, "name": "LUXEMBURGO" },
        { "id": 246, "name": "MALTA" },
        { "id": 247, "name": "MONACO" },
        { "id": 248, "name": "MONTE ATHOS" },
        { "id": 249, "name": "NORUEGA" },
        { "id": 250, "name": "PAISES BAJOS" },
        { "id": 251, "name": "HOLANDA" },
        { "id": 252, "name": "POLONIA" },
        { "id": 253, "name": "PORTUGAL" },
        { "id": 254, "name": "GRAN BRETA@A" },
        { "id": 255, "name": "RUMANIA" },
        { "id": 256, "name": "SAN MARINO" },
        { "id": 257, "name": "SUECIA" },
        { "id": 258, "name": "SUIZA" },
        { "id": 259, "name": "CIUDAD DEL VATICANO" },
        { "id": 260, "name": "YUGOSLAVIA" },
        { "id": 261, "name": "OCEANÍA" },
        { "id": 262, "name": "AUSTRALIA" },
        { "id": 263, "name": "FIJI" },
        { "id": 264, "name": "ISLAS AMBRIM" },
        { "id": 265, "name": "ISLAS CANTON ENDERBURY" },
        { "id": 266, "name": "ISLAS CAROLINAS" },
        { "id": 267, "name": "ISLAS CHRISTMAS" },
        { "id": 268, "name": "ISLAS COCOS" },
        { "id": 269, "name": "ISLAS COOK" },
        { "id": 270, "name": "ISLAS DE LA SOCIEDAD" },
        { "id": 271, "name": "ISLAS EFATE" },
        { "id": 272, "name": "ISLAS EROMANGA" },
        { "id": 273, "name": "ISLAS ESPIRITU SANTO" },
        { "id": 274, "name": "ISLAS FENIX" },
        { "id": 275, "name": "ISLAS FLORIDA" },
        { "id": 276, "name": "ISLAS FUTUNA" },
        { "id": 277, "name": "ISLAS GAMBIER" },
        { "id": 278, "name": "ISLAS GILBERT" },
        { "id": 279, "name": "ISLA GUADALCANAL" },
        { "id": 280, "name": "ISLA GUAM" },
        { "id": 281, "name": "ISLAS HAAPAI" },
        { "id": 282, "name": "ISLAS HAWAII" },
        { "id": 283, "name": "ISLAS HEARD-MAC DONALD" },
        { "id": 284, "name": "ISLAS JOHNSTON" },
        { "id": 285, "name": "ISLAS KANDAVU" },
        { "id": 286, "name": "ISLAS KUSAIE" },
        { "id": 287, "name": "ISLAS LAU" },
        { "id": 288, "name": "ISLAS LEALTAD" },
        { "id": 289, "name": "ISLAS LINE" },
        { "id": 290, "name": "ISLAS MALAIATA" },
        { "id": 291, "name": "ISLAS MACQUARIE" },
        { "id": 292, "name": "ISLAS MALEKULA" },
        { "id": 293, "name": "ISLAS MARIANAS" },
        { "id": 294, "name": "ISLAS MARQUESAS" },
        { "id": 295, "name": "ISLAS MARSHALL" },
        { "id": 296, "name": "ISLAS MIDWAY" },
        { "id": 297, "name": "ISLAS NIUE" },
        { "id": 298, "name": "ISLAS NORFOLK" },
        { "id": 299, "name": "ISLAS NUEVA CALEDONIA" },
        { "id": 300, "name": "ISLAS OCEAN" },
        { "id": 301, "name": "ISLAS PALAU" },
        { "id": 302, "name": "ISLAS PINOS" },
        { "id": 303, "name": "ISLAS PITCAIRN" },
        { "id": 304, "name": "ISLAS POLINESIA" },
        { "id": 305, "name": "ISLAS PONAPE" },
        { "id": 306, "name": "ISLA SAMOA" },
        { "id": 307, "name": "ISLAS SAVAII" },
        { "id": 308, "name": "ISLAS TAHITI" },
        { "id": 309, "name": "ISLA TASMANIA" },
        { "id": 310, "name": "ISLAS TOKELAU" },
        { "id": 311, "name": "ISLAS TONGATAPU" },
        { "id": 312, "name": "ISLAS TRAVEUNI" },
        { "id": 313, "name": "ISLAS TRUK" },
        { "id": 314, "name": "ISLAS TUAMOTU" },
        { "id": 315, "name": "ISLAS TUBAI" },
        { "id": 316, "name": "ISLAS UPOLU" },
        { "id": 317, "name": "ISLAS VANUA LEVU" },
        { "id": 318, "name": "ISLAS VAVAU" },
        { "id": 319, "name": "ISLAS VITI LEVU" },
        { "id": 320, "name": "ISLA WAKE" },
        { "id": 321, "name": "ISLAS WALLIS" },
        { "id": 322, "name": "ISLAS YAP" },
        { "id": 323, "name": "KIRIBATI" },
        { "id": 324, "name": "MICRONESIA" },
        { "id": 325, "name": "NAURU" },
        { "id": 326, "name": "NUEVA ZELANDIA" },
        { "id": 327, "name": "PAPUA NUEVA GUINEA" },
        { "id": 328, "name": "ISLAS SALOMON" },
        { "id": 329, "name": "SAMOA OCCIDENTAL" },
        { "id": 330, "name": "TONGA" },
        { "id": 331, "name": "TUVALU" },
        { "id": 332, "name": "ELLICE" },
        { "id": 333, "name": "VANUATU" },
        { "id": 334, "name": "NUEVAS HEBRIDAS" },
        { "id": 335, "name": "REGIONES POLARES" },
        { "id": 336, "name": "ISLAS BAFFIN" },
        { "id": 337, "name": "ISLAS BANKS" },
        { "id": 338, "name": "ISLAS DEVON" },
        { "id": 339, "name": "ISLAS ELLESMERE" },
        { "id": 340, "name": "ISLA GROENLANDIA" },
        { "id": 341, "name": "ISLAS JAN MAYEN" },
        { "id": 342, "name": "ISLAS MELVILLE" },
        { "id": 343, "name": "ISLAS NUEVA SIBERIA" },
        { "id": 344, "name": "ISLAS NUEVA ZEMBLA" },
        { "id": 345, "name": "ISLAS PRINCIPE DE GALES" },
        { "id": 346, "name": "ISLAS SEVERNAIA ZEMLI" },
        { "id": 347, "name": "ISLAS SPITSBERGEN" },
        { "id": 348, "name": "ISLAS TIERRAS FRANCISCO JOSE" },
        { "id": 349, "name": "ISLAS TIERRAS DEL NORTE" },
        { "id": 350, "name": "ISLAS VICTORIA" },
        { "id": 351, "name": "ISLAS WRANGEL" },
        { "id": 352, "name": "SVALBARD" },
        { "id": 353, "name": "SECTOR ANTÁRTICO ARGENTINO" },
        { "id": 354, "name": "SECTOR ANTÁRTICO AUSTRALIANO" },
        { "id": 355, "name": "SECTOR ANTÁRTICO BRITANICO" },
        { "id": 356, "name": "SECTOR ANTÁRTICO CHILENO" },
        { "id": 357, "name": "SECTOR ANTÁRTICO FRANCES" },
        { "id": 358, "name": "SECTOR ANTÁRTICO NEOZELANDEZ" },
        { "id": 359, "name": "SECTOR ANTÁRTICO NORTEAMERICA" },
        { "id": 360, "name": "SECTOR ANTÁRTICO NORUEGO" },
        { "id": 361, "name": "SECTOR ANTÁRTICO SUDAFRICANO" },
        { "id": 362, "name": "LETONIA" },
        { "id": 363, "name": "LITUANIA" },
        { "id": 364, "name": "SERBIA" },
        { "id": 365, "name": "CURLANDIA" },
        { "id": 366, "name": "ARMENIA" },
        { "id": 367, "name": "DALMACIA" },
        { "id": 368, "name": "UCRANIA" },
        { "id": 369, "name": "ESTONIA" },
        { "id": 370, "name": "MOLDAVIA" },
        { "id": 371, "name": "CISKEI" },
        { "id": 372, "name": "VENDA" },
        { "id": 373, "name": "BELAU" },
        { "id": 374, "name": "CROACIA" },
        { "id": 375, "name": "TRANSJORDANIA" },
        { "id": 376, "name": "REPÚBLICA FEDERAL DE ALEMANIA" },
        { "id": 377, "name": "BOSNIA" },
        { "id": 378, "name": "BIELORUSIA" },
        { "id": 379, "name": "GEORGIA" },
        { "id": 380, "name": "MYANMAR" },
        { "id": 381, "name": "RUSIA" },
        { "id": 382, "name": "MACEDONIA" },
        { "id": 383, "name": "ESLOVENIA" },
        { "id": 384, "name": "SAMOA" },
        { "id": 385, "name": "REPÚBLICA ESLOVACA" },
        { "id": 386, "name": "REPÚBLICA CHECA" },
        { "id": 387, "name": "REPÚBLICA DE UZBEKISTAN" },
        { "id": 388, "name": "REPÚBLICA DEMOCRÁTICA DEL CONGO" },
        { "id": 389, "name": "REPÚBLICA DE KASAJSTAN" },
        { "id": 390, "name": "KUWAIT" },
        { "id": 391, "name": "KIRGUISTAN" },
        { "id": 392, "name": "NATURAL DE CROACIA" },
        { "id": 393, "name": "BOSNIA Y HERZEGOVINA" },
        { "id": 394, "name": "NATURAL REP. FED. YUGOSLAVIA" },
        { "id": 395, "name": "EL TIBET" },
        { "id": 396, "name": "REPÚBLICA DE AZERBAIJAN" },
        { "id": 397, "name": "REPÚBLICA DE TADJIKISTAN" },
        { "id": 398, "name": "BURKINA FASO" },
        { "id": 399, "name": "SERBIA Y MONTENEGRO" },
        { "id": 400, "name": "ERITREA" },
        { "id": 401, "name": "MONTENEGRO" },
        { "id": 402, "name": "YEMEN" },
        { "id": 403, "name": "KOSOVO" }
    ]
};

