<div class="topPanel no-print">
  <div class="topBanner">
    <a routerLink="home" routerLinkActive="active">
      <img alt="Logo" src="/assets/logo_regcivil.png" />
    </a>
    <div class="actionsContainer">
      <div class="loginSpan">
        <img (click)="toolbarActions()" src="/assets/user-icon.png" alt="" />
        <small>{{userData.fullName}}</small>
        <div class="toolbarActions" *ngIf="showActions">
          <span class="panelTip"></span>
          <div>
            <a href="#" (click)="doLogout()">
              <i class="bi bi-box-arrow-right"></i> Cerrar Sesión</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="toolbar" role="banner">
    <img src="/assets/art44_logo_mini.png" alt="art44" />
    <div>
      <h4>Solicitud de RUN para personas extranjeras</h4>
      <blockquote>(Artículo 44 Ley 21.325)</blockquote>
    </div>
  </div>
</div>