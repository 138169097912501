<div class="loginContainer">
    <div class="loginPanel">
        <img src="assets/logo-registro-civil-300-jpg.jpg?timestamp=20180610163906" alt="Registro Civil" />

        <h3 class="text-center">Recuperación de Contraseña</h3>
        <form>
            <div>
                <label for="username">Usuario</label><br />
                <input *ngIf="!usrok" autofocus type="text" name="username" placeholder="Nombre de Usuario"
                    [(ngModel)]="username" autocomplete="off" (blur)="valRut($event, 'username')">
                <span *ngIf="usrok">{{username}}</span>
            </div>
            <div *ngIf="usrok">
                <label for="email">Correo Electrónico</label><br />
                <input type="email" autofocus name="email" id="email" placeholder="Correo Electrónico"
                    autocomplete="off" [(ngModel)]="email" />
            </div>

            <button *ngIf="!usrok" (click)="nextPass()" class='btn-reset mt-3'
                [ngClass]="!nextOK ? 'disabled' : ''">Siguiente</button>
            <button *ngIf="usrok" (click)="resetPass()" class='btn-reset mt-3'
                [ngClass]="!resetOK ? 'disabled' : ''">Reconfigurar Contraseña</button>
        </form>
        <div class="actions">
            <a routerLink="/login" routerLinkActive="active">Volver</a>
        </div>
        <div class="mensajes mt-3 text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
        </div>
    </div>
</div>