import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as userData from './userdata.reducer';

export const userDataState = createFeatureSelector<userData.IUserDataState>(
    userData.Key,
);
export const selectUserData = createSelector(
    userDataState,
    (state: userData.IUserDataState) => {

        return state.userdata
    }
);
