<div *ngIf="organizacion" class="orgContainer">
  <h3>{{organizacion.nombre}}</h3>
  <div class="modal-body">
    <div class="container">
      <div class='viewOrganizacion' *ngIf="!editForm;else formOrganizacion">
        <button class='btn btn-sm btn-primary' (click)="editForm=true">
          <i class="bi bi-pencil-square"></i> Editar
        </button>
        <button class='btn btn-sm btn-danger ml-1' (click)="dialogBloqueo(confirmaBloqueo)"
          *ngIf='organizacion.estado != 0'>
          <i class="bi bi-lock-fill"></i> Bloquear Ingreso
        </button>

        <button class='btn btn-sm btn-success ml-1' (click)="dialogDesbloqueo(confirmaDesbloqueo)"
          *ngIf='organizacion.estado == 0'>
          <i class="bi bi-unlock-fill"></i> Desbloquear Ingreso
        </button>
        <div class="controls-container">
          <div class="row form-group">
            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                Fecha de Subscripción
              </label>
              <input disabled class='form-control' name="fecha_subscripcion_convenio"
                [(ngModel)]="organizacion.fecha_subscripcion_convenio" type="text" placeholder='dd-mm-aaaa' />
            </div>
            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                N° de Resolución
              </label>
              <input disabled class='form-control' name="numero_subscripcion_convenio"
                [(ngModel)]="organizacion.numero_subscripcion_convenio" type="text" placeholder='N° de Resolución' />
            </div>
            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                Fecha de Resolución
              </label>
              <input disabled class='form-control' name="fecha_resolucion_convenio"
                [(ngModel)]="organizacion.fecha_resolucion_convenio" type="text" placeholder='dd-mm-aaaa' />
            </div>
          </div>

          <div class="row">
            <div class="form-group col col-8">
              <label for="orgName"><span class="required">*</span>
                Nombre Organización</label>
              <input disabled (blur)="validateField($event)" required [(ngModel)]="organizacion.nombre"
                class="form-control" type="text" name="orgName" placeholder="Nombre de la Organización" />
            </div>
            <div class="form-group col col-4">
              <label for="orgRun"><span class="required">*</span> RUN Organización</label>
              <input disabled (blur)="valRut($event, 'orgRun')" required [(ngModel)]="organizacion.run"
                class="form-control" type="text" name="orgRun" placeholder="RUN de la Organización" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col col-12">
              <label for="orgAddress"><span class="required">*</span> Dirección</label>
              <input disabled (blur)="validateField($event)" [(ngModel)]="organizacion.direccion" class="form-control"
                type="text" name="orgAddress" placeholder="Dirección" />
            </div>
            <div class="form-group col col-6">
              <label for="orgRegion"><span class="required">*</span> Región</label>
              <input disabled [(ngModel)]="organizacion.region_label" class="form-control" name="orgRegion">

            </div>
            <div class="form-group col col-6">
              <label for="orgComuna"><span class="required">*</span> Comuna</label>
              <input disabled [(ngModel)]="organizacion.comuna_label" class="form-control" name="orgComuna" />
            </div>
          </div>


          <div class="row form-group">
            <div class="col-12">
              <label for="repName"><span class="required">*</span> Representante Legal / Autoridad</label>
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.run_representante" (blur)="valRut($event, 'repRun')"
                class="form-control" type="text" name="repRun" placeholder="Run Rep. Legal" />
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.nombre_representante" class="form-control" type="text"
                name="repFirstName" placeholder="Nombre Rep. Legal" />
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.apellido_representante" class="form-control" type="text"
                name="repLastName" placeholder="Apellido Rep. Legal" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input disabled [(ngModel)]="organizacion.telefono_representante" class="form-control" type="text"
                name="repPhone" placeholder="Telefono Rep. Legal" />

            </div>
            <div class="col-6">
              <input disabled [(ngModel)]="organizacion.correo_representante" class="form-control" type="text"
                name="repMail" placeholder="Correo Rep. Legal" />

            </div>
          </div>
          <!-- <div class="row form-group">
            <div class="col-12">
              <label for="admName"><span class="required">*</span> Coordinador</label>
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.run_administrador" (blur)="valRut($event, 'admRun')"
                class="form-control" type="text" name="admRun" placeholder="Run Coordinador" />
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.nombre_administrador" class="form-control" type="text"
                name="admFirstName" placeholder="Nombre Coordinador" />
            </div>
            <div class="col-4">
              <input disabled [(ngModel)]="organizacion.apellido_administrador" class="form-control" type="text"
                name="admLastName" placeholder="Apellido Coordinador" />
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <input disabled [(ngModel)]="organizacion.telefono_administrador" class="form-control" type="text"
                name="admPhone" placeholder="Telefono Coordinador" />

            </div>
            <div class="col-6">
              <input disabled [(ngModel)]="organizacion.correo_administrador" class="form-control" type="text"
                name="admMail" placeholder="Correo Coordinador" />

            </div> 
          </div>-->

          <!-- <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="repName"><span class="required">*</span> Representante Legal</label>
                <input disabled (blur)="validateField($event)"  [(ngModel)]="organizacion.run_representante" class="form-control"
                type="text" name="repRun" placeholder="Run Rep. Legal" />
                <label for="repRun"></label>
                <input disabled [(ngModel)]="organizacion.nombre_representante" class="form-control"
                type="text" name="repName" placeholder="Nombre Rep. Legal" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="admName"><span class="required">*</span> Administrador</label>
                <input disabled (blur)="validateField($event)"  [(ngModel)]="organizacion.run_administrador" class="form-control"
                type="text" name="admRun" placeholder="Run Administrador" />
                <label for="admRun"></label>
                <input disabled [(ngModel)]="organizacion.nombre_administrador" class="form-control"
                type="text" name="admName" placeholder="Nombre Administrador" />
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Editar organización -->
<ng-template #formOrganizacion>
  <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
  </div>
  <form class="form" id="editarOrganizacionForm" (ngSubmit)="editarOrganizacionSubmit($event)">
    <div class="controls-container">
      <div class="row form-group">

        <div class="col-4">
          <label for="datosConvenio">
            <span class="required">*</span>
            Fecha de Subscripción
          </label>
          <input class='form-control' name="fecha_subscripcion_convenio"
            [(ngModel)]="organizacion.fecha_subscripcion_convenio" type="date" id="fecha_sub" placeholder='dd-mm-aaaa'
            (change)="checkDates()" />
        </div>
        <div class="col-4">
          <label for="datosConvenio">
            <span class="required">*</span>
            N° de Resolución
          </label>
          <input class='form-control' name="numero_subscripcion_convenio"
            [(ngModel)]="organizacion.numero_subscripcion_convenio" type="text" placeholder='N° de Resolución' />
        </div>
        <div class="col-4">
          <label for="datosConvenio">
            <span class="required">*</span>
            Fecha de Resolución
          </label>
          <input class='form-control' name="fecha_resolucion_convenio"
            [(ngModel)]="organizacion.fecha_resolucion_convenio" type="date" id="fecha_conv" placeholder='dd-mm-aaaa'
            (change)="checkDates()" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col col-8">
          <label for="orgName"><span class="required">*</span>
            Nombre Organización</label>
          <input (blur)="validateField($event)" required [(ngModel)]="organizacion.nombre" class="form-control"
            type="text" name="orgName" placeholder="Nombre de la Organización" />
        </div>
        <div class="form-group col col-4">
          <label for="orgRun"><span class="required">*</span> RUN Organización</label>
          <input disabled (blur)="valRut($event,'orgRun')" required [(ngModel)]="organizacion.run"
            class="form-control bg-white" type="text" name="orgRun" placeholder="RUN de la Organización" />
        </div>
      </div>

      <div class="row">
        <div class="form-group col col-12">
          <label for="orgAddress"><span class="required">*</span> Dirección</label>
          <input (blur)="validateField($event)" [(ngModel)]="organizacion.direccion" class="form-control" type="text"
            name="orgAddress" placeholder="Dirección" />
        </div>
        <div class="form-group col col-6">
          <label for="orgRegion"><span class="required">*</span> Región</label>
          <select [(ngModel)]="organizacion.region" class="form-control" name="orgRegion" (change)="changeRegion()">
            <option value="-1">- Seleccione Región -</option>
            <option *ngFor="let region of regiones" [value]="region.codigo_region">
              {{region.nombre_region}}
            </option>
          </select>
        </div>
        <div class="form-group col col-6">
          <label for="orgComuna"><span class="required">*</span> Comuna</label>
          <select [(ngModel)]="organizacion.comuna" class="form-control" name="orgComuna">
            <option *ngFor="let comuna of comunas" [value]="comuna.codigo_comuna">
              {{comuna.nombre_comuna}}
            </option>
          </select>
        </div>
      </div>

      <div class="row form-group">
        <div class="col-12">
          <label for="repName"><span class="required">*</span> Representante Legal / Autoridad</label>
        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.run_representante" (blur)="valRut($event, 'repRun')" class="form-control"
            type="text" name="repRun" placeholder="Run Rep. Legal" />
        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.nombre_representante" class="form-control" type="text" name="repFirstName"
            placeholder="Nombre Rep. Legal" />
        </div>
        <!-- apellido_representante da error y no deja editar -->
        <div class="col-4">
          <input [(ngModel)]="organizacion.apellido_representante" class="form-control" type="text" name="repLastName"
            placeholder="Apellido Rep. Legal" />
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <input [(ngModel)]="organizacion.telefono_representante" class="form-control" type="text" name="repPhone"
            placeholder="Telefono Rep. Legal" />

        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.correo_representante" class="form-control" type="text" name="repMail"
            placeholder="Correo Rep. Legal" onpaste="return false;" oncopy="return false" oncut="return false"
            autocomplete="off" ondrop="return false" ondrag="return false" />
        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.confirEmail_rep" class="form-control" type="text" name="confirEmail_rep"
            placeholder="Confirmar correo" (blur)="confirEmail_rep($event)" onpaste="return false;"
            oncopy="return false" oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />
        </div>
      </div>

      <!-- editar informacion de coordinador/administrador -->

      <!-- <div class="row form-group">
        <div class="col-12">
          <label for="admName"><span class="required">*</span> Coordinador</label>
        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.run_administrador" (blur)="valRut($event, 'admRun')" class="form-control"
            type="text" name="admRun" placeholder="Run Coordinador" />
        </div>



        <div class="col-4">
          <input [(ngModel)]="organizacion.nombre_administrador" class="form-control" type="text" name="admFirstName"
            placeholder="Nombre Coordinador" />

        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.apellido_administrador" class="form-control" type="text" name="admLastName"
            placeholder="Apellido Coordinador" />

        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <input [(ngModel)]="organizacion.telefono_administrador" class="form-control" type="text" name="admPhone"
            placeholder="Telefono Coordinador" />

        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.correo_administrador" class="form-control" type="text" name="admMail"
            placeholder="Correo Coordinador" onpaste="return false;" oncopy="return false" oncut="return false"
            autocomplete="off" ondrop="return false" ondrag="return false" />

        </div>
        <div class="col-4">
          <input [(ngModel)]="organizacion.confirEmail_adm" class="form-control" type="text" name="confirEmail_adm"
            placeholder="Confirmar correo" (blur)="confirEmail_adm($event)" onpaste="return false;"
            oncopy="return false" oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />

        </div>
      </div> -->
    </div>

    <!-- <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="repName"><span class="required">*</span> Representante Legal</label>
            <input  (keyup)="valRut($event)"   (blur)="validateField($event)"  [(ngModel)]="organizacion.run_representante" class="form-control"
            type="text" name="repRun" placeholder="Run Rep. Legal" />
            <label for="repRun"></label>
            <input [(ngModel)]="organizacion.nombre_representante" class="form-control"
            type="text" name="repName" placeholder="Nombre Rep. Legal" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="admName"><span class="required">*</span> Administrador</label>
            <input  (keyup)="valRut($event)"  (blur)="validateField($event)"  [(ngModel)]="organizacion.run_administrador" class="form-control"
            type="text" name="admRun" placeholder="Run Administrador" />
            <label for="admRun"></label>
            <input [(ngModel)]="organizacion.nombre_administrador" class="form-control"
            type="text" name="admName" placeholder="Nombre Administrador" />
          </div>
        </div>
      </div> -->

    <div class="actions">
      <a href='javascript:;' (click)='editForm=false'>Cancelar</a>&nbsp;&nbsp;
      <button type="submit" class="btn btn-primary text-right">
        <i class="bi bi-save"></i> Actualizar Organización</button>
    </div>
  </form>
</ng-template>
<app-users [orgId]='id_organizacion'></app-users>


<!--  MODAL PARA bloquear ingreso -->
<ng-template #confirmaBloqueo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><i class="bi bi-lock-fill"></i> Bloquear Ingreso de Solicitudes </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
  </div>
  <div class="modal-body">
    <div class="my-1 p-1">
      <p>
        Esta acción inhabilitará a la organización el poder ingresar nuevas solicitudes de RUN para extranjeros.
      </p>
      <p>
        La organización podrá seguir visualizando las solicitudes ya ingresadas.
      </p>
    </div>
    <div class="my-1 p-1">
      ¿Desea realizar esta acción?
    </div>
    <div class="actions text-right">
      <a href="javascript:;" (click)="closeModal()">Cancelar</a>&nbsp;&nbsp;
      <button type="submit" class="btn btn-primary" (click)="bloquearIngreso()">
        <i class="bi bi-lock-fill"></i> Bloquear Ingreso</button>
    </div>
  </div>
</ng-template>

<!--  MODAL PARA desbloquear ingreso-->
<ng-template #confirmaDesbloqueo let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><i class="bi bi-unlock-fill"></i> Desbloquear Ingreso de Solicitudes
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
  </div>
  <div class="modal-body">
    <div class="container">
      <div class="my-1 p-1">
        Esta acción permitirá a la organización poder ingresar nuevas solicitudes de RUN para extranjeros.
      </div>
      <div class="my-1 p-1">
        ¿Desea realizar esta acción?
      </div>
      <div class="actions text-right">
        <a href="javascript:;" (click)="closeModal()">Cancelar</a>&nbsp;&nbsp;
        <button type="submit" class="btn btn-primary" (click)="desbloquearIngreso()">
          <i class="bi bi-unlock-fill"></i> Desbloquear Ingreso</button>
      </div>
    </div>
  </div>
</ng-template>