<ng-template [ngIf]="loggedIn && setPassword" [ngIfElse]="loginContainer">
  <!-- Toolbar -->

  <app-tool-bar></app-tool-bar>  
  <div>
    <div class="mainContainer">
      <app-side-bar></app-side-bar>
      <div id="mainContent">
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- Footer -->
    <app-footer></app-footer>
  </div>
</ng-template>

<ng-template #loginContainer>
  <router-outlet></router-outlet>
</ng-template>

