import { Component, OnInit, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { SolicitudesService } from '../shared/solicitudes.service';
import { environment } from 'src/environments/environment';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { Router } from '@angular/router';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { select, Store } from '@ngrx/store';
import { selectUserData } from '../store/userdata/userdata.selector';
import userdata from '../store/userdata/userdata.interface';
import { Observable } from 'rxjs';

import { SettingsService } from '../shared/settings.service';



@Component({
  selector: 'app-nuevasolicitud',
  templateUrl: './nuevasolicitud.component.html',
  styleUrls: ['./nuevasolicitud.component.css']
})


export class NuevasolicitudComponent implements OnInit {
  public title: string;

  fechaVoucher: string = '08 de Abril de 2022';
  horaVoucher: string = '12:30 hrs.';
  direccionVoucher: string = 'Obispo Umaña N° 50, Estación Central';
  oficinaVoucher: string = 'Estación Central';
  horaInicio: string = '12:00';
  horaFin: string = '13:00';
  sendingForm: boolean = false;
  usrData: any = {};
  canCreate: boolean = false;
  isAdmin: boolean = false;

  isMenor: boolean = false;

  displayTicket = false;
  msgTitle = 'Error creando Solicitud';
  msgText = 'Se ha producido un error al crear la solicitud';
  showSysMsg = false;

  showSolicitudNormal: boolean = false;
  showSolicitudUrgencia: boolean = false;
  showButtonN: boolean = false;
  showButtonU: boolean = false;
  formValid: boolean = false;
  fechaSolicitud: string = '';
  ticketNumber: any = null;  // Set to 9999 when debugin voucher
  organizacion: any = {
    nombre: 'Organización en Convenio'
  }
  fecNacDisplay = '';
  maxBDate: any = '0';
  minBDate: any = '';
  doc: any;
  nuevaSolicitud: any = {
    tipo: 'normal',
    titulo: '',
    autor: '',
    materia: '',
    run_solicitante: '',
    run_ciudadano: '',
    estado: '1',
    id_organizacion: '',
    organizacion: {},
    tramite: {},
  };

  motivosUrgencia: any = [
    { id: 0, label: "Una Opción", value: 0 },
    { id: 1, label: "Opción 1", value: 1 },
  ]

  fecha_agenda: any = '';
  hora_agenda: any = '';

  //  inptrunCtrl = new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(8), Validators.pattern("^[0-9]{1,2}.[0-9]{3}.[0-9]{3}-[0-9kK]{1}$")]);
  nameCtrl = new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(1)]);
  primerapCtrl = new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(1)]);
  segundoapCtrl = new FormControl('', [Validators.maxLength(30), Validators.minLength(1)]);
  sexoCtrl = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(1)]);
  nacimientoCtrl = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(8)]);
  nacionalCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(3)]);
  paisNacionalCtrl: string = '';
  pasaportCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]);
  domicilioCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);
  domicilioNumeroCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);
  domicilioLetraCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);

  comunaCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  regionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  direccionAtencionCtrl = new FormControl('', [Validators.maxLength(50), Validators.minLength(5)]);
  regionAtencionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  comunaAtencionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  telfCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]);
  emailCtrl = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(60), Validators.pattern(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]);
  proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido1proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido2proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);

  proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido1proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido2proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  justUrgenciaCtrl = new FormControl('', [Validators.minLength(3)]);
  idInternoCtrl = new FormControl('');
  motivoUrgenciaCtrl = new FormControl('-1');

  esMenor = new FormControl('');
  acompanianteMenor = new FormControl('');
  relacionMenor = new FormControl('');
  acompanianteID = new FormControl('');



  direccionAtencion: string = '';
  regionAtencion: number = -1;
  comunaAtencion: number = -1;

  isDUP: boolean = false;

  oficinasAtencion: any = [];

  fnConfig = {

    format: "YYYY-MM-DD HH:mm"
  }


  public List$: Observable<userdata>;

  constructor(private dom: ElementRef, private solService: SolicitudesService,
    private router: Router, private store: Store<IUserDataState>,
    private settings: SettingsService) {
    this.title = "Nueva solicitud";

    this.List$ = this.store.pipe(select(selectUserData))

  }

  closeMsg(): void {
    this.msgTitle = '';
    this.msgText = '';
    this.showSysMsg = false;
    this.router.navigate(['solicitudes']);
  }

  setFechaAgenda() {
    // console.log(this.fecha_agenda, this.hora_agenda);

  }


  validaFecha(fecha: any) {
    let hoy = new Date();
    let { year, month, day } = fecha;
    let resp = true;

    if (year && month && day) {

      if ((year > hoy.getFullYear() || year < 1900) || month > 12 || day > 31) {
        resp = false;
      }
    } else {
      resp = false;
    }


    return resp;
  }

  validForm() {
    this.nameCtrl.setValue(this.nameCtrl.value.replace(/=/g, ''));


    if (this.nameCtrl.valid && this.sexoCtrl.valid && this.primerapCtrl.valid &&
      this.fecNacDisplay.trim() != '' && this.nacionalCtrl.valid && this.validaFecha(this.nacimientoCtrl.value)) {
      if (this.nuevaSolicitud.tipo == 'urgente') {
        if (this.motivoUrgenciaCtrl.value != -1 && this.justUrgenciaCtrl.value != '' && this.justUrgenciaCtrl.valid &&
          this.direccionAtencion != '' &&
          this.regionAtencion != -1 &&
          this.comunaAtencion != -1 && this.cFound !== false && this.cVal !== '') {
          this.comunaAtencionCtrl.setValue(this.comunaAtencion);
          this.regionAtencionCtrl.setValue(this.regionAtencion);
          /* this.comunaCtrl.setValue(this.comunaAtencion);
          this.regionCtrl.setValue(this.regionAtencion); */
          this.comuna = this.comunaAtencion;

          this.formValid = true;
        } else {
          this.formValid = false;
        }
      } else {
        if (this.comunaCtrl.valid && this.regionCtrl.valid && this.cFound !== false && this.cVal !== '') {
          this.formValid = true;
        } else {
          this.formValid = false;
        }
      }
      if (this.isMenor) {
        if (this.proge1Ctrl.value != '' || this.proge2Ctrl.value || (this.acompanianteMenor.value != '' && this.relacionMenor.value != '' && this.acompanianteID.value != '')) {
          this.formValid = true;
        } else {
          this.formValid = false;
        }
      }
    } else {
      this.formValid = false;
    }
  }

  ngDoCheck() {
    let f = this.nacimientoCtrl.value;
    this.fecNacDisplay = this.setDateFormat(f);

    if (f) {
      let fN = new Date(f.year, f.month - 1, f.day);
      let hoy = new Date();

      let edif = parseFloat(((((((hoy.valueOf() - fN.valueOf()) / 1000) / 60) / 60) / 24) / 365).toFixed(1));

      this.isMenor = edif < 18;

      if (this.isMenor) {
        this.esMenor.setValue(1);
      } else {
        this.esMenor.setValue(0);
      }
    }

    this.validForm();

  }

  selDate(evt: Event) {
    evt.preventDefault();
    //this.doc.querySelector('.fDate').click();
  }

  setBDates() {
    //Cálculo de rango de fechas aceptadas como fecha de nacimiento
    let hoy = new Date();
    hoy.setDate(hoy.getDate() - 1);
    this.maxBDate = hoy.toISOString().split("T")[0];
    this.maxBDate = { year: hoy.getFullYear(), month: hoy.getMonth() + 1, day: hoy.getDate() - 1 }
    hoy.setFullYear(hoy.getFullYear() - 90);
    this.minBDate = hoy.toISOString().split("T")[0];
  }

  getOficinasRegion() {
    let com = this.nuevaSolicitud.organizacion.comuna;
    let reg = this.nuevaSolicitud.organizacion.region;

    this.solService.getOficinasRegion(this.nuevaSolicitud.organizacion.region).subscribe((o: any) => {
      let coms = o.filter((c: any) => {
        return c.codigo_comuna == com
      });

      /*
        PARA LOS PILOTOS SE DEBE PONER LA OFICINA DE LA COMUNA QUE SE DEFINA

      */

      if (reg == 14) {
        coms = coms.filter((com: any) => com.codigo_oficina == 110);
      }

      this.oficinasAtencion = coms;

    });
  }
  getOficinasComuna() {
    this.solService.getOficinasRegion(this.nuevaSolicitud.organizacion.region).subscribe((o: any) => {
      console.log('Oficinas', o);
    });
  }

  handleVoucherEvent(data: any) {
    switch (data.action) {
      case "CLOSE":
        this.closeConfirmacion();
        break;
      case "PRINT":
        this.printTicket();
        break;
      default:
        break;

    }
  }

  ngOnInit(): void {
    this.doc = this.dom.nativeElement;
    this.loadRegiones();
    this.setBDates();

    const resp = this.settings.loadSettings().toPromise();
    this.regiones = environment.regiones;
    resp.then((settings: any) => {

      this.motivosUrgencia = settings.filter((s: any) => s.type == 'motivo-urgencia');
    })

    this.List$.subscribe((user: userdata) => {



      this.nuevaSolicitud.run_solicitante = user.username;
      this.nuevaSolicitud.organizacion = user.organizacion;
      let hoy = new Date();
      this.fechaSolicitud = this.setDateFormat({ day: hoy.getDate(), month: hoy.getMonth() + 1, year: hoy.getFullYear() });

      this.userLang = navigator.language;

      this.direccionAtencion = this.nuevaSolicitud.organizacion.direccion;
      this.regionAtencion = this.nuevaSolicitud.organizacion.region;
      this.changeRegionAtencion();
      setTimeout(() => {
        this.comunaAtencion = this.nuevaSolicitud.organizacion.comuna;
      }, 1000);
    })

    this.emailCtrl.valueChanges.subscribe((event) => {
      this.emailCtrl.setValue(event.toLowerCase(), { emitEvent: false });
    });

    this.checkUserRole();
    //TEST CODE
    /*   this.nuevaSolicitud = {
        "tipo": "normal",
        "titulo": "Daniela Garcia Contreras",
        "autor": "",
        "materia": "articulo 44",
        "run_solicitante": "10414071-8",
        "run_ciudadano": "",
        "estado": "1",
        "id_organizacion": 5,
        "organizacion": {
            "id_organizacion": 5,
            "run": "56327168-2",
            "nombre": "OrganizaciÃ³n de Prueba",
            "direccion": "Los Platanos 5321",
            "comuna": "172",
            "region": "7",
            "run_representante": "67832192-2",
            "nombre_representante": "Rep Servicio Urgencia",
            "direccion_representante": "",
            "comuna_representante": "",
            "region_representante": "",
            "run_administrador": "12345678-9",
            "nombre_administrador": "Admin Servicio Urgencia",
            "correo_representante": "RepLegal@cor.cl",
            "telefono_representante": "+5691234567",
            "correo_administrador": "Coord@cor.cl",
            "telefono_administrador": "+56912345678",
            "fecha_subscripcion_convenio": "2022-01-05",
            "fecha_resolucion_convenio": "2022-03-01",
            "numero_subscripcion_convenio": "123456",
            "estado": 1,
            "fecha_creacion": "2021-10-01T15:23:40.430Z",
            "fecha_modificacion": "2021-10-01T15:23:40.430Z"
        },
        "tramite": {
            "typeCtrl": "normal",
            "nameCtrl": "Daniela",
            "primerapCtrl": "Garcia",
            "segundoapCtrl": "Contreras",
            "nacimientoCtrl": "11-03-2022",
            "sexoCtrl": "F",
            "nacionalCtrl": "Antillas Holandesas",
            "pasaportCtrl": "",
            "proge1Ctrl": "",
            "telfCtrl": "",
            "proge2Ctrl": "",
            "domicilioCtrl": "",
            "regionCtrl": "10",
            "emailCtrl": "daniela@gmail.com",
            "comunaCtrl": "296",
            "antecedentesCtrl": ""
        }

      }
    */

    // console.log(this.nuevaSolicitud.organizacion);
    // this.getOficinasRegion();
  }
  userLang!: any;
  clearText() {
    return false;
  }

  checkUserRole() {
    this.List$.subscribe(data => {
      this.usrData = data;

      this.isAdmin = (this.usrData.rol.codigo === 0);
      this.canCreate = (this.usrData.rol.codigo === 1 || this.usrData.rol.codigo === 2);

      if (this.usrData.organizacion.estado == 0) {
        this.canCreate = false;
      }

      if (!this.canCreate) {
        this.router.navigate(['solicitudes'])
      }
    })


  }

  submitSolicitud(): void {

    if (this.formValid) {
      this.sendingForm = true;
      let user = JSON.parse(atob(sessionStorage.getItem('boToken')?.split(".")[1] || ''));
      this.nuevaSolicitud.run_solicitante = user.username;
      this.nuevaSolicitud.organizacion = user.organizacion;
      this.nuevaSolicitud.id_organizacion = user.organizacion.id_organizacion;
      this.nuevaSolicitud.titulo = `${this.nameCtrl.value} ${this.primerapCtrl.value} ${this.segundoapCtrl.value}`;
      this.nuevaSolicitud.materia = 'articulo 44';
      let data = $("#ticketForm").serializeArray();

      let formData: any = {};
      data.forEach(d => {

        formData[d.name] = d.value;
      });

      this.nuevaSolicitud.tramite = formData;
      this.nuevaSolicitud.tramite.paisNacionalCtrl = this.paisNacionalCtrl;
      this.nuevaSolicitud.tramite.esMenor = this.esMenor.value;
      this.nuevaSolicitud.tramite.emailCtrl = this.emailCtrl.value.trim() == '' ? 'sincorreo@registrocivil.cl' : this.emailCtrl.value;

      if (this.nuevaSolicitud.tipo == 'urgente') {
        this.nuevaSolicitud.tramite.regionCtrl = this.regionAtencion;
        this.nuevaSolicitud.tramite.comunaCtrl = this.comunaAtencion;
        this.nuevaSolicitud.tramite.motivoUrgencia = this.motivoUrgenciaCtrl.value;
      }
      this.solService.crearSolicitud(this.nuevaSolicitud).subscribe((ret: any) => {

        if (ret.status && ret.status == -1) {
          this.isDUP = true;
          let rName = this.regiones.find((r: any) => r.codigo_region == ret.data.region);
          let cName: any = rName.comunas.find((c: any) => c.codigo_comuna == ret.data.comuna);
          let fCreacion = this.formatDate(ret.data.fecha_creacion.split(" ")[0].split("-").reverse().join("-"))

          this.msgTitle = "Solicitud Existente";
          this.msgText = `Se ha detectado una solicitud de run existente para el extranjero:<br /><br />
          <div class='mt-30'>
            Nombre       : <strong>${ret.data.titulo}</strong><br />
            F. Nacimiento: <strong>${ret.data.fecha_nac}</strong><br />
            Nacionalidad : <strong>${ret.data.nacionalidad}</strong><br /><br />
            Solicitado por: <strong>${ret.data.organizacion},  ${cName.nombre_comuna}, ${rName.nombre_region}</strong> con fecha ${fCreacion}

          </div>`;

          this.showSysMsg = true;
        } else {
          if (ret && ret.id) {
            let nameCom = this.comunas.find((c: any) => c.codigo_comuna == this.comunaCtrl.value);
            /*  if (ret.agenda.code && ret.agenda.code != -99) {
               this.fechaVoucher = this.formatDate(ret.agenda.fecha);
               this.horaVoucher = ret.agenda.hora;
               this.direccionVoucher = ret.agenda.direccion + ', ' + nameCom?.nombre_comuna;
             } else {
               this.fechaVoucher = '08 de Abril de 2022';
               this.horaVoucher = '12:30 hrs.';
               this.direccionVoucher = 'Obispo Umaña N° 50, Estación Central';
             } */


            this.ticketNumber = ret.id;
            this.nuevaSolicitud["id"] = this.ticketNumber;
            
            this.showTicket();
            if(this.nuevaSolicitud.tipo == "urgente"){            
              this.solService.newUrgente();
            }
          } else {
            this.msgTitle = "Error Creando Solicitud";
            this.msgText = `Se ha producido un error al crear la solicitud:<br /><br />
            <div class='mt-30'> <strong>` + this.getErrorDescription(ret.titulo) + '</strong></div>';
            this.showSysMsg = true;
          }

        }

      })
    }
  }

  getErrorDescription(err: any) {
    let errLabel = '';
    switch (err) {
      case 'ER_DUP_ENTRY':
        errLabel = 'Solicitud Duplicada';
        break;
      default:
        errLabel = 'Error Interno'
        break;
    }
    return errLabel;
  }

  formatDate(f: string) {
    let meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    let fec = f.split('-');
    let out = fec[0] + ' de ' + meses[parseInt(fec[1])] + ' de ' + fec[2];

    return out;
  }

  setDateFormat(f: any) {
    if (f) {
      return `${f.day < 10 ? '0' + f.day : f.day}/${f.month < 10 ? '0' + f.month : f.month}/${f.year}`;
    } else {
      return '';
    }
  }

  showTicket() {
    let theDoc = this.doc.querySelector(".overlay");
    if (theDoc) {
      theDoc.style.display = 'block';

    }
    this.displayTicket = true;
  }

  closeConfirmacion() {
    this.router.navigateByUrl("/solicitudes/" + this.ticketNumber);
  }

  printTicket() {
    window.print();
  }

  getAccion(tipoAccion: any) {
    switch (tipoAccion) {
      case 'checkA':
        this.showSolicitudNormal = false;
        this.showSolicitudUrgencia = false;
        this.showButtonN = true;
        this.showButtonU = false;
        break;
      case 'checkB':
        this.showSolicitudUrgencia = false;
        this.showSolicitudNormal = false;
        this.showButtonU = true;
        this.showButtonN = false;
    }
  }

  //Paises, Regiones y Comunas

  paises: any = [];
  regiones: any = [{ codigo_region: -1, nombre_region: 'Seleccione Región', comunas: [] }];
  comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
  comunasAt = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
  region = {
    codigo_region: -1,
    comunas: []
  };
  cLookup: any = [];
  comuna = {};

  changeRegionAtencion() {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == this.regionAtencion);
    if (filterComunas) {
      this.comunasAt = filterComunas["comunas"];
      this.comunaAtencion = -1;
      //      this.comunaAtencionCtrl.setValue(-1);
    } else {
      this.comunasAt = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];

    }

  }

  changeRegion() {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == this.regionCtrl.value);
    if (filterComunas) {
      this.comunas = filterComunas["comunas"];
      this.comunaCtrl.setValue(-1);
    } else {
      this.comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
      this.comuna = {};
    }

  }

  changeComuna() {
    this.comuna = this.comunaCtrl.value;
  }

  loadRegiones() {
    let that = this;
    this.regiones = environment.regiones;
    this.paises = environment.paises; //.map( p => p.name);
  }
  cFound: boolean = false;
  cVal: string = '';

  countryLookup(evt: any) {
    evt.preventDefault();
    let valor = evt.target.value.toLowerCase();
    this.cVal = valor;
    this.cFound = true;
    if (valor.trim() == '') {
      this.cLookup = [];
    } else {
      this.cLookup = this.paises.filter((p: any) => p.name.toLowerCase().indexOf(valor) >= 0)
      if (this.cLookup.length == 0) {
        this.cFound = false;
        this.cLookup.push("No se encontraron coincidencias")
      } else {

        this.cFound = true;
      }
    }

  }

  selectCountry(evt: any, cId: any) {
    evt.preventDefault();
    let valor = evt.target.innerText;
    this.nacionalCtrl.setValue(valor);
    this.paisNacionalCtrl = cId;
    this.cLookup = [];

  }

  cleanSpaces(formControl: FormControl) {
    let value = formControl.value;

    value = value.trim().toUpperCase();
    value = value.replace(/\s+/g, ' ');

    formControl.setValue(value);
  }

}
