import userdata from 'src/app/store/userdata/userdata.interface';
import { IUserDataState } from './../store/userdata/userdata.reducer';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LoginService } from "../shared/login.service";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LogicalProjectPath } from '@angular/compiler-cli/src/ngtsc/file_system';
import { validate, format } from 'rut.js';
import { RutserviceService } from '../rutservice.service';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { loadUserData } from '../store/userdata/userdata.actions';
import { selectUserData } from '../store/userdata/userdata.selector';
import { UsersService } from '../shared/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  captchaSiteKey: string = environment.captchaSiteKey;
  loginOK: boolean = false;
  username: string = '';
  password: string = '';
  loginMsg: string = '';
  public List$: Observable<userdata>;

  constructor(
    public loginApi: LoginService,
    public router: Router,
    private rutval: RutserviceService,
    private userServices: UsersService,
    private store: Store<IUserDataState>


  ) {
    this.validatorRut = rutval
    this.List$ = this.store.pipe(select(selectUserData))

  }

  private validatorRut: RutserviceService;


  ngDoCheck(): void {
    this.loginOK = (this.username != '' && this.password != '');

  }

  ngOnInit(): void {
    if (sessionStorage.getItem('boReset') == 'true') {

      this.router.navigate(['/setpassword']);
      sessionStorage.removeItem('boReset');

    } else if (sessionStorage.getItem('boLogin') == 'true') {
      this.router.navigate(['/home']);
    }
  }


  valRut(evt: Event, context: string) {
    let el: any = evt.target;
    if (validate(el.value.trim())) {
      el.value = format(el.value, { dots: false });
    }
    if (!validate(el.value.trim())) {
      this.loginMsg = "Debe ingresar un Usuario válido"
      this.loginOK = true;
      el.focus();
    } else {
      this.loginMsg = ""
      this.loginOK = false;
      if (context === "username") this.username = el.value;
    }
  }


  actLogin = (evt: any) => {
    //this.loginOK = false;

    if (this.username == '' || this.password == '') {
      this.loginMsg = "Ingrese sus credenciales";
      this.loginOK = true;
      setTimeout(() => {
        this.loginMsg = '';
      }, 3000)

    } else {
      evt.preventDefault();
      let loginData = {
        /*         tt: 'cu',
                tk: 'token_clave_unica', */
        username: this.username,
        password: this.password
      }

      this.loginApi.UserLogin(loginData).subscribe((data: any) => { //: { userStatus: number, username: string, accessToken: string, opt: string }



        if (data && data.accessToken) {
          let usrData = {
            token: data.accessToken,
            ...JSON.parse(atob(data.accessToken.split(".")[1])),
            fullName: data.fullName,
            region: data.region
          }

          sessionStorage.setItem("boOpt", data.opt);
          sessionStorage.setItem("boToken", data.accessToken);
          sessionStorage.setItem("usrData", JSON.stringify(usrData));

          let newUserData: userdata = usrData;

          this.userServices.getUserInfo(usrData.username).subscribe((ret: any) => {

            ret.fullName = ret.firstName + " " + ret.lastName;
            ret.token = usrData.token
            this.store.dispatch(loadUserData(ret));
          })



          if (usrData.organizacion.id_organizacion == 1) {
            if (window.location.hostname.indexOf('solicitarun.srcei.cl') > 0) {
              sessionStorage.removeItem("boData");
              sessionStorage.removeItem("boToken");
              sessionStorage.removeItem("boOpt");

              let h = window.location.href.replace('solicitarun.srcei.cl', 'articulo44.srcei.cl');
              window.location.replace(h);
            }

          }

          if (data.userStatus == 0) {

            sessionStorage.setItem("boReset", 'true');
            this.router.navigate(["/setpassword"]);
          } else {

            sessionStorage.setItem("boLogin", 'true');
            this.router.navigate(["/home"]);
          }
        }

        if (data && data.accessToken) {

          sessionStorage.setItem("boData", JSON.stringify(data))
          sessionStorage.setItem("boToken", data.accessToken);
          sessionStorage.setItem("boOpt", data.opt);

          let d = JSON.parse(atob(sessionStorage.boToken.split(".")[1]));

          if (d.organizacion.id_organizacion == 1) {
            if (window.location.hostname.indexOf('solicitarun.srcei.cl') > 0) {
              sessionStorage.removeItem("boData");
              sessionStorage.removeItem("boToken");
              sessionStorage.removeItem("boOpt");

              let h = window.location.href.replace('solicitarun.srcei.cl', 'articulo44.srcei.cl');

              window.location.replace(h);
            }

          }

          if (data.userStatus == 0) {

            sessionStorage.setItem("boReset", 'true');
            this.router.navigate(["/setpassword"]);
          } else {

            sessionStorage.setItem("boLogin", 'true');
            this.router.navigate(["/home"]);
          }
        }
      },
        (error: any) => {
          this.loginMsg = "Login erroneo. Verifique sus credenciales.";
          this.loginOK = true;
          setTimeout(() => {
            this.loginMsg = '';
          }, 3000)
        })
    }
  }

  cbCaptcha(captchaResponse: string) {
    this.loginOK = true;
  }

  cbCaptchaError() {
    this.loginOK = false;
  }

}
