import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable()
export class SolicitudesService {
  constructor(private http: HttpClient) { }

  loadSolicitudes(page: number = 1) {
    return this.http
      .get(`${environment.boBackendAPI}/solicitudes?page=${page || 1}`)
      .pipe(map((solicitudes) => solicitudes || []));
  }

  loadSolicitudesHistoricos(page: number = 1) {
    return this.http
      .get(`${environment.boBackendAPI}/solicitudes/historico?page=${page || 1}`)
      .pipe(map((solicitudes) => solicitudes || []));
  }

  loadUrgencias(page: number = 1) {
    return this.http
      .get(`${environment.boBackendAPI}/solicitudes/urgencias?page=${page || 1}`)
      .pipe(map((solicitudes) => solicitudes || []));
  }

  private API_ART44 = environment.API_GESTION_ART44; 
  //"https://gestion.articulo44-dev.srcei.cl/api/art44/";

  newUrgente() {
    return this.http
      .get(this.API_ART44 + "newurg")
      .pipe(map((solicitudes) => solicitudes || []));
  }

  checkUrgentes(ids: number[]) {
    return this.http
      .post(this.API_ART44 + "churgentes", {ids})
      .pipe(map((solicitudes) => solicitudes || []));
  }


  loadStats() {
    return this.http
      .get(`${environment.boBackendAPI}/solicitudes/stats`)
      .pipe(map((stats) => stats || []));
  }

  exportGrid(filters: any) {
    let filtros = Object.entries(filters)
      .filter(([, value]) => value !== "")
      .map(([key, value]) => `${key}=${value}`)

      .join('_');
    return this.http
      .post(`${environment.boBackendAPI}/solicitudes/export`, { body: JSON.stringify(filters) }, { responseType: 'blob' })
      .subscribe((data: Blob) => {

        let fecha = new Date().toLocaleDateString('es-CL')

        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'solicitudes_art44' + (filtros != '' ? ("_filtrado") : "") + "_" + fecha.replace(/\ /g, "_") + '.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);


      });
  }

  filterGrid(filters: any, page: number = 1) {
    return this.http
      .post(`${environment.boBackendAPI}/solicitudes/filter?page=${page || 1}`, { body: JSON.stringify(filters) })
      .pipe(map((stats) => stats || []));

  }

  filterGridUrgencias(filters: any, page: number = 1) {
    return this.http
      .post(`${environment.boBackendAPI}/solicitudes/filterurgentes?page=${page || 1}`, { body: JSON.stringify(filters) })
      .pipe(map((stats) => stats || []));

  }

  crearSolicitud(solicitud: any) {
    let resp;
    resp = this.http
      .post(`${environment.boBackendAPI}/solicitudes`, solicitud)
      .pipe(map((data) => data || {}))
    return resp;

  }

  globalSearch(term: string, page: number = 1) {
    const url = `${environment.boBackendAPI}/solicitudes/globalsearch?page=${page || 1}`;
    const resp = this.http
      .post(url, { term: term })
      .pipe(map((solicitud) => solicitud || {}));
    return resp;
  }

  searchSolicitud(solId: any) {
    const url = `${environment.boBackendAPI}/solicitudes/${solId}`;
    const resp = this.http
      .get(url)
      .pipe(map((solicitud) => solicitud || {}));
    return resp;
  }

  updateSolicitud(solicitud: any) {
    const resp = this.http
      .put(`${environment.boBackendAPI}/solicitudes`, solicitud)
      .pipe(map((data) => data || {}));
    return resp;
  }

  updateStatus(settings: any) {
    const resp = this.http
      .put(`${environment.boBackendAPI}/solicitudes/status/${settings.id}`, settings)
      .pipe(map((data) => data || {}));
    return resp;
  }

  updateAgenda(settings: any) {
    const resp = this.http
      .put(`${environment.boBackendAPI}/solicitudes/agenda/${settings.id}`, { fecha_agenda: settings.fecha_agenda })
      .pipe(map((data) => data || {}));
    return resp;
  }

  liberaAgenda(idSolicitud: any) {
    const resp = this.http
      .put(`${environment.boBackendAPI}/solicitudes/agenda/liberar`, { idSolicitud: idSolicitud })
      .pipe(map((data) => data || {}));
    return resp;
  }

  updateOwner(settings: any) {
    const resp = this.http.put(`${environment.boBackendAPI}/solicitudes/owner/${settings.id}`, settings)
      .pipe(map((data) => data || {}));
    return resp;
  }

  updateRun(settings: any) {
    const resp = this.http
      .put(`${environment.boBackendAPI}/solicitudes/run/${settings.id}`, { run_ciudadano: settings.run_ciudadano })
      .pipe(map((data) => data || {}));
    return resp;
  }

  getOficinasRegion(region: number) {

    const resp = this.http
      .get(`${environment.api_oficinas}/${region}`)
      .pipe(map((data) => data || {}));
    return resp;
  }

}
