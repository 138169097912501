<div class="overlay" *ngIf="showSysMsg || displayTicket"></div>
<div id="title" class="container no-print">
  <h3><i class="bi bi-file-earmark-text"></i> {{title}}</h3>
  <blockquote>{{nuevaSolicitud.organizacion.nombre}}</blockquote>
  <br />
  <form id="ticketForm" class="form-horizontal no-print" (submit)="submitSolicitud()">
    <h5>Datos Básicos</h5>
    <div class="row">
      <div class="col-md-6 ticketType">
        <label for="tiposol">Tipo de solicitud:</label>
        <div>
          <span>
            <input [(ngModel)]="nuevaSolicitud.tipo" type="radio" name="typeCtrl" value="normal" checked="checked">
            Normal
          </span>
          <span>
            <input [(ngModel)]="nuevaSolicitud.tipo" type="radio" name="typeCtrl" value="urgente"> Urgencia
          </span>
        </div>

      </div>
      <div class="col-md-5 offset-md-1 text-right">
        <p class="mb-4">Fecha de Solicitud: <strong>{{fechaSolicitud}}</strong></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="name"><span class="required">*</span> Nombres</label>
        <input type="text" [formControl]="nameCtrl" autocomplete="off" name="nameCtrl" class="form-control"
          placeholder="Nombres" aria-label="Nombres" (blur)="cleanSpaces(nameCtrl)">
        <small *ngIf="nameCtrl.hasError('required') && nameCtrl.dirty" class="error">Campo requerido</small>
      </div>

      <div class="col-md-4">
        <label for="primerap"><span class="required">*</span> Primer Apellido</label>
        <input type="text" [formControl]="primerapCtrl" autocomplete="off" name="primerapCtrl" class="form-control"
          placeholder="Primer Apellido" aria-label="" required (blur)="cleanSpaces(primerapCtrl)">
        <small *ngIf="primerapCtrl.hasError('required') && primerapCtrl.dirty" class="error">Campo requerido</small>
      </div>
      <div class="col-md-4">
        <label for="segundoap">Segundo Apellido</label>
        <input type="text" [formControl]="segundoapCtrl" autocomplete="off" name="segundoapCtrl" class="form-control"
          placeholder="Segundo Apellido" aria-label="" (blur)="cleanSpaces(segundoapCtrl)">
        <small *ngIf="segundoapCtrl.hasError('required') && segundoapCtrl.dirty" class="error">Campo requerido</small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <label for="nacimiento"><span class="required">*</span> Fecha Nacimiento</label>
        <div class="input-group">

          <input type="text" class="form-control fechaNac" [formControl]="nacimientoCtrl" name="nacimientoCtrl"
            (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}"
            placeholder='dd-mm-aaa' [maxDate]="maxBDate" maxlength="10" autocomplete='off'>
          <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
            <i class="bi bi-calendar-date"></i>
          </button>

        </div>

        <small *ngIf="nacimientoCtrl.hasError('required') && nacimientoCtrl.dirty" class="error">Campo requerido</small>
      </div>
      <div class="col-md-4">
        <label for="sexo"><span class="required">*</span> Sexo</label>
        <select name="sexoCtrl" [formControl]="sexoCtrl" class="form-control">
          <option value="N/I">-</option>
          <option value="F">Femenino</option>
          <option value="M">Masculino</option>
          <option value="I">Indeterminado</option>
        </select>
        <small *ngIf="sexoCtrl.hasError('required') && sexoCtrl.dirty" class="error">Campo requerido</small>

      </div>

      <div class="col-md-4">
        <label for="nacional"><span class="required">*</span> País de Origen</label>
        <input autocomplete='off' (keyup)='countryLookup($event)' type="text" [formControl]="nacionalCtrl"
          name="nacionalCtrl" class="form-control" placeholder="Buscar país" aria-label="">
        <div class="pRes" *ngIf="cLookup.length > 0 && cFound; else cNotFound">
          <a href="javascript:;" *ngFor="let cObj of cLookup" (click)='selectCountry($event, cObj.id)'>{{cObj.name}}</a>
        </div>
        <ng-template #cNotFound>
          <div class="pRes notFound" *ngIf="cFound == false && cVal != ''">
            No se encontraron coincidencias
          </div>

        </ng-template>
        <small *ngIf="nacionalCtrl.hasError('required') && nacionalCtrl.dirty" class="error">Campo requerido</small>

      </div>
    </div>

    <div class="row mb-3" *ngIf="nuevaSolicitud.tipo =='urgente'">
      <div class="col-md-6">
        <label for="motivoUrgenciaCtrl"><span class="required">*</span> Motivo Urgencia</label>
        <select [formControl]="motivoUrgenciaCtrl" class="form-control">
          <option value="-1" selected>Selecione el motivo</option>
          <option *ngFor="let option of motivosUrgencia" [value]="option.codigo">{{option.glosa}}</option>
        </select>

        <label class="mt-1" for="justUrgenciaCtrl"><span class="required">*</span> Justificación Urgencia</label>
        <textarea [formControl]="justUrgenciaCtrl" class="form-control justUrgenciaCtrl" name="justUrgenciaCtrl"
          rows="7" (blur)="cleanSpaces(justUrgenciaCtrl)"></textarea>
      </div>
      <div class="col-md-6">
        <label for="direccionAtencionCtrl"><span class="required">*</span> Dirección de Atención</label>
        <input type="text" [(ngModel)]='direccionAtencion' name="direccionAtencionCtrl" class="form-control"
          placeholder="Dirección de Atención" aria-label="">
        <small *ngIf="direccionAtencionCtrl.hasError('required') && direccionAtencionCtrl.dirty" class="error">Campo
          requerido</small>

        <label class='mt-1' for="regionAtencionCtrl"><span class="required">*</span> Región</label>
        <select [(ngModel)]="regionAtencion" class="form-control" name="regionAtencionCtrl"
          (change)="changeRegionAtencion()">
          <option value="-1">- Seleccione Región -</option>
          <option *ngFor="let region of regiones" [value]="region.codigo_region">
            {{region.nombre_region}}
          </option>
        </select>

        <label class='mt-1' for="comuna"><span class="required">*</span> Comuna: </label>
        <select [(ngModel)]="comunaAtencion" class="form-control" name="comunaAtencionCtrl" (change)="changeComuna()">
          <option *ngFor="let comuna of comunasAt" [value]="comuna.codigo_comuna">
            {{comuna.nombre_comuna}}
          </option>
        </select>
        <small *ngIf="comunaAtencionCtrl.hasError('required') && comunaCtrl.dirty" class="error">Campo requerido</small>

      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr>
      </div>
    </div>

    <h5>Información Adicional</h5>

    <div class="row p-1" *ngIf="isMenor">
      <div class="col-md-12">
        <div class="alert alert-warning" role="alert">
          <strong>Nota:</strong> La fecha de nacimiento indicada corresponde a un menor de edad. Se requiere ingresar
          los datos de al menos uno los progenitores o del acompañante del menor de edad.
        </div>
      </div>
      <div class="col-md-3">
        <label for="proge1"><span class="required">*</span> RUN/DNI/Pasaporte</label>
        <input type="text" [formControl]="acompanianteID" autocomplete="off" name="acompanianteID" class="form-control"
          placeholder="Identificación Acompáñante" aria-label="" (blur)="cleanSpaces(acompanianteID)">
      </div>
      <div class="col-md-5">
        <label for="proge1"><span class="required">*</span> Acompañante</label>
        <input type="text" [formControl]="acompanianteMenor" autocomplete="off" name="acompanianteMenor"
          class="form-control" placeholder="Nombre Acompañante" aria-label="" (blur)="cleanSpaces(acompanianteMenor)">
      </div>
      <div class="col-md-4">
        <label for="proge1"><span class="required">*</span> Relación con el Menor</label>
        <input type="text" [formControl]="relacionMenor" autocomplete="off" name="relacionMenor" class="form-control"
          placeholder="Relación con el Menor" aria-label="" (blur)="cleanSpaces(relacionMenor)">
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label for="pasaport">Pasaporte/DNI</label>
        <input type="text" [formControl]="pasaportCtrl" autocomplete="off" name="pasaportCtrl" class="form-control"
          placeholder="Pasaporte/dni" aria-label="" (blur)="cleanSpaces(pasaportCtrl)">
        <small *ngIf="pasaportCtrl.hasError('required') && pasaportCtrl.dirty" class="error">Campo requerido</small>

      </div>
      <div class="col-md-9">

        <label for="proge1">Progenitor 1</label>
        <div class="form-row ">
          <input type="text" [formControl]="proge1Ctrl" autocomplete="off" name="proge1Ctrl" class="col form-control"
            placeholder="Nombre" (blur)="cleanSpaces(proge1Ctrl)">
          <input type="text" [formControl]="apellido1proge1Ctrl" autocomplete="off" name="apellido1proge1Ctrl"
            class="mx-1 col form-control" placeholder="Primer Apellido" (blur)="cleanSpaces(apellido1proge1Ctrl)">
          <input type="text" [formControl]="apellido2proge1Ctrl" autocomplete="off" name="apellido2proge1Ctrl"
            class="col form-control" placeholder="Segundo Apellido" (blur)="cleanSpaces(apellido2proge1Ctrl)">
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label for="telf">Teléfono</label>
        <input type="tel" [formControl]="telfCtrl" autocomplete="off" name="telfCtrl" class="form-control"
          placeholder="Teléfono" aria-label="" (blur)="cleanSpaces(telfCtrl)">
        <small *ngIf="telfCtrl.hasError('required') && telfCtrl.dirty" class="error">Campo requerido</small>

      </div>
      <div class="col-md-9">
        <label for="proge2">Progenitor 2</label>
        <div class="form-row ">
          <input type="text" [formControl]="proge2Ctrl" autocomplete="off" name="proge2Ctrl" class="col form-control"
            placeholder="Nombre" (blur)="cleanSpaces(proge2Ctrl)">
          <input type="text" [formControl]="apellido1proge2Ctrl" autocomplete="off" name="apellido1proge2Ctrl"
            class="mx-1 col form-control" placeholder="Primer Apellido" (blur)="cleanSpaces(apellido1proge2Ctrl)">
          <input type="text" [formControl]="apellido2proge2Ctrl" autocomplete="off" name="apellido2proge2Ctrl"
            class="col form-control" placeholder="Segundo Apellido" (blur)="cleanSpaces(apellido2proge2Ctrl)">

        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <label for="domicilio">Domicilio</label>
        <div class="form-row">
          <input type="text" [formControl]="domicilioCtrl" autocomplete='off' name="domicilioCtrl"
            class="form-control col" placeholder="Calle" style='width:15rem' (blur)="cleanSpaces(domicilioCtrl)">
          <input type="text" [formControl]="domicilioNumeroCtrl" autocomplete='off' name="domicilioNumeroCtrl"
            class="form-control col mx-1" placeholder="Número" style='width:6rem'
            (blur)="cleanSpaces(domicilioNumeroCtrl)">
          <input type="text" [formControl]="domicilioLetraCtrl" autocomplete='off' name="domicilioLetraCtrl"
            class="form-control col" placeholder="Letra" style='width:4rem' (blur)="cleanSpaces(domicilioLetraCtrl)">
        </div>
      </div>
      <div class="col-md-5">
        <label for="region"><span class="required" *ngIf="nuevaSolicitud.tipo!='urgente'">*</span> Región</label>
        <select [formControl]="regionCtrl" class="form-control" name="regionCtrl" (change)="changeRegion()">
          <option value="-1">- Seleccione Región -</option>
          <option *ngFor="let region of regiones" [value]="region.codigo_region">
            {{region.nombre_region}}
          </option>
        </select>
        <small *ngIf="regionCtrl.hasError('required') && regionCtrl.dirty" class="error">Campo requerido</small>

      </div>
    </div>

    <div class="row">
      <div class="col-md-7">
        <label for="email"> Correo electrónico</label> (<small>Para envío de comprobante de solicitud.</small>)

        <input type="email" [formControl]="emailCtrl" autocomplete='off' name="emailCtrl" class="form-control"
          placeholder="Dejar en blanco si no tiene email" aria-label="" required (blur)="cleanSpaces(emailCtrl)">
        <small *ngIf="emailCtrl.hasError('required') && emailCtrl.dirty" class="error">Campo requerido</small>
      </div>
      <div class="col-md-5">
        <label for="comuna"><span class="required" *ngIf="nuevaSolicitud.tipo!='urgente'">*</span> Comuna: </label>
        <select [formControl]="comunaCtrl" class="form-control" name="comunaCtrl" (change)="changeComuna()">
          <option *ngFor="let comuna of comunas" [value]="comuna.codigo_comuna">
            {{comuna.nombre_comuna}}
          </option>
        </select>
        <small *ngIf="comunaCtrl.hasError('required') && comunaCtrl.dirty" class="error">Campo requerido</small>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label for="">Identificador Interno</label>
        <input type="text" class="form-control" autocomplete='off' [formControl]='idInternoCtrl' name="id_interno"
          (blur)="cleanSpaces(idInternoCtrl)" />
      </div>

      <div class="col-md-9">
        <label for="exampleFormControlTextarea1">Otros Antecedentes</label>
        <textarea class="form-control" name="antecedentesCtrl" rows="3"></textarea>
      </div>
    </div>

    <div class="col-md-4">
      <button *ngIf="showButtonN" type="button" id="showButtonN" class="btn btn-primary btn-sm mt-4 btn-lg btn-sm"
        data-toggle="modal" data-target="#ventanaNormal">
        <i class="bi bi-check-lg"></i> Guardar
      </button>
    </div>
    <div class="col-md-4">
      <button *ngIf="showButtonU" type="button" id="showButtonU" class="btn btn-primary btn-sm mt-4 btn-lg btn-sm"
        data-toggle="modal" data-target="#ventanaUrgencia">
        Guardar
      </button>
    </div>
    <button *ngIf='!isDUP && !sendingForm' class="btn btn-primary mt-2" type="submit" [disabled]='!formValid || isDUP'
      [class]='{disabled: !formValid || isDUP}'>
      <i class="bi bi-check-lg"></i> Ingresar Solicitud
    </button>
  </form>

</div>


<!-- VOUCHER -->
<div *ngIf="ticketNumber">
  <app-voucher (voucherEvent)="handleVoucherEvent($event)" [voucher]="ticketNumber"></app-voucher>
</div>

<div class="sysMsg" *ngIf="showSysMsg">
  <i class="bi bi-exclamation-triangle-fill" style='color:#666; font-size:4rem'></i>
  <div>
    <h4>{{msgTitle}}</h4>
    <div [innerHTML]='msgText'></div>
    <div class="mt-2 text-right">
      <a href="javascript:;" class="btn btn-sm btn-secondary" (click)="closeMsg()">Cerrar</a>
    </div>
  </div>
</div>