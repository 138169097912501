import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable()
export class OrganizacionesService {
  constructor(private http: HttpClient) {}

  loadOrganizaciones() {
    return this.http
      .get(`${environment.boBackendAPI}/organizaciones`)
      .pipe(map((organizaciones) => organizaciones || []));
  }

  createOrganizacion(organizacion: any) {
    let options = new HttpHeaders();

    options.set('Content-Type', 'application/json;charset=utf-8');
    options.set("Accept", "application/json, text/plain, */*");
    return this.http
      .post(`${environment.boBackendAPI}/organizaciones`,
        organizacion,
        {headers: options})
      .pipe(map((organizacion) => organizacion || {}));
  }

  updateOrganizacion(organizacion: any) {
    let options = new HttpHeaders();

    options.set('Content-Type', 'application/json;charset=utf-8');
    options.set("Accept", "application/json, text/plain, */*");
    return this.http
      .put(`${environment.boBackendAPI}/organizaciones/${organizacion.id_organizacion}`,
        organizacion,
        {headers: options})
      .pipe(map((organizacion) => organizacion || {}));
  }

  updateStatus(settings: any) {
    const resp = this.http.put(`${environment.boBackendAPI}/solicitudes/status/${settings.id}`,
    settings)
    .pipe(map((data) => data || {}));
    return resp;
  }
  updateOwner(settings: any) {
    const resp = this.http.put(`${environment.boBackendAPI}/solicitudes/owner/${settings.id}`, settings)
    .pipe(map((data) => data || {}));
    return resp;
  }
}
