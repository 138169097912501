import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

//import { isNumber, toInteger, padNumber } from '@ng-bootstrap/ng-bootstrap/util/util';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): any {
      if (value) {
          const dateParts = value.trim().split('-');
          return {day: parseInt(dateParts[0]), month: parseInt(dateParts[1]), year: parseInt(dateParts[2])};
      /* if (dateParts.length === 1 && !isNaN(dateParts[0])) {
        return {day: toInteger(dateParts[0]), month: null, year: null};
      } else if (dateParts.length === 2 && isNumber(dateParts[0]) && isNumber(dateParts[1])) {
        return {day: toInteger(dateParts[0]), month: toInteger(dateParts[1]), year: null};
      } else if (dateParts.length === 3 && isNumber(dateParts[0]) && isNumber(dateParts[1]) && isNumber(dateParts[2])) {
      } */
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
        `${!isNaN(date.day) ? this.padNumber(date.day) : ''}-${!isNaN(date.month) ? this.padNumber(date.month) : ''}-${date.year}` :
        '';
  }

  padNumber(n: any): string {
    return n < 10 ? '0' + n : n;
  }
}