import { Component, OnInit } from '@angular/core';
import { SolicitudesComponent } from '../solicitudes/solicitudes.component';
import * as Highcharts from 'highcharts';
import { SolicitudesService } from '../shared/solicitudes.service';



@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(
    private solService: SolicitudesService
  ) { }

  statsTimer: any = null;

  stats = {
    total:0,
    cerradas: 0,
    urgentes: 0,
    peritaje: 0,
    promedio: 0,
    solpromedio: 0,
  }

  updateStats() {
    this.solService.loadStats().subscribe( (resp: any) => {
      resp.forEach( (s: any) => {
        switch(s.stat){
         case 'total':
           this.stats.total = s.count
           break;
         case 'urgentes':
           this.stats.urgentes = s.count
           break;
     
         case 'cerradas':
           this.stats.cerradas = s.count
           break;
         case 'peritaje':
           this.stats.peritaje = s.count
           break;
           case 'atpromedio':
             this.stats.promedio = (+(parseInt(s.count) / 60 ).toFixed(1)) || 0;
             break;
            case 'solpromedio':
              this.stats.solpromedio = (+(parseInt(s.count) / 86400 ).toFixed(1)) || 0;
              break;
         }
      })
     })
  }
  autoLoader() {
    let that = this;
    this.statsTimer = setInterval( () => {
      that.updateStats();
    }, 5000);
  }
  ngOnInit(): void {
    let that = this;
    this.width = 100;
    this.height = 300;
    this.dataLine = [];
    this.dataCols = [];
    this.dataPie = [];
    for (let idx = 0; idx < 10; idx++) {
      this.dataLine.push(Math.floor(Math.random()*18*(Math.ceil(Math.random())) + 10));
      this.dataCols.push(Math.floor(Math.random()*120 + 50));
      if(idx < 6){
        this.dataPie.push({y:Math.floor(Math.random()*150 + 70), name: this.labels[idx]});
      }
    }

    this.updateStats();

    this.doTheCharts();

    this.autoLoader();

  }
  ngOnDestroy() {
    clearInterval(this.statsTimer);
  }
  width=0;
  height=0;
  labels: string[] = ["Arica", "La Serena", "Temuco", "Santiago", "Valdivia", "Punta Arenas"];
  dataLine: number[] = [];
  dataCols: number[] = [];
  dataPie: any[] = [];
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {}
  chartOptionsPie: Highcharts.Options = {}
  chartOptionsColumns: Highcharts.Options = {}

  doTheCharts(): void {

    this.chartOptions = {
      title: {
        text: 'Cantidad de Datos en Lineas',
        style: {
          color: '#777'
        }
      },
      chart:{
        backgroundColor: "transparent",
        events: {
          load: () => {

          }
        }
      },
      series: [
        {
          name: 'Acumulado',
          type: "line",
          data: this.dataLine
        }
      ]
    };

    this.chartOptionsColumns  = {
      title: {
        text: 'Cantidad de Datos en Columnas',
        style: {
          color: '#777'
        }
      },
      chart:{
        backgroundColor: "transparent",
        events: {
          load: () => {
          }
        }
      },
      series: [
        {
          name: 'Personas',
          type: "column",
          data: this.dataCols
        }
      ]
    };

    this.chartOptionsPie  = {
      title: {
        text: 'Distribución de Datos por Ciudad',
        style: {
          color: '#777'
        }
      },
      chart:{
        backgroundColor: "transparent",
        events: {
          load: () => {
          }
        }
      },
      plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
      series: [
        {
          name: 'Datos por Ciudad',
          type: "pie",
          data: this.dataPie
        }
      ]
    };


  }
}
