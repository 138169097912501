<div id="sidebar" class="no-print">
  <ul>
    <li *ngFor="let m of opts">
      <a [routerLink]="m.ruta" routerLinkActive="router-link-active"   routerLinkActive="active" >{{m.text}}</a>
    </li>
  </ul>
</div>
<!--
  <li><a routerLink="home" routerLinkActive="active" >Home</a></li>
  <li><a routerLink="solicitudes" routerLinkActive="active" >Solicitudes</a></li>
  <li><a routerLink="users" routerLinkActive="active" >Usuarios</a></li>
  <li><a routerLink="dashboard" routerLinkActive="active" >Dashboard</a></li>
  <li><a routerLink="organizaciones" routerLinkActive="active" >Organizaciones</a></li>
  <li><a routerLink="setup" routerLinkActive="active" >Configuración</a></li>

-->
