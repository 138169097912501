import { Component, OnInit, ElementRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OrganizacionesService } from '../shared/organizaciones.service';
import { LanguageApp } from '../shared/dataTableLang';
import { SettingsService } from '../shared/settings.service';
import { UsersService } from '../shared/users.service'
import {
  allOrganizaciones,
} from '../store/organizaciones.selector';
import { OrganizacionModel } from './organizaciones.model';
import { environment } from 'src/environments/environment';
import { RutserviceService } from '../rutservice.service';
import { validate, format } from 'rut.js';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import userdata from '../store/userdata/userdata.interface';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { selectUserData } from '../store/userdata/userdata.selector';


@Component({
  selector: 'app-organizaciones',
  templateUrl: './organizaciones.component.html',
  styleUrls: ['./organizaciones.component.css']
})

export class OrganizacionesComponent implements OnInit {
  public List$: Observable<userdata>;
  loading: boolean = true;
  constructor(
    private orgService: OrganizacionesService,
    private settingService: SettingsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private rutval: RutserviceService,
    private modalService: NgbModal,
    private dom: ElementRef,
    private store: Store<IUserDataState>,
  ) {

    this.List$ = this.store.pipe(select(selectUserData))
  }

  doc!: any;

  organizaciones!: any[];
  organizacion: any = null;
  nuevaOrg = {
    orgName: '',
    orgRun: '',
    orgAddress: '',
    orgComuna: '',
    orgRegion: '',
    repFirstName: '',
    repLastName: '',
    repRun: '',
    repAddress: '',
    repComuna: '',
    repRegion: '',
    admFirstName: '',
    admLastName: '',
    admRun: '',
    correo_representante: '',
    confirEmail_rep: '',
    telefono_representante: '',
    correo_administrador: '',
    confirEmail_adm: '',
    telefono_administrador: '',
    fecha_subscripcion_convenio: '',
    fecha_resolucion_convenio: '',
    numero_subscripcion_convenio: '',
  };

  regiones: any = [{ codigo_region: -1, nombre_region: 'Seleccione Región', comunas: [] }];
  comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
  region = {};
  comuna = {};

  uiMsg = 'Mensaje de prueba';
  showMsg = false;
  newStatus: any;
  isAdmin: boolean = false;
  canCreate: boolean = false;
  canUpdate: boolean = false;
  canUpdateOwner: boolean = false;
  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: []
  }
  users: any = [];
  usrData: any;
  changeStatusForm!: FormGroup;
  assignOwnerForm!: FormGroup;

  triggerModal(content: any) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((res) => { }, (res) => {
      //Reset Values
      this.organizacion = null;
      this.canUpdate = false;
      this.canUpdateOwner = false;
    });
  }

  changeRegion() {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == parseInt(this.nuevaOrg.orgRegion));

    if (filterComunas) {
      this.comunas = filterComunas["comunas"];
    } else {
      this.comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
    }
  }

  loadRegiones() {
    this.regiones = environment.regiones;
  }


  loadOrganizaciones() {
    this.orgService.loadOrganizaciones().subscribe((organizaciones: any) => {
      this.organizaciones = organizaciones;

      $('#dataTable').DataTable().destroy();
      let that = this;
      setTimeout(function () {
        $('#dataTable').DataTable({
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          lengthMenu: [5, 10, 20],
          language: LanguageApp.spanish_datatables
        });
      }, 0);
      that.loading = false;

    });
  }

  checkUserRole() {
    this.List$.subscribe(data => {

      this.usrData = data;

      switch (this.usrData.rol.codigo) {
        case 0:
          this.isAdmin = true;
          this.canCreate = true;
          break;
        case 20:
          this.canCreate = true;
          break;
      }

    })


  }


  valRut(evt: Event, context: string) {
    let el: any = evt.target;
    if (el.value.trim() == '') {
      this.uiMsg = "Debe completar el campo Run "
      this.showMsg = true;
      el.focus();
    }
    else {
      if (validate(el.value.trim())) {
        el.value = format(el.value, { dots: false });
      }
      if (!validate(el.value.trim())) {
        this.uiMsg = "Debe ingresar un RUN válido"
        this.showMsg = true;
        el.focus();
      } else {
        this.uiMsg = ""
        this.showMsg = false;
        if (context === "orgRun") this.nuevaOrg.orgRun = el.value;
        if (context === "repRun") this.nuevaOrg.repRun = el.value;
        if (context === "admRun") this.nuevaOrg.admRun = el.value;

      }
    }
  }


  ngOnInit(): void {
    this.checkUserRole();
    this.doc = this.dom.nativeElement;
    this.loadRegiones();
    this.loadOrganizaciones();


  }

  crearOrganizacion(content: any) {
    this.triggerModal(content);
    let fS = document.querySelector("#fecha_sub");
    let fC = document.querySelector("#fecha_conv");
    let hoy = new Date();
    let maxD = hoy.toISOString().split("T")[0];

    fS?.setAttribute("max", maxD);
    fC?.setAttribute("max", maxD);

  }

  showMessage(txt: string) {
    this.uiMsg = txt;
    this.showMsg = true;
  }

  closeMsg() {
    this.showMsg = false;
    this.uiMsg = '';
  }

  validateField(evt: any) {
    let fld: HTMLInputElement = evt.target;
    if (fld.value.trim() == '') {
      this.uiMsg = "Debe completar el campo " + fld.getAttribute("placeholder")
      this.showMsg = true;
      fld.focus();
    } else {
      this.uiMsg = "";
      this.showMsg = false;
    }
  }

  validaNuevaOrg(o: OrganizacionModel) {
    if (o.run == '' || o.nombre == '' || o.direccion == '' ||
      o.comuna == '' || o.region == '' || o.run_representante == '' ||
      o.run_administrador == '' || o.numero_subscripcion_convenio == '' ||
      o.correo_administrador == '' || o.correo_representante == '' ||
      o.telefono_administrador == '' || o.telefono_representante == '' ||
      o.fecha_resolucion_convenio == '' || o.fecha_subscripcion_convenio == '') {
      this.showMessage("Debe completar los campos requeridos");
      return false;
    } else {

      if (this.checkDates()) {

        //Validar Org Existente      
        let fOrg = this.organizaciones.find(o => o.run == this.nuevaOrg.orgRun);

        if (fOrg) {
          this.showMessage("Ya existe un convenio para este rut. Valide la información e intente nuevamente.");
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  checkDates() {
    let fS: any = document.querySelector("#fecha_sub");
    let fC: any = document.querySelector("#fecha_conv");
    
    let FS = new Date(fS.value);
    let FC = new Date(fC.value);
    let ret = FS < FC;
    if (FS && FC && !ret) {

      this.showMessage("La fecha de Resolución no puede ser menor a la fecha de Subscripción");
    } else {

      this.closeMsg();
    }
    return ret;
  }

  closeModal(): void {
    this.nuevaOrg = {
      orgName: '',
      orgRun: '',
      orgAddress: '',
      orgComuna: '',
      orgRegion: '',
      repFirstName: '',
      repLastName: '',
      repRun: '',
      repAddress: '',
      repComuna: '',
      repRegion: '',
      admFirstName: '',
      admLastName: '',
      admRun: '',
      correo_representante: '',
      confirEmail_rep: '',
      telefono_representante: '',
      correo_administrador: '',
      confirEmail_adm: '',
      telefono_administrador: '',
      fecha_subscripcion_convenio: '',
      fecha_resolucion_convenio: '',
      numero_subscripcion_convenio: '',
    };
    this.modalService.dismissAll();
    this.uiMsg = '';
    this.showMsg = false;
  }

  crearOrganizacionSubmit(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    const newOrg: OrganizacionModel = {
      id_organizacion: -1,
      run: this.nuevaOrg.orgRun,
      nombre: this.nuevaOrg.orgName,
      direccion: this.nuevaOrg.orgAddress,
      comuna: this.nuevaOrg.orgComuna,
      region: this.nuevaOrg.orgRegion,
      run_representante: this.nuevaOrg.repRun,
      nombre_representante: this.nuevaOrg.repFirstName,
      apellido_representante: this.nuevaOrg.repLastName,
      direccion_representante: '',
      comuna_representante: '',
      correo_representante: this.nuevaOrg.correo_representante,
      telefono_representante: this.nuevaOrg.telefono_representante,
      region_representante: '',
      run_administrador: this.nuevaOrg.admRun,
      nombre_administrador: this.nuevaOrg.admFirstName,
      apellido_administrador: this.nuevaOrg.admLastName,
      correo_administrador: this.nuevaOrg.correo_administrador,
      telefono_administrador: this.nuevaOrg.telefono_administrador,
      fecha_subscripcion_convenio: this.nuevaOrg.fecha_subscripcion_convenio,
      fecha_resolucion_convenio: this.nuevaOrg.fecha_resolucion_convenio,
      numero_subscripcion_convenio: this.nuevaOrg.numero_subscripcion_convenio
    }

    if (this.validaNuevaOrg(newOrg)) {
      this.orgService.createOrganizacion(newOrg).subscribe((resp: any) => {
        this.loadOrganizaciones();
        this.modalService.dismissAll();
      });
    }
  }

  confirEmail_rep = (evt: any) => {
    let repEmail: HTMLInputElement = evt.target
    if (repEmail.value === '') {
      this.uiMsg = "Debe completar el campo"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    }
    else
      if (repEmail.value.toLowerCase().trim() != this.nuevaOrg.correo_representante.toLowerCase().trim()) {
        this.uiMsg = "Los correos deben coincidir"
        this.showMsg = true;
        document.addEventListener('click', this.cancelClickEvent);
      } else {
        this.uiMsg = "";
        this.showMsg = false;
        document.addEventListener('click', this.cancelClickEvent);
      }
  }

  cancelClickEvent = (event: Event) => {
    if (this.showMsg) {
      event.preventDefault();
    }
  }

  confirEmail_adm = (evt: any) => {
    let admEmail: HTMLInputElement = evt.target
    if (admEmail.value === '') {
      this.uiMsg = "Debe completar el campo"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    }
    else
      if (admEmail.value.toLowerCase().trim() != this.nuevaOrg.correo_administrador.toLowerCase().trim()) {
        this.uiMsg = "Los correos deben coincidir"
        this.showMsg = true;
        document.addEventListener('click', this.cancelClickEvent);
      } else {
        this.uiMsg = "";
        this.showMsg = false;
        document.addEventListener('click', this.cancelClickEvent);
      }
  }

}
