import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import { NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { SetPasswordComponent } from './setpassword/setpassword.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { RecaptchaModule } from "ng-recaptcha";
import { AuthInterceptorService } from './auth-interceptor.service'; // this will allow the app to automatically attach authorization information to requests

//userData
import { Key, reducer } from './store/userdata/userdata.reducer';

import { OrganizacionesComponent } from './organizaciones/organizaciones.component';
import { OrganizacionComponent } from './organizaciones/organizacion.component';
import { SolicitudesComponent } from './solicitudes/solicitudes.component';
import { NuevasolicitudComponent } from './nuevasolicitud/nuevasolicitud.component';

import { UsersComponent } from './users/users.component';
import { SolicitudesService } from './shared/solicitudes.service';
import { OrganizacionesService } from './shared/organizaciones.service';
import { UsersService } from './shared/users.service';

import { solicitudesReducer } from './store/solicitudes.reducer';
import { FooterComponent } from './components/footer/footer.component';


import { SettingsService } from './shared/settings.service';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { UrgenciasComponent } from './urgencias/urgencias.component';
import { InicioComponent } from './inicio/inicio.component';
import { NgbDateCustomParserFormatter } from './shared/DateFormatter';
import { ResetpassComponent } from './resetpass/resetpass.component';
import { metaReducers, reducers } from './reducers';
import { CheckCodeComponent } from './check-code/check-code.component';
//QRCode
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { VoucherComponent } from './components/voucher/voucher.component';

@NgModule({
  declarations: [
    AppComponent,
    NuevasolicitudComponent,
    SolicitudesComponent,
    DashboardComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    RegisterComponent,
    SetPasswordComponent,
    SideBarComponent,
    ToolBarComponent,
    FooterComponent,
    UsersComponent,
    OrganizacionComponent,
    OrganizacionesComponent,
    SolicitudComponent,
    UrgenciasComponent,
    InicioComponent,
    ResetpassComponent,
    CheckCodeComponent,
    VoucherComponent
  ],
  imports: [
    BrowserModule,
    NgxQRCodeModule,
    RouterModule.forRoot([
      { path: 'login', component: LoginComponent },
      { path: 'resetpass', component: ResetpassComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'setpassword', component: SetPasswordComponent },
      { path: 'checkCode', component: CheckCodeComponent },
      { path: 'home', component: HomeComponent },
      { path: 'solicitar', component: NuevasolicitudComponent },
      { path: 'solicitudes', component: SolicitudesComponent },
      { path: 'historico', component: SolicitudesComponent },
      { path: 'solicitudes/:id_solicitud', component: SolicitudComponent },
      { path: 'solicitar/:id_solicitud', component: SolicitudComponent },
      { path: 'users', component: UsersComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'organizaciones', component: OrganizacionesComponent },
      { path: 'urgencias', component: UrgenciasComponent },
      { path: 'organizacion/:id_organizacion', component: OrganizacionComponent },
      { path: 'inicio', component: InicioComponent, pathMatch: 'full' },
      { path: '', redirectTo: '/inicio', pathMatch: 'full' },
      { path: '**', component: PageNotFoundComponent }
    ]),
    StoreModule.forRoot({ solicitudes: solicitudesReducer }),
    StoreModule.forFeature(Key, reducer),
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),

    ReactiveFormsModule,
    DataTablesModule,
    NgbModule,
    HighchartsChartModule,
    HttpClientModule,
    FormsModule,
    RecaptchaModule,
  ],
  providers: [
    SolicitudesService,
    UsersService,
    SettingsService,
    OrganizacionesService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
