<div class="loginContainer">
  <div class="loginPanel">
    <img src="assets/logo-registro-civil-300-jpg.jpg?timestamp=20180610163906"
    alt="Registro Civil" />
    <h3>Login Artículo 44</h3>
    <form>
      <label for="username">Usuario</label>
      <input type="text" name="username" placeholder="Nombre de Usuario" [(ngModel)]="username" (blur)="valRut($event, 'username')">
      <label for="password">Password</label>
      <input type="password" name="password" placeholder="Contraseña" [(ngModel)]="password"  style="text-security:disc; -webkit-text-security:disc;">
      <button (click)="actLogin($event)" class="btn" [ngClass]="{disabled: !loginOK }">Login</button>
      <div class="actions">
      <!-- <a routerLink="/register" routerLinkActive="active" >Registrase</a> --> 
        <a routerLink="/resetpass" routerLinkActive="active" >Recuperar Contraseña</a>
      </div>
    </form>
    <div class="warning" *ngIf="loginMsg!=''">
      {{loginMsg}}
    </div>
  </div>
</div>
