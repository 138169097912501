import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { throwError } from 'rxjs';
@Injectable()
export class UsersService {
  constructor(private http: HttpClient) { }

  loadUsers() {
    return this.http
      .get(`${environment.boBackendAPI}/users`)
      .pipe(map((users) => users || []));
  }
  getUserInfo(username: any) {
    return this.http
      .get(`${environment.boBackendAPI}/users/${username}`)
      .pipe(map((user) => user || {}));

  }
  findUser(username: any) {
    return this.http
      .get(`${environment.boBackendAPI}/users/find/${username}`)
      .pipe(map((user) => user || {}));

  }

  findEmail(email: any) {
    return this.http
      .get(`${environment.boBackendAPI}/users/findemail/${email}`)
      .pipe(map((user) => user || {}));

  }

  checkCode(codigo: any) {
    const url = `${environment.boBackendAPI}/users/verificacodigo`;
    const body = { codigo: codigo };
    const getToken = JSON.parse(sessionStorage.getItem('boData') || "{}") || null;
    let token = getToken.token;
    const headers = new HttpHeaders().set('x-app-key', token)
    return this.http.post(url, body, { headers }
    ).pipe(
      map((user) => user || {}),
      catchError((error: any) => {
        return throwError(error.error); // any further errors are returned to frontend                    
      })
    );
  }

  createUser(userData: any) {
    let options = new HttpHeaders();

    options.set('Content-Type', 'application/json;charset=utf-8');
    options.set("Accept", "application/json, text/plain, */*");

    return this.http
      .post(`${environment.boBackendAPI}/auth/register`,
        userData,
        { headers: options })
      .pipe(
        map((user) => user || {}),
        catchError((error: any) => {
          return throwError(error.error); // any further errors are returned to frontend                    
        })
      )
  }
  updateUser(userData: any) {
    let options = new HttpHeaders();
    options.set('Content-Type', 'application/json;charset=utf-8');
    options.set("Accept", "application/json, text/plain, */*");

    let updateURL = `${environment.boBackendAPI}/users/${userData.username}`;
    delete userData.username;

    return this.http
      .put(updateURL,
        userData,
        { headers: options })
      .pipe(map((user) => user || {}))
  }

  borrarUser(userData: any) {
    let options = new HttpHeaders();
    options.set('Content-Type', 'application/json;charset=utf-8');
    options.set("Accept", "application/json, text/plain, */*");

    let updateURL = `${environment.boBackendAPI}/users/${userData.username}`;
    delete userData.username;

    return this.http.delete(updateURL, userData)
      .pipe(map((user) => user || {}))
  }


}
