<div class="overlay" *ngIf="showAsignaRun || showVoucher || showAgenda || updateTicket"></div>
<div *ngIf="solicitud" class="solicitud">
  <button *ngIf="solicitud.estado < 5" class="btn btn-sm no-print" style='float: right;' (click)="printVoucher()"><i class="bi bi-envelope"></i>
    Comprobante</button>
  <button class="btn mb-2 no-print" (click)="goBack()"><i class="bi bi-arrow-left-square"></i> Volver</button>

  <div class='mb-2'>
    <h3>
      {{solicitud.titulo.toUpperCase()}}
    </h3>
    <span *ngIf="solicitud.run_ciudadano">RUN Asignado: {{solicitud.run_ciudadano}}</span>

  </div>

  <div class="orgActions">
    <button *ngIf='showFinalizar' class="my-1 btn btn-small btn-primary no-print"
      (click)="finalizarSolicitud($event)">Finalizar Solicitud</button>
  </div>

  <div class="solHeader" [ngClass]="{urgent: solicitud.tipo == 'urgente'}">
    <div class="row text-center">
      <div class="col-3">
        <label>N° Solicitud</label>
        <strong class='solID'>{{id_solicitud}}</strong>
      </div>
      <div class="col-3">
        <label>Fecha</label>
        <strong>{{solicitud.fecha_creacion.split("T")[0].split("-").reverse().join("/")}}</strong>
      </div>
      <div class="col-4">
        <label>Tipo de Solicitud</label>
        <strong>{{solicitud.tipo.toUpperCase()}}</strong>
      </div>
      <div class="col-2">
        <label>Estado</label>
        <strong>{{solicitud.statusText}}</strong>
      </div>
    </div>

    <div class="row" *ngIf="solicitud.fecha_agenda && solicitud.estado == 4">
      <div class="col-md-12 text-center">
        <strong>Atención en terreno agendada para el </strong>
        {{(solicitud.fecha_agenda.includes("T")) ? solicitud.fecha_agenda.split("T")[0].split("-").reverse().join("/") :
        solicitud.fecha_agenda.split(" ")[0].split("-").reverse().join("/")}} a las
        {{(solicitud.fecha_agenda.includes("T")) ? solicitud.fecha_agenda.split("T")[1].substring(0, 5) :
        solicitud.fecha_agenda.split(" ")[1].substring(0, 5)}}

      </div>
    </div>

    <div class="row" *ngIf="solicitud.estado == 10 || solicitud.estado == 14 ">
      <div class="col-md-12 text-center my-2">
        <strong>Motivo de Anulación: </strong>
        {{solicitud.motivo_anulacion}}
      </div>
    </div>

    <div class="row hide">
      <div class="col-md-12 text-center">
        <strong>Agendado para el </strong>
        {{solicitud.fecha_voucher}} a las {{solicitud.hora_voucher}} en oficina de {{solicitud.direccion_voucher}},
        {{tramite.comunaLabel}}
      </div>
    </div>
  </div>

  <div class="mt-3">
    <!--Read Only -->
    <div class="tramite" *ngIf="!editMode">
      <button *ngIf="editBase " class="btn btn-warning btn-sm mb-3 no-print" (click)="editSolicitud()">Editar
        Datos</button>
      <h5>Datos Básicos</h5>
      <div class="row m-2 align-items-center">
        <div class="col-4">
          <label>Nombres:</label>
          <input type="text" disabled class="form-control" [value]="tramite.nombre.toUpperCase()" />
        </div>

        <div class="col-4">
          <label>Apellido Paterno</label>
          <input type="text" disabled class="form-control" [value]="tramite.paterno.toUpperCase()" />
        </div>

        <div class="col-4">
          <label>Apellido Materno</label>
          <input type="text" disabled class="form-control" [value]="tramite.materno.toUpperCase()" />
        </div>
        <div class="col-5">
        </div>
      </div>
      <div class="row m-2 align-items-center">
        <div class="col-4">
          <label>Fecha Nacimiento</label>
          <input type="text" disabled class="form-control" [value]="tramite.fecha_nac" />
        </div>

        <div class="col-4">
          <label>Sexo</label>
          <input type="text" disabled class="form-control" [value]="tramite.sexLabel.toUpperCase()" />
        </div>

        <div class="col-4">
          <label>País de Origen</label>
          <input type="text" disabled class="form-control" [value]="tramite.nacionalidad.toUpperCase()" />
        </div>
      </div>
      <div class="row m-2 align-items-center" *ngIf="solicitud.tipo=='urgente'" style='align-items:baseline !important'>
        <div class="col-6">
          <label for="justUrgenciaCtrl">Justificación Urgencia</label>
          <textarea [innerHTML]='motUrgencia.toUpperCase()' disabled class="form-control justUrgenciaCtrl"
            name="justUrgenciaCtrl" rows="3">
              </textarea>
        </div>

        <div class="col-6">
          <label for="regionAtencionCtrl">Dirección de Atención</label>
          <textarea [innerHTML]="dirAtencion.toUpperCase()" disabled class='form-control' name="dirAtencion" rows="3">
            </textarea>
        </div>
      </div>

      <h5 class="mt-3">Información Adicional</h5>

      <!-- MENOR DE EDAD -->

      <div class="row p-1" *ngIf="isMenor">
        <div class="col-md-12 m-2">
          <div class="alert alert-warning" role="alert">
            <strong>Acompañante de Menor de Edad</strong>
            <div class="row m-2">
              <div class="col-md-3">
                <label for="proge1"><span class="required">*</span> RUN/DNI/Pasaporte</label>
                <input [attr.disabled]="!editMode ? 'disabled': null" type="text" [formControl]="acompanianteID"
                  autocomplete="off" name="acompanianteID" class="form-control" placeholder="Identificación Acompáñante"
                  aria-label="" [value]="tramite.acompaniante_id">

              </div>
              <div class="col-md-5">
                <label for="proge1"><span class="required">*</span> Acompañante</label>
                <input [attr.disabled]="!editMode ? 'disabled': null" type="text" [formControl]="acompanianteMenor"
                  autocomplete="off" name="acompanianteMenor" class="form-control" placeholder="Nombre Acompañante"
                  aria-label="" [value]="tramite.acompaniante">
              </div>
              <div class="col-md-4">
                <label for="proge1"><span class="required">*</span> Relación con el Menor</label>
                <input [attr.disabled]="!editMode ? 'disabled': null" type="text" [formControl]="relacionMenor"
                  autocomplete="off" name="relacionMenor" class="form-control" placeholder="Relación con el Menor"
                  aria-label="" [value]="tramite.relacion_menor_de_edad">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row m-2 align-items-center">
        <div class="col-6">
          <label>Pasaporte / DNI</label>
          <input type="text" disabled class="form-control" [value]="tramite.pasaporte" />
        </div>
        <div class="col-6">
          <label>Progenitor 1</label>
          <input type="text" disabled class="form-control"
            [value]="tramite.nombre_progenitor_1.toUpperCase() + ' ' + tramite.primer_apellido_progenitor_1.toUpperCase()+ ' ' + tramite.segundo_apellido_progenitor_1.toUpperCase()" />

        </div>
        <div class="col-6">
          <label>Teléfono</label>
          <input type="text" disabled class="form-control" [value]="tramite.telefono" />
        </div>
        <div class="col-6">
          <label>Progenitor 2</label>
          <input type="text" disabled class="form-control"
            [value]="tramite.nombre_progenitor_2.toUpperCase() + ' ' + tramite.primer_apellido_progenitor_2.toUpperCase()+ ' ' + tramite.segundo_apellido_progenitor_2.toUpperCase()" />
        </div>
      </div>
      <div class="row m-2 align-items-center">
        <div class="col-6">
          <label>Dirección</label>
          <input type="text" disabled class="form-control"
            [value]="tramite.direccion.toUpperCase() + ' '  + tramite.direccion_numero + ' ' + tramite.direccion_letra.toUpperCase()" />
        </div>

        <div class="col-6">
          <label>Región</label>

          <input type="text" disabled class="form-control" [value]="tramite.regionLabel.toUpperCase() || ''" />
        </div>

        <div class="col-6">
          <label>Email</label>
          <input type="text" disabled class="form-control" [value]="tramite.email.toUpperCase()" />
        </div>
        <div class="col-6">
          <label>Comuna</label>

          <input type="text" disabled class="form-control" [value]="tramite.comunaLabel.toUpperCase() || ''" />
        </div>
      </div>
      <div class="row m-2">
        <div class="col-md-3">
          <label for="">Identificador Interno</label>
          <input type="text" class="form-control" autocomplete='off' disabled name="id_interno"
            [value]="tramite.id_interno" />
        </div>
        <div class="col-md-9">
          <label for="exampleFormControlTextarea1">Otros Antecedentes</label>
          <textarea disabled class="form-control" name="antecedentesCtrl"
            rows="3">{{tramite.antecedentes.toUpperCase()}}</textarea>
        </div>
      </div>
    </div>

    <!-- Edit Mode -->
    <div class="tramite" *ngIf="editMode">
      <form id="ticketForm" class="form-horizontal no-print" (submit)="submitSolicitud()">
        <div>
          <h5>Datos Básicos</h5>
          <div class="row my-2">
            <div class="col-md-6 ticketType">
              <div>
                <label for="tiposol">Tipo de solicitud:</label>
                {{solicitud.tipo.toUpperCase()}}
              </div>
            </div>
            <div class="col-md-5 offset-md-1 text-right">
              <p class="mb-4">
              </p>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <label for="name"><span class="required">*</span> Nombres</label>
              <input type="text" [attr.disabled]='solicitud.tipo == "urgente" || !editBase || null'
                [formControl]="nameCtrl" autocomplete="off" name="nameCtrl" class="form-control" placeholder="Nombres"
                aria-label="Nombres" (blur)="cleanSpaces(nameCtrl)">
              <small *ngIf="nameCtrl.hasError('required') && nameCtrl.dirty" class="error">Campo requerido</small>
            </div>

            <div class="col-md-4">
              <label for="primerap"><span class="required">*</span> Primer Apellido</label>
              <input type="text" [attr.disabled]='solicitud.tipo == "urgente" || !editBase || null'
                [formControl]="primerapCtrl" autocomplete="off" name="primerapCtrl" class="form-control"
                placeholder="Primer Apellido" aria-label="" required (blur)="cleanSpaces(primerapCtrl)">
              <small *ngIf="primerapCtrl.hasError('required') && primerapCtrl.dirty" class="error">Campo
                requerido</small>
            </div>
            <div class="col-md-4">
              <label for="segundoap">Segundo Apellido</label>
              <input type="text" [attr.disabled]='solicitud.tipo == "urgente" || !editBase || null'
                [formControl]="segundoapCtrl" autocomplete="off" name="segundoapCtrl" class="form-control"
                placeholder="Segundo Apellido" aria-label="" (blur)="cleanSpaces(segundoapCtrl)">

              <small *ngIf="segundoapCtrl.hasError('required') && segundoapCtrl.dirty" class="error">Campo
                requerido</small>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-4">
              <label for="nacimiento"><span class="required">*</span> Fecha Nacimiento</label>

              <div class="input-group">

                <input type="text" [attr.disabled]='(solicitud.tipo != "urgente" && editBase) || null'
                  class="form-control fechaNac bg-white" [(ngModel)]='fechaNacimiento'
                  [attr.style]='solicitud.tipo == "urgente" ? "display:none": "display:block"' name="nacimientoCtrl"
                  (click)="(solicitud.tipo != 'urgente' && editBase) ? (d.toggle()) : (null)" ngbDatepicker
                  #d="ngbDatepicker" [minDate]="{year: 1900, month: 1, day: 1}" placeholder='dd-mm-aaa'
                  [maxDate]="maxBDate" autocomplete='off'>
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"
                  *ngIf='solicitud.tipo != "urgente" && editBase'>

                  <i class="bi bi-calendar-date"></i>
                </button>

                <span *ngIf="solicitud.tipo == 'urgente'" class='form-control disabled'>
                  {{fechaNacimiento}}
                </span>

              </div>

              <small *ngIf="nacimientoCtrl.hasError('required') && nacimientoCtrl.dirty" class="error">Campo
                requerido</small>
            </div>
            <div class="col-md-4">
              <label for="sexo"><span class="required">*</span> Sexo</label>
              <select name="sexoCtrl" [formControl]="sexoCtrl" class="form-control" *ngIf="solicitud.tipo != 'urgente'">
                <option value="N/I">-</option>
                <option value="F">Femenino</option>
                <option value="M">Masculino</option>
                <option value="I">Indeterminado</option>
              </select>
              <small *ngIf="sexoCtrl.hasError('required') && sexoCtrl.dirty" class="error">Campo requerido</small>
              <span *ngIf="solicitud.tipo == 'urgente'" class='form-control disabled'>
                {{sexoCtrl.value == 'F' ? 'Femenino' : sexoCtrl.value == 'M' ? 'Masculino' : 'Indeterminado'}}
              </span>

            </div>

            <div class="col-md-4">
              <label for="nacional"><span class="required">*</span> País de Origen</label>
              <input autocomplete='off' (keyup)='countryLookup($event)' type="text" [formControl]="nacionalCtrl"
                name="nacionalCtrl" class="form-control" placeholder="Buscar país" aria-label=""
                *ngIf="solicitud.tipo != 'urgente'">

              <div class="pRes" *ngIf="cLookup.length > 0 && cFound; else cNotFound">
                <a href="javascript:;" *ngFor="let cObj of cLookup"
                  (click)='selectCountry($event, cObj.id)'>{{cObj.name}}</a>
              </div>
              <ng-template #cNotFound>
                <div class="pRes notFound error" *ngIf="cFound == false && cVal != ''">
                  No se encontraron coincidencias
                </div>

              </ng-template>
              <small *ngIf="nacionalCtrl.hasError('required') && nacionalCtrl.dirty" class="error">Campo
                requerido</small>
              <span *ngIf="solicitud.tipo == 'urgente'" class='form-control disabled'>
                {{nacionalCtrl.value}}
              </span>

            </div>
          </div>

          <div class="row my-3" *ngIf="nuevaSolicitud.tipo=='urgente'">
            <div class="col-md-6">
              <label for="motivoUrgenciaCtrl"><span class="required">*</span> Motivo Urgencia</label>
              <select [(ngModel)]="motivoUrgenciaCtrl" class="form-control" name="motivoUrgenciaCtrl">
                <option value="-1" selected>Selecione el motivo</option>
                <option *ngFor="let option of settings.motivosUrgencia" [value]="option.codigo">{{option.glosa}}
                </option>
              </select>

              <label class="mt-1" for="justUrgenciaCtrl"><span class="required">*</span> Justificación Urgencia</label>
              <textarea [formControl]="justUrgenciaCtrl" class="form-control justUrgenciaCtrl" name="justUrgenciaCtrl"
                rows="7" (blur)="cleanSpaces(justUrgenciaCtrl)"></textarea>
            </div>
            <div class="col-md-6">
              <label for="direccionAtencionCtrl"><span class="required">*</span> Dirección de Atención</label>
              <input type="text" [(ngModel)]='direccionAtencion' name="direccionAtencionCtrl" class="form-control"
                placeholder="Dirección de Atención" aria-label="">
              <small *ngIf="direccionAtencionCtrl.hasError('required') && direccionAtencionCtrl.dirty">Campo
                requerido</small>

              <label class='mt-1' for="regionAtencionCtrl"><span class="required">*</span> Región</label>
              <select [(ngModel)]="regionAtencion" class="form-control" name="regionAtencionCtrl"
                (change)="changeRegionAtencion()">
                <option value="-1">- Seleccione Región -</option>
                <option *ngFor="let region of regiones" [value]="region.codigo_region">
                  {{region.nombre_region}}
                </option>
              </select>

              <label class='mt-1' for="comuna"><span class="required">*</span> Comuna: </label>
              <select [(ngModel)]="comunaAtencion" class="form-control" name="comunaAtencionCtrl"
                (change)="changeComuna()">
                <option *ngFor="let comuna of comunasAt" [value]="comuna.codigo_comuna">
                  {{comuna.nombre_comuna}}
                </option>
              </select>
              <small *ngIf="comunaAtencionCtrl.hasError('required') && comunaCtrl.dirty" class="error">Campo
                requerido</small>

            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <hr>
            </div>
          </div>

          <h5>Información Adicional</h5>

          <div class="row p-1" *ngIf="isMenor">
            <div class="col-md-12">
              <div class="alert alert-warning" role="alert">
                <strong>Acompañante de Menor de Edad</strong><br />
                La fecha de nacimiento indicada corresponde a un menor de edad. Se requiere ingresar los datos de al
                menos uno los progenitores o del acompañante del menor de edad.
                <div class="row m2">
                  <div class="col-md-3">
                    <label for="proge1"><span class="required">*</span> RUN/DNI/Pasaporte</label>
                    <input type="text" [formControl]="acompanianteID" autocomplete="off" name="acompanianteID"
                      class="form-control" placeholder="Identificación Acompáñante" aria-label=""
                      (blur)="cleanSpaces(acompanianteID)">
                  </div>
                  <div class="col-md-5">
                    <label for="proge1">Acompañante</label>
                    <input type="text" [formControl]="acompanianteMenor" autocomplete="off" name="acompanianteMenor"
                      class="form-control" placeholder="Nombre Acompañante" aria-label=""
                      (blur)="cleanSpaces(acompanianteMenor)">
                  </div>
                  <div class="col-md-4">
                    <label for="proge1">Relación con el Menor</label>
                    <input type="text" [formControl]="relacionMenor" autocomplete="off" name="relacionMenor"
                      class="form-control" placeholder="Relación con el Menor" aria-label=""
                      (blur)="cleanSpaces(relacionMenor)">
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label for="pasaport">Pasaporte/DNI</label>
              <input type="text" [formControl]="pasaportCtrl" autocomplete="off" name="pasaportCtrl"
                class="form-control" placeholder="Pasaporte/dni" aria-label="" (blur)="cleanSpaces(pasaportCtrl)">
              <small *ngIf="pasaportCtrl.hasError('required') && pasaportCtrl.dirty" class="error">Campo
                requerido</small>

            </div>
            <div class="col-md-9">

              <label for="proge1">Progenitor 1</label>
              <div class="form-row ">
                <input type="text" [formControl]="proge1Ctrl" autocomplete="off" name="proge1Ctrl"
                  class="col form-control" placeholder="Nombre" (blur)="cleanSpaces(proge1Ctrl)">
                <input type="text" [formControl]="apellido1proge1Ctrl" autocomplete="off" name="apellido1proge1Ctrl"
                  class="mx-1 col form-control" placeholder="Primer Apellido" (blur)="cleanSpaces(apellido1proge1Ctrl)">
                <input type="text" [formControl]="apellido2proge1Ctrl" autocomplete="off" name="apellido2proge1Ctrl"
                  class="col form-control" placeholder="Segundo Apellido" (blur)="cleanSpaces(apellido2proge1Ctrl)">
              </div>

            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label for="telf">Teléfono</label>
              <input type="tel" [formControl]="telfCtrl" autocomplete="off" name="telfCtrl" class="form-control"
                placeholder="Teléfono" aria-label="" (blur)="cleanSpaces(telfCtrl)">
              <small *ngIf="telfCtrl.hasError('required') && telfCtrl.dirty" class="error">Campo requerido</small>

            </div>
            <div class="col-md-9">
              <label for="proge2">Progenitor 2</label>
              <div class="form-row ">
                <input type="text" [formControl]="proge2Ctrl" autocomplete="off" name="proge2Ctrl"
                  class="col form-control" placeholder="Nombre" (blur)="cleanSpaces(proge2Ctrl)">
                <input type="text" [formControl]="apellido1proge2Ctrl" autocomplete="off" name="apellido1proge2Ctrl"
                  class="mx-1 col form-control" placeholder="Primer Apellido" (blur)="cleanSpaces(apellido1proge2Ctrl)">
                <input type="text" [formControl]="apellido2proge2Ctrl" autocomplete="off" name="apellido2proge2Ctrl"
                  class="col form-control" placeholder="Segundo Apellido" (blur)="cleanSpaces(apellido2proge2Ctrl)">

              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-7">
              <label for="domicilio">Domicilio</label>
              <div class="form-row">
                <input type="text" [formControl]="domicilioCtrl" autocomplete='off' name="domicilioCtrl"
                  class="form-control col-7" placeholder="Calle" style='width:15rem'
                  (blur)="cleanSpaces(domicilioCtrl)">
                <input type="text" [formControl]="domicilioNumeroCtrl" autocomplete='off' name="domicilioNumeroCtrl"
                  class="form-control col mx-1" placeholder="Número" style='width:6rem'
                  (blur)="cleanSpaces(domicilioNumeroCtrl)">
                <input type="text" [formControl]="domicilioLetraCtrl" autocomplete='off' name="domicilioLetraCtrl"
                  class="form-control col" placeholder="Letra" style='width:4rem'
                  (blur)="cleanSpaces(domicilioLetraCtrl)">
              </div>
            </div>

            <div class="col-md-5">
              <label for="region"><span class="required" *ngIf="nuevaSolicitud.tipo!='urgente'">*</span> Región</label>
              <select [formControl]="regionCtrl" class="form-control" name="regionCtrl" (change)="changeRegion()">
                <option value="-1">- Seleccione Región -</option>
                <option *ngFor="let region of regiones" [value]="region.codigo_region">
                  {{region.nombre_region}}
                </option>
              </select>
              <small *ngIf="regionCtrl.hasError('required') && regionCtrl.dirty" class="error">Campo requerido</small>

            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-7">
              <label for="email"> Correo electrónico</label> (<small>Para envío de comprobante de solicitud.</small>)

              <input type="email" [formControl]="emailCtrl" autocomplete='off' name="emailCtrl" class="form-control"
                placeholder="Dejar en blanco si no tiene email" aria-label="" required (blur)="cleanSpaces(emailCtrl)">
              <small *ngIf="emailCtrl.hasError('required') && emailCtrl.dirty" class="error">Campo requerido</small>
            </div>
            <div class="col-md-5">
              <label for="comuna"><span class="required" *ngIf="nuevaSolicitud.tipo!='urgente'">*</span> Comuna:
              </label>
              <select [formControl]="comunaCtrl" class="form-control" name="comunaCtrl" (change)="changeComuna()">
                <option *ngFor="let comuna of comunas" [value]="comuna.codigo_comuna">
                  {{comuna.nombre_comuna}}
                </option>
              </select>
              <small *ngIf="comunaCtrl.hasError('required') && comunaCtrl.dirty" class="error">Campo requerido</small>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-3">
              <label for="">Identificador Interno</label>
              <input type="text" class="form-control" autocomplete='off' [formControl]='idInternoCtrl' name="id_interno"
                (blur)="cleanSpaces(idInternoCtrl)" />
            </div>

            <div class="col-md-9">
              <label for="exampleFormControlTextarea1">Otros Antecedentes</label>
              <textarea class="form-control" name="antecedentesCtrl" rows="3">{{tramite.antecedentes}}</textarea>
            </div>
          </div>
        </div>

        <hr />

        <div class="col-md-4">
          <button *ngIf="showButtonN" type="button" id="showButtonN" class="btn btn-primary btn-sm mt-4 btn-lg btn-sm"
            data-toggle="modal" data-target="#ventanaNormal">
            <i class="bi bi-check-lg"></i> Guardar
          </button>
        </div>
        <div class="col-md-4">
          <button *ngIf="showButtonU" type="button" id="showButtonU" class="btn btn-primary btn-sm mt-4 btn-lg btn-sm"
            data-toggle="modal" data-target="#ventanaUrgencia">
            Guardar
          </button>
        </div>
        <div class="text-right">
          <button class='btn btn-sm' (click)="editMode = false;">Cancelar</button>
          <button class="btn btn-primary mt-2" type="submit" [disabled]='!formValid' [class]='{disabled: !formValid}'>
            <i class="bi bi-check-lg"></i> Actualizar Solicitud
          </button>

        </div>
      </form>
    </div>
  </div>


</div>

<div class="asignaRun" *ngIf="solicitud && showAsignaRun">
  <span class="closeAsignaRun" (click)='closeAsignaRun()'>X</span>
  <h4>Asignación de RUN</h4>
  <blockquote>
    Ingrese el RUN asignado a la persona extranjera una vez inscrito en el SRCeI
  </blockquote>
  <div class="row">
    <div class="col">
      <span>Extranjero Solicitante</span>
      <h5>{{solicitud.titulo}}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <span>RUN Asignado</span>
    </div>
    <div class='col-md-6'>
      <input (keyup)="valRut($event)" type="text" [(ngModel)]='runAsignado' class='form-control' />

    </div>
  </div>

  <div class="row">
    <div class="col-12 actions">
      <button [attr.disabled]='!runAsignadoValid ? true : null' class="btn btn-sm btn-success no-print"
        (click)='asignarRUN()'>Asignar RUN:</button>

    </div>
  </div>
</div>

<div *ngIf="loading" class="loading">
  <img src="assets/loading.gif" alt="">
</div>

<div *ngIf="showVoucher && solicitud">
  SHOW VOUCHER
  <app-voucher (voucherEvent)="handleVoucherEvent($event)" [voucher]="solicitud.id"></app-voucher>
</div>

<div class="finDialog" *ngIf='showFinDialog'>
  <span class="closeAsignaRun" (click)='closeFinDialog()'>X</span>
  <h4>Finalizar Solicitud</h4>
  <blockquote class='my-3'>
    Al finalizar la solicitud esta quedará disponible en la vista de solicituides historicas
  </blockquote>
  <div class="row">

  </div>

  <div class="row">
    <div class="col-12 actions">
      <button class='btn btn-sm' (click)='closeFinDialog()'>Cancelar</button>
      <button class="btn btn-sm btn-success" (click)='okFinSolicitud()'>Finalizar Solicitud</button>
    </div>
  </div>
</div>

<div *ngIf="updateTicket">
  COFIRM VOUCHER
  <app-voucher (voucherEvent)="handleVoucherEvent($event)" [voucher]="solicitud.id"></app-voucher>

</div>