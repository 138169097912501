import { createReducer, on } from '@ngrx/store';
import { SolicitudModel } from '../solicitudes/solicitud.model';
import { retrievedSolicitudesList } from './solicitudes.action';

export const initialState: ReadonlyArray<SolicitudModel> = [];

const _solicitudesReducer = createReducer(
  initialState,
  on(retrievedSolicitudesList, (state, { allSolicitudes }) => {
    return [...allSolicitudes];
  })
);


export function solicitudesReducer(state: any, action: any) {
  return _solicitudesReducer(state, action);
}
