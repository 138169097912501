import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { LoginService } from '../shared/login.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  constructor(
    private router: Router,
    private login: LoginService
  ) { }
    showOverlay:boolean = false;
  linkCU = 'https://accounts.claveunica.gob.cl/openid/authorize/?' +
  `client_id=${environment.claveUnica_client_id}` + 
  `&response_type=code` +
  `&scope=${encodeURIComponent(environment.claveUnica_scope)}` +
  `&redirect_uri=${encodeURIComponent(environment.claveUnica_redirect_uri)}` +
  `&state=${environment.claveUnica_state}`;

  pageHit: any;

  ngOnInit(): void {
   this.pageHit = this.router.url;
   if(this.pageHit.indexOf("code=")> 0 && this.pageHit.indexOf("state=") > 0){
     this.showOverlay = true;
     let qs = this.pageHit.replace("/inicio?", '').split("&");
     let qarr:any = {};
     qs.forEach( (q:any) => {
        let arr = q.split("=");
        qarr[arr[0]] = arr[1];
     });
     this.cuLogin(qarr)
   }
  }

  cuLogin(qarr: any) {
    this.login.LoginClaveUnica(qarr);
  }
}
