<h3>Organizaciones en Convenio</h3>

<blockquote>Registro de organizaciones en convenio para la solicitud de RUN acorde al artículo 44 </blockquote>
<div class="actions" *ngIf="canCreate">
  <a #usrBtn href="javascript:;" (click)="crearOrganizacion(modalCrearOrganizacion)" class="btn btn-primary btn-sm">
    <i class="bi bi-building"></i> Nueva Organización
  </a>
</div>

<table class="table table-striped table-bordered table-sm row-border hover" id="dataTable">
  <thead>
    <tr>
      <th>Rut</th>
      <th>Nombre</th>
      <th>Rep. Legal</th>
      <th>Administrador</th>
      <th>
        Acciones
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of organizaciones" (id)="item.id_organizacion">
      <td>{{item.run}}</td>
      <td>{{item.nombre}}</td>
      <td>{{item.nombre_representante}} {{item.apellido_representante}} </td>
      <td>{{item.nombre_administrador}} {{item.apellido_administrador}} </td>
      <td class="actions">
        <a [routerLink]="'/organizacion/' + item.id_organizacion">
          <i class="bi bi-file-earmark-ruled"></i>
        </a>
      </td>
    </tr>
  </tbody>
</table>
<h5 *ngIf="loading" class="text-center m-3 p-3"> <img src="assets/loading.gif" class="mb-3" /> <br /><small>Cargando
    organizaciones</small></h5>


<!--  MODAL PARA CREAR ORGANIZACION -->
<ng-template #modalCrearOrganizacion let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><i class="bi bi-building"></i> Nueva Organización en Convenio </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
  </div>
  <div class="modal-body">
    <div class="container">
      <form class="form" id="crearOrganizacionForm" (ngSubmit)="crearOrganizacionSubmit($event)">
        <div class="controls-container">
          <div class="row form-group">

            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                Fecha de Subscripción
              </label>
              <input class='form-control' name="fecha_subscripcion_convenio"
                [(ngModel)]="nuevaOrg.fecha_subscripcion_convenio" type="date" id="fecha_sub" placeholder='dd-mm-aaaa'
                (change)="checkDates()" />
            </div>
            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                N° de Resolución
              </label>
              <input class='form-control' name="numero_subscripcion_convenio"
                [(ngModel)]="nuevaOrg.numero_subscripcion_convenio" type="text" placeholder='N° de Resolución' />
            </div>
            <div class="col-4">
              <label for="datosConvenio">
                <span class="required">*</span>
                Fecha de Resolución
              </label>
              <input class='form-control' name="fecha_resolucion_convenio"
                [(ngModel)]="nuevaOrg.fecha_resolucion_convenio" type="date" id="fecha_conv" placeholder='dd-mm-aaaa'
                (change)="checkDates()" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col col-8">
              <label for="orgName"><span class="required">*</span>
                Nombre Organización</label>
              <input (blur)="validateField($event)" required [(ngModel)]="nuevaOrg.orgName" class="form-control"
                type="text" name="orgName" placeholder="Nombre de la Organización" />
            </div>
            <div class="form-group col col-4">
              <label for="orgRun"><span class="required">*</span> RUT Organización</label>
              <input [(ngModel)]="nuevaOrg.orgRun" (blur)="valRut($event, 'orgRun')" required class="form-control"
                type="text" name="orgRun" placeholder="Ej 88777666-5" />
            </div>
          </div>

          <div class="row">
            <div class="form-group col col-12">
              <label for="orgAddress"><span class="required">*</span> Dirección</label>
              <input (blur)="validateField($event)" [(ngModel)]="nuevaOrg.orgAddress" class="form-control" type="text"
                name="orgAddress" placeholder="Dirección" />
            </div>
            <div class="form-group col col-6">
              <label for="orgRegion"><span class="required">*</span> Región</label>
              <select [(ngModel)]="nuevaOrg.orgRegion" class="form-control" name="orgRegion" (change)="changeRegion()">
                <option value="-1">- Seleccione Región -</option>
                <option *ngFor="let region of regiones" [value]="region.codigo_region">
                  {{region.nombre_region}}
                </option>
              </select>
            </div>
            <div class="form-group col col-6">
              <label for="orgComuna"><span class="required">*</span> Comuna</label>
              <select [(ngModel)]="nuevaOrg.orgComuna" class="form-control" name="orgComuna">
                <option *ngFor="let comuna of comunas" [value]="comuna.codigo_comuna">
                  {{comuna.nombre_comuna}}
                </option>
              </select>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12">
              <label for="repName"><span class="required">*</span> Representante Legal / Autoridad</label>
            </div>
            <div class="col-4">
              <input (blur)="valRut($event, 'repRun')" [(ngModel)]="nuevaOrg.repRun" class="form-control" type="text"
                name="repRun" placeholder="Run Rep. Legal" />
            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.repFirstName" class="form-control" type="text" required name="repFirstName"
                placeholder="Nombre Rep. Legal" />
            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.repLastName" class="form-control" type="text" name="repLastName"
                placeholder="Apellido Rep. Legal" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.telefono_representante" class="form-control" type="text" name="repPhone"
                placeholder="Telefono Rep. Legal" />
            </div>

            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.correo_representante" class="form-control" type="text" id="repMail"
                name="repMail" placeholder="Correo Rep. Legal" onpaste="return false;" oncopy="return false"
                oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />
            </div>

            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.confirEmail_rep" class="form-control" type="text" id="confrEmail_rep"
                name="confrEmail_rep" placeholder="Confirmar correo" (blur)="confirEmail_rep($event)"
                onpaste="return false;" oncopy="return false" oncut="return false" autocomplete="off"
                ondrop="return false" ondrag="return false" />
            </div>
          </div>

          <div class="row form-group">
            <div class="col-12">
              <label for="admName"><span class="required">*</span> Coordinador</label>
            </div>
            <div class="col-4">
              <input (blur)="valRut($event, 'admRun')" [(ngModel)]="nuevaOrg.admRun" class="form-control" type="text"
                name="admRun" placeholder="Run Coordinador" />
            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.admFirstName" class="form-control" type="text" name="admFirstName"
                placeholder="Nombre Coordinador" />
            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.admLastName" class="form-control" type="text" name="admLastName"
                placeholder="Apellido Coordinador" />
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.telefono_administrador" class="form-control" type="text" name="admPhone"
                placeholder="Telefono Coordinador" />

            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.correo_administrador" class="form-control" type="text" name="admMail"
                placeholder="Correo Coordinador" onpaste="return false;" oncopy="return false" oncut="return false"
                autocomplete="off" ondrop="return false" ondrag="return false" />

            </div>
            <div class="col-4">
              <input [(ngModel)]="nuevaOrg.confirEmail_adm" class="form-control" type="text" name="confirEmail_adm"
                placeholder="Confirmar correo" (blur)="confirEmail_adm($event)" onpaste="return false;"
                oncopy="return false" oncut="return false" autocomplete="off" ondrop="return false"
                ondrag="return false" />

            </div>
          </div>
        </div>
        <div class="actions">
          <a href="javascript:;" (click)="closeModal()">Cancelar</a>&nbsp;&nbsp;
          <button type="submit" class="btn btn-primary">
            <i class="bi bi-plus-lg"></i>
            Crear Organización</button>
        </div>

      </form>

    </div>
  </div>
</ng-template>