<div class="container">
  <div class="row">
    <div class="col-3">
      <div class="panel">
        <h1>{{stats.total}}</h1>
        <blockquote>Total de Solicitudes</blockquote>
      </div>
    </div>
    <div class="col-3">
      <div class="panel">
        <h1>{{stats.cerradas}}</h1>
        <blockquote>Total Resueltas</blockquote>
      </div>
    </div>
    <div class="col-3">
      <div class="panel">
        <h1>{{stats.urgentes}}</h1>
        <blockquote>Total Urgentes</blockquote>
      </div>
    </div>
    <div class="col-3">
      <div class="panel">
        <h1>{{stats.solpromedio}}<small>dias</small></h1>
        <blockquote>T° Prom. Atención</blockquote>
      </div>
    </div>
  </div>
</div>