import { Action, createReducer, on } from '@ngrx/store';
import userdata from './userdata.interface';
import * as userActions from './userdata.actions';

export const userDataFeatureKey = 'userData';
export const Key = 'UserData';


export interface IUserDataState {
    userdata: userdata
}

let usrData = JSON.parse(sessionStorage.usrData || `{
    "token": "",
    "username": "",
    "rol": {},
    "organizacion": {},
    "iat": 0,
    "exp": 0,
    "fullName": "",
    "region":  "",
    "menu": {}
}`);

export const initialState: IUserDataState = {
    userdata: usrData
};

export const UserDataReducer = createReducer(
    initialState,
    on(userActions.loadUserData, (state, { UserData }) => ({
        ...state,
        userdata: UserData
    })
))

export function reducer(state: IUserDataState, action: Action): any {
    return UserDataReducer(state, action);
}
