import { Component } from '@angular/core';
import { RecaptchaComponent, RecaptchaErrorParameters } from "ng-recaptcha";
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'backoffice';
  appVersion = 1;
  env: any;
  loggedIn: boolean = false;
  setPassword: boolean = sessionStorage.getItem("boLogin") == "false";
  setupOk: boolean = sessionStorage.getItem("boSetup") == "false";
  boUserData: string = sessionStorage.getItem("boData") || '';
  constructor(public router: Router) {
    this.env = environment;
  }


  appCompFun = function () {

  }

  checkSetup() {
    return true;

  }

  ngDoCheck(): void {
    if (window.location.pathname != "/" && window.location.pathname != "/inicio" && window.location.pathname != "/resetpass"
      && window.location.pathname != "/setpassword" && window.location.pathname != "/checkCode") {
      this.loggedIn = sessionStorage.getItem('boLogin') == 'true';
      if (this.loggedIn) {
        this.setPassword = true;
      } else if (this.router.url != "/login") {
        this.router.navigate(["/login"]);
      }
    }
  }

  ngOnInit(): void {

    if (window.location.pathname != "/" && window.location.pathname != "/inicio") {
      sessionStorage.setItem('term', '');
      this.boUserData = sessionStorage.getItem("boData") || '';
      if (this.boUserData != '') {
        this.setPassword = JSON.parse(this.boUserData).userStatus;
      }
      if (this.checkSetup()) {

        this.loggedIn = sessionStorage.getItem('boLogin') == 'true';
        if (this.router.url != "/login" && !this.loggedIn) {
          this.router.navigate(["/login"]);
        } else if (!this.setPassword) {
          this.router.navigate(["/setpassword"]);
        }
      }
    } else {
      if (window.location.search && window.location.href.indexOf('/inicio') == -1) {
        window.location.replace(window.location.href.replace('/?', '/inicio?'))
      }
    }
  }
}
