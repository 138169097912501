import { Component, OnInit } from '@angular/core';

import { LoginService } from "../shared/login.service";
import { Router } from '@angular/router';
import { UsersService } from '../shared/users.service'


@Component({
  selector: 'app-check-code',
  templateUrl: './check-code.component.html',
  styleUrls: ['./check-code.component.css']
})
export class CheckCodeComponent implements OnInit {

  constructor(
    public loginApi: LoginService,
    public router: Router,
    private usrService: UsersService,
  ) { }

  uiMsg = "";
  showMsg = false;
  nextOK: boolean = false;
  code: number = -1;

  ngOnInit(): void {

    if (sessionStorage.getItem('boReset') == null) {
      this.router.navigate(["/login"]);
    }
  }


  enterCode(evt: any) {

    this.code = evt.target.value;
    this.nextOK = true;
  };

  valCode(code: any) {
    this.usrService.checkCode(this.code).subscribe((response: any) => {
      this.router.navigate(["/setpassword"])
    }, error => {
      let that = this;
      this.showMsg = true;
      this.uiMsg = 'El código ingresado no es válido';
      setTimeout(() => {
        that.closeMsg();

      }, 4000)
    })
  };

  closeMsg() {
    this.showMsg = false;
    this.uiMsg = '';
  }

}
