import { Component, OnInit } from '@angular/core';

import { LoginService } from "../shared/login.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-setpassword',
  templateUrl: './setpassword.component.html',
  styleUrls: ['./setpassword.component.css']
})
export class SetPasswordComponent implements OnInit {

  constructor(
    public loginApi: LoginService,
    public router: Router
  ) { }

  password: string = '';
  passwordcheck: string = '';
  passwordMsg: string = '';
  pwdOk: boolean = false;

  ngOnInit(): void {

    if (sessionStorage.getItem('boReset') == null) {
      this.router.navigate(["/login"]);
    }
  }

  ngDoCheck(): void {
    this.pwdOk = this.password.length >= 6 && this.password == this.passwordcheck;
  }

  updatePassword(evt: Event) {
    evt.preventDefault();
    const boData = JSON.parse(sessionStorage.boData);

    let userData = {
      username: boData.username,
      password: this.password,
      token: boData.accessToken ? boData.accessToken.split(".")[2] : boData.token,
    }

    this.loginApi.SetPassword(userData).subscribe((data: any) => {

      boData.userStatus = 1;
      sessionStorage.removeItem("boData");
      sessionStorage.removeItem("boLogin");

      let that = this;
      setTimeout(() => {
        that.router.navigate(["/login"]);
      }, 1000)
    });
  }
}
