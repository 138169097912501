import { Observable } from 'rxjs';
import { Component, OnInit, Input, Output, ElementRef, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { SolicitudesService } from 'src/app/shared/solicitudes.service';
import { SettingsService } from 'src/app/shared/settings.service';
import { UsersService } from 'src/app/shared/users.service';
import { environment } from 'src/environments/environment';
//import { SolicitudesService } from '../shared/solicitudes.service';
//import { SettingsService } from '../shared/settings.service';


@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  @Input() voucher: number = -1;
  @Output() voucherEvent: EventEmitter<any> = new EventEmitter();

  
  tramite: any;
  horaInicio: string = `${environment.hora_inicio_atencion}`; // '12:00';
  horaFin: string = `${environment.hora_fin_atencion}`; //'13:00';
  
  backendAPI: string = `${environment.apiSRCEI}`;
  url_lista_oficinas: string = `${environment.url_lista_oficinas}`;
  plazo_presentacion: string = `${environment.plazo_presentacion}`;

  printTimeStamp: string = new Date().toLocaleString("ES-cl").replace(",", "");


  handleQRError(err: any) {
    console.error('Error generating QR Code: ', err);
  }

  handleQRSuccess(dataURL: string) {
    this.generatedQRCode = dataURL;
  }

  objectToCsv(obj: any) {
    // Flatten the object
    const flatObj: any = {};
    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'object' && !Array.isArray(value)) {
        for (const [subKey, subValue] of Object.entries(`${value}`)) {
          flatObj[`${key}_${subKey}`] = subValue;
        }
      } else {
        flatObj[key] = value;
      }
    }
  
    // Create CSV
    const csvRows = [];
    const header = Object.keys(flatObj).join(',');
    const values = Object.values(flatObj).join(',');
  
    csvRows.push(header);
    csvRows.push(values);
  
    return csvRows.join('\n');
  }

  closeVoucher(){
    this.voucherEvent.emit({ action: 'CLOSE' });
  }
  printVoucher(){

    this.voucherEvent.emit({ action: 'PRINT' });
  }
  async generateHash(inputNumber: number): Promise<string> {
    const msgBuffer = new TextEncoder().encode(inputNumber.toString());
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => ('00' + b.toString(16)).slice(-2)).join('');
    return hashHex;
  }

  constructor( 
    public router: Router, 
    private dom: ElementRef,
    private solService: SolicitudesService,
    private usersService: UsersService,
    private settingService: SettingsService,
  ) { 


     
    }


  opts: any = [];

  qrData: string = "";
  generatedQRCode: string ="";
 
  setDateFormat(f: any) {
    if (f) {
      return `${f.day < 10 ? '0' + f.day : f.day}/${f.month < 10 ? '0' + f.month : f.month}/${f.year}`;
    } else {
      return '';
    }
  }

  loadSolicitud() {
    this.solService.searchSolicitud(this.voucher).subscribe((tramite: any) => {
      this.tramite = tramite;
      this.usersService.getUserInfo(tramite.solicitud.run_solicitante).subscribe((user: any) => {
        this.tramite.organizacion = user.organizacion;
        this.tramite.solicitud.fecha_creacion
        let hoy = new Date(this.tramite.solicitud.fecha_creacion);
        this.tramite.solicitud.fecha_creacion = this.setDateFormat({ day: hoy.getDate(), month: hoy.getMonth() + 1, year: hoy.getFullYear() });
        //this.generateHash(this.voucher).then(r => {
          this.qrData = `${this.backendAPI}/${this.voucher}`;
          setTimeout( ()=>{
            let imgContainer:any = document.querySelector("#voucher .qrContainer img");
            imgContainer.style.width = "90%";

          }, 0)
        //})
      })
  
    })
  }

  ngOnInit(): void {
    this.loadSolicitud();
  }

}
