import { Component, OnInit, ElementRef, ComponentFactoryResolver } from '@angular/core';

import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';

import { SolicitudesService } from '../shared/solicitudes.service';
import { SettingsService } from '../shared/settings.service';
import { environment } from 'src/environments/environment';

import { RutserviceService } from '../rutservice.service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import userdata from '../store/userdata/userdata.interface';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { selectUserData } from '../store/userdata/userdata.selector';

@Component({
  selector: 'app-solicitud',
  templateUrl: './solicitud.component.html',
  styleUrls: ['./solicitud.component.css']
})
export class SolicitudComponent implements OnInit {
  public List$: Observable<userdata>;
  constructor(private route: ActivatedRoute,
    private dom: ElementRef,
    private solService: SolicitudesService,
    private settingService: SettingsService,
    private rutval: RutserviceService,
    private router: Router,
    private store: Store<IUserDataState>) {

    this.List$ = this.store.pipe(select(selectUserData))
  }

  showAgenda: Boolean = false;
  updateOk: boolean = false;
  updateFail: boolean = false;
  editBase: boolean = false
  editMode: boolean = false;
  formValid: boolean = false;
  fechaVoucher: string = ''; // '08 de Abril de 2022';
  fechaVoucherCheck: string = '';
  horaVoucher: string = ''; // '12:30 y las 13:30 hrs.';
  direccionVoucher: string = ''; // 'Obispo Umaña N° 50, Estación Central, ';
  oficinaVoucher: string = '';
  horaInicio: string = '12:00';
  horaFin: string = '13:00';

  backUrl: string = '/';
  loading: boolean = false;
  doc: any;
  id_solicitud: any;
  solicitud: any;
  tramite: any;
  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: [],
    motivosUrgencia: [],
  }
  showFinDialog: boolean = false;
  showConfirmEdit: boolean = false;
  showAsignaRun: boolean = false;
  runAsignado: string = '';
  runAsignadoValid: boolean = false;
  usrData: any;
  isAdmin: boolean = false;
  canEdit: boolean = false;
  dirAtencion: string = '';
  motUrgencia: string = '';
  showVoucher: boolean = false;
  showCambioTipo: boolean = false;
  showFinalizar: boolean = false;
  newType: string = '';
  ticketNumber: any = null;
  updateTicket: boolean = false;

  nuevaSolicitud: any = {
    tipo: 'normal',
    titulo: '',
    autor: '',
    materia: '',
    run_solicitante: '',
    run_ciudadano: '',
    estado: '1',
    id_organizacion: '',
    organizacion: {},
    tramite: {},
  };

  nextStatus: string = "";

  msgTitle: string = '';
  msgText: string = '';
  showSysMsg: boolean = false;
  displayTicket: boolean = false;

  fechaSolicitud: string = '';

  paises: any = [];
  regiones: any = [];
  comunas: any = [];
  comunasAt: any = [];
  regionAtencion: any = -1;
  comunaAtencion: any = -1;


  //Campos para edición de solicitud
  nameCtrl = new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(1)]);
  primerapCtrl = new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(1)]);
  segundoapCtrl = new FormControl('', [Validators.maxLength(30), Validators.minLength(1)]);
  sexoCtrl = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(1)]);
  nacimientoCtrl = new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(8)]);
  nacionalCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(3)]);
  paisNacionalCtrl: string = '';
  pasaportCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]);
  domicilioCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);
  domicilioNumeroCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);
  domicilioLetraCtrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(5)]);
  comunaCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  regionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  direccionAtencionCtrl = new FormControl('', [Validators.maxLength(50), Validators.minLength(5)]);
  regionAtencionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  comunaAtencionCtrl = new FormControl('-1', [Validators.required, Validators.min(1)]);
  telfCtrl = new FormControl('', [Validators.required, Validators.maxLength(20), Validators.minLength(5)]);
  emailCtrl = new FormControl('', [Validators.required, Validators.email, Validators.maxLength(60), Validators.pattern(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/)]);
  proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido1proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido2proge1Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);

  proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido1proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);
  apellido2proge2Ctrl = new FormControl('', [Validators.required, Validators.maxLength(50), Validators.minLength(1)]);


  justUrgenciaCtrl = new FormControl('', [Validators.minLength(3)]);
  idInternoCtrl = new FormControl('');
  motivoUrgenciaCtrl = new FormControl('-1');

  fechaNacimiento = '';
  esMenor = new FormControl('');
  acompanianteMenor = new FormControl('');
  relacionMenor = new FormControl('');
  acompanianteID = new FormControl('');
  fecNacDisplay = '';
  maxBDate: any = '0';
  minBDate: any = '';
  cLookup: any = [];
  comuna = {};

  direccionAtencion: string = '';
  showButtonN: boolean = false;
  showButtonU: boolean = false;

  isMenor: boolean = false;
  cFound: boolean = false;
  cVal: string = '';
  canEditSolicitud: boolean = false;
  agendaAtencion: any = {
    fecha: '',
    hora: ''
  };
  voucher: number = -1;

  anulaSolicitud() {
    let eOK = window.confirm('está seguro de anular la solicitud?\n\rEsto cambiará es status de la solicitud y se le informará a la organización solicitante.');

    if (eOK) {
      this.loading = true;
      this.solicitud.estado = '10';
      this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
        window.location.reload();
      });

    }

  }

  openAgendaDialog() {
    this.showAgenda = true;
  }

  enrolamientoOK() {
    let eOK = window.confirm('¿Se realizó correctamente el enrolamiento del extranjero?\n\rEsto cambiará es status de la solcitud y se le informará a la organización solicitante.');

    if (eOK) {
      this.loading = true;
      this.solicitud.estado = '5';
      this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
        window.location.reload();
      });

    }

  }

  // imprime comprobante
  printTicket() {

    window.print();
  }

  checkUserRole() {
    this.List$.subscribe(data => {


      this.usrData = data;
      if (this.usrData.rol.codigo == 0 || this.usrData.rol.codigo == 10 || this.usrData.rol.codigo == 30) {
        this.isAdmin = true;
        this.canEdit = true;
      }

    })
  }

  valRut(evt: Event) {
    let el: any = evt.target;
    this.runAsignado = this.rutval.RutHelper.formatearFull(el.value, true);

  }

  handleVoucherEvent(data: any) {
    switch (data.action) {
      case "CLOSE":
        this.closeConfirmacion();
        break;
      case "PRINT":
        this.printTicket();
        break;
      default:
        break;

    }
  }

  // muestra el comprobante
  printVoucher() {
    this.showVoucher = true;
  }

  changeRegionAtencion(rd = -1) {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == this.regionAtencion);
    if (filterComunas) {
      this.comunasAt = filterComunas["comunas"];
      this.comunasAt = this.comunasAt.sort((a: any, b: any) => a.nombre_comuna < b.nombre_comuna ? -1 : 1);
      this.comunaAtencion = rd;
      //      this.comunaAtencionCtrl.setValue(-1);
    } else {
      this.comunasAt = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];

    }

  }

  ngDoCheck(): void {
    this.runAsignadoValid = this.rutval.RutHelper.isValid(this.runAsignado);
    if (this.editMode) {
      let f: any = this.fechaNacimiento;

      if (typeof f === 'string') {
        let d = f.split("-");
        f = { day: d[0], month: d[1], year: d[2] };
      }

      if (f) {
        this.fecNacDisplay = this.setDateFormat(f);
        let fN = new Date(f.year, f.month - 1, f.day)
        let hoy = new Date();
        let edif = parseFloat(((((((hoy.valueOf() - fN.valueOf()) / 1000) / 60) / 60) / 24) / 365).toFixed(1));

        this.isMenor = edif < 18;

        if (this.isMenor) {
          this.esMenor.setValue(1);
        } else {
          this.esMenor.setValue(0);
        }
      }

      this.validForm();
    }
  }

  editSolicitud() {
    this.editMode = true;
    let that = this;
    setTimeout(() => {
      let elm: any = document.querySelector('input.fechaNac');
      elm.value = that.fechaNacimiento;

      that.changeRegionAtencion(that.tramite.comuna_atencion);
    }, 0)
  }

  ngOnInit(): void {

    this.regiones = environment.regiones;
    this.loadRegiones();
    const resp = this.settingService.loadSettings().toPromise();
    resp.then((settings: any) => {
      this.settings.roles = settings.filter((s: any) => s.type == 'rol');
      this.settings.estados = settings.filter((s: any) => s.type == 'estado');
      this.settings.materias = settings.filter((s: any) => s.type == 'materia');
      this.settings.motivosUrgencia = settings.filter((s: any) => s.type == 'motivo-urgencia');
      this.settings.slas = settings.filter((s: any) => s.type == 'sla');
      this.loadSolicitud();
    });
    if (this.router.url.indexOf("/solicitar/") != -1) {
      this.editMode = true;

    }

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id_solicitud = params.get('id_solicitud');
      sessionStorage.id_solicitud = this.id_solicitud;
    })
    this.doc = this.dom.nativeElement;
    this.checkUserRole();
  }

  getStatus(status: any) {
    const sts = this.settings.estados.find((e: any) => parseInt(e.codigo) == status);
    return sts.glosa;
  }

  getRegComuna(t: any) {
    let tr = t.region;
    let tc = t.comuna;

    let reg = environment.regiones.find(reg => reg.codigo_region == tr);

    let com = reg?.comunas.find(c => c.codigo_comuna == tc);

    this.tramite.regionLabel = reg?.nombre_region;
    this.tramite.comunaLabel = com?.nombre_comuna;

  }

  sexLabel(s: any) {
    let sex = 'N/I';
    switch (s) {
      case 'F':
        sex = 'Femenino';
        break;
      case 'M':
        sex = 'Masculino';
        break;
      case 'I':
        sex = 'Indeterminado';
        break;
    }
    this.tramite.sexLabel = sex;
  }

  closeConfirmacion() {
    this.ticketNumber = '';
    this.showVoucher = false;
    this.updateTicket = false;

  }

  checkEditarBase() {
    if (parseInt(this.solicitud.estado) > 4) {
      this.editBase = false;
      this.canEdit = false;
      this.editMode = false;
    } else {
      this.editBase = true;
      this.canEdit = false;
    }
  }

  updateFormControls() {
    this.nameCtrl.setValue(this.tramite.nombre);
    this.primerapCtrl.setValue(this.tramite.paterno);
    this.segundoapCtrl.setValue(this.tramite.materno);
    this.sexoCtrl.setValue(this.tramite.sexo);
    this.nacimientoCtrl.setValue(this.tramite.fecha_nac.split("-").reverse().join("-"));
    this.nacionalCtrl.setValue(this.tramite.nacionalidad);
    this.pasaportCtrl.setValue(this.tramite.pasaporte);
    this.domicilioCtrl.setValue(this.tramite.direccion);
    this.domicilioNumeroCtrl.setValue(this.tramite.direccion_numero);
    this.domicilioLetraCtrl.setValue(this.tramite.direccion_letra);
    this.regionCtrl.setValue(this.tramite.region);

    let reg = this.regiones.find((r: any) => r.codigo_region == this.tramite.region);
    this.comunas = reg.comunas;

    this.comunaCtrl.setValue(this.tramite.comuna);
    this.telfCtrl.setValue(this.tramite.telefono);
    this.emailCtrl.setValue(this.tramite.email);
    this.proge1Ctrl.setValue(this.tramite.nombre_progenitor_1);
    this.apellido1proge1Ctrl.setValue(this.tramite.primer_apellido_progenitor_1);
    this.apellido2proge1Ctrl.setValue(this.tramite.segundo_apellido_progenitor_1);
    this.proge2Ctrl.setValue(this.tramite.nombre_progenitor_2);
    this.apellido1proge2Ctrl.setValue(this.tramite.primer_apellido_progenitor_2);
    this.apellido2proge2Ctrl.setValue(this.tramite.segundo_apellido_progenitor_2);
    this.justUrgenciaCtrl.setValue(this.tramite.justifica_urgencia);
    this.idInternoCtrl.setValue(this.tramite.id_interno);
    this.direccionAtencionCtrl.setValue(this.tramite.direccion_atencion);
    this.direccionAtencion = this.tramite.direccion_atencion;
    this.comunaAtencion = this.tramite.comuna_atencion;
    this.regionAtencion = this.tramite.region_atencion;
    this.regionAtencionCtrl.setValue(this.tramite.region_atencion);

    this.comunaAtencionCtrl.setValue(this.tramite.comuna_atencion);

    this.motivoUrgenciaCtrl = this.tramite.motivo_urgencia;
    this.esMenor.setValue(this.tramite.menor_de_edad);
    this.acompanianteMenor.setValue(this.tramite.acompaniante.toUpperCase());
    this.relacionMenor.setValue(this.tramite.relacion_menor_de_edad.toUpperCase());
    this.acompanianteID.setValue(this.tramite.acompaniante_id.toUpperCase());

    this.nacimientoCtrl.setValue(this.tramite.fecha_nac)

  }

  loadSolicitud() {

    this.solService.searchSolicitud(this.id_solicitud).subscribe((sol: any) => {

      this.ticketNumber = this.id_solicitud;
      this.tramite = sol;
      this.solicitud = sol.solicitud;
      if (this.solicitud.tipo == "normal") {
        this.fechaVoucherCheck = this.solicitud.fecha_voucher.split("-").reverse().join("-") + "T00:00:00";
      } else {
        this.fechaVoucherCheck = this.solicitud.fecha_agenda; //.split("-").reverse().join("-") + "T00:00:00";
      }
      this.solicitud.statusText = this.getStatus(sol.solicitud.estado);
      this.solicitud.fecha_voucher = this.formatDate(this.solicitud.fecha_voucher);
      this.sexLabel(this.tramite.sexo);
      this.tramite.fecha_nac_label = this.tramite.fecha_nac.split("-").reverse().join("-");
      this.fechaNacimiento = this.tramite.fecha_nac;

      this.getRegComuna(this.tramite);
      this.newType = this.solicitud.tipo == 'normal' ? 'urgente' : 'normal';

      if (this.solicitud.tipo == 'urgente') {
        let regName: any = environment.regiones.find(reg => reg.codigo_region == this.tramite.region_atencion);;
        if (regName) {
          this.tramite.region_atencion_text = regName.nombre_region;
          let comName = regName.comunas.find((c: any) => c.codigo_comuna == this.tramite.comuna_atencion);
          if (comName) {
            this.tramite.comuna_atencion_text = comName.nombre_comuna;
          }
          this.dirAtencion = `${this.tramite.direccion_atencion || ''}
${this.tramite.comuna_atencion_text || ''}
${this.tramite.region_atencion_text || ''}`;

        }

        this.motUrgencia = `${this.tramite.motivo_urgencia ?
          this.settings.motivosUrgencia.find((m: any) => m.codigo == this.tramite.motivo_urgencia).glosa + ': ' :
          ''}${this.tramite.justifica_urgencia || ''}`

      }
      this.nuevaSolicitud = this.solicitud;

      this.updateFormControls();
      this.checkEditarBase();
      this.checkFinalizar();

      this.checkMenor();

    });
  }


  checkMenor() {
    let fN = this.nacimientoCtrl.value.split("-").reverse();

    let f = {
      year: fN[0],
      month: fN[1],
      day: fN[2]
    };

    if (f) {
      let fN = new Date(f.year, f.month - 1, f.day);
      let hoy = new Date();

      let edif = parseFloat(((((((hoy.valueOf() - fN.valueOf()) / 1000) / 60) / 60) / 24) / 365).toFixed(1));

      this.isMenor = edif < 18;

      if (this.isMenor) {
        this.esMenor.setValue(1);
      } else {
        this.esMenor.setValue(0);
      }

    }
  }

  searchAgain() {
    let menuLnks = document.querySelectorAll('#sidebar li a');
    let firstLnk: any = '/';
    for (let x = 0; x < menuLnks.length; x++) {
      let m = menuLnks[x];
      let lnk = m.getAttribute('href');
      if (lnk != '/home') {
        firstLnk = lnk;
        x = menuLnks.length;
      }
    }
    this.router.navigate([firstLnk]);

  }

  iniciarAtencion(): void {
    this.loading = true;
    this.solicitud.estado = '2';

    this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
      window.location.reload();
    })
  }

  agendarAtencion(): void {
    this.loading = true;
    this.solicitud.estado = '2';

    this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
      window.location.reload();
    })
  }

  marcaPendiente(): void {
    this.loading = true;
    this.solicitud.estado = '3';

    this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
      window.location.reload();
    })
  }

  openAsignaRun() {
    this.showAsignaRun = true;
    this.runAsignado = '';

  }

  asignarRUN() {

    this.showAsignaRun = false;
    this.loading = true;
    this.solicitud.run_ciudadano = this.runAsignado;
    this.updateRunAsignado();
  }

  updateRunAsignado() {
    this.solService.updateRun(this.solicitud).subscribe((resp: any) => {
      this.closeAsignaRun();
      this.finAtencion();
    });


  }

  closeAsignaRun() {
    this.showAsignaRun = false;
    this.loading = false;
    this.runAsignado = '';
  }
  closeFinDialog() {
    this.showFinDialog = false;
    this.loading = false;

  }

  closeCambioTipo() {
    this.showConfirmEdit = false;
  }

  finAtencion(): void {
    this.loading = true;
    this.solicitud.estado = '5';

    if (this.solicitud.run_ciudadano.trim() == '') {
      let ret = confirm('No se ha asignado un RUN a la persona extranjera. ¿Desea cerrar la solicitud de todas maneras? ');

      if (ret) {
        this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
          this.searchAgain();
        })
      }
    } else {
      this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
        this.searchAgain();
      })
    }
  }

  cerrarSolicitud(): void {
    this.loading = true;
    this.solicitud.estado = '6';

    if (this.solicitud.run_ciudadano.trim() == '') {
      let ret = confirm('No se ha asignado un RUN a la persona extranjera. ¿Desea cerrar la solicitud de todas maneras? ');

      if (ret) {
        this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
          this.searchAgain();
        })
      }
    } else {
      this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {
        this.searchAgain();
      })
    }
  }

  formatDate(f: string) {
    let meses = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    let fec = f.split('-');
    let out = fec[0] + ' de ' + meses[parseInt(fec[1])] + ' de ' + fec[2];

    return out;
  }

  setDateFormat(ff: any) {

    try {
      let f = ff;
      if (f && f.day && f.month && f.year) {
        return `${f.day < 10 ? '0' + f.day : f.day}/${f.month < 10 ? '0' + f.month : f.month}/${f.year}`;
      } else {
        let nF = f.indexOf("-") ? f.split("-") : f;

        f = nF[0].length == nF[1].length && nF[2].length == 4 ? nF.reverse() : nF;
        f = {
          year: parseInt(f[0]),
          month: parseInt(f[1]),
          day: parseInt(f[2])
        }

        return `${f.day < 10 ? '0' + f.day : f.day}/${f.month < 10 ? '0' + f.month : f.month}/${f.year}`;
      }

    } catch (ex) {
      console.log(ex);
      return ff;
    }
  }

  submitSolicitud(): void {

    if (this.formValid) {
      let user = JSON.parse(atob(sessionStorage.getItem('boToken')?.split(".")[1] || ''));
      this.nuevaSolicitud.run_solicitante = user.username;
      this.nuevaSolicitud.organizacion = user.organizacion;
      this.nuevaSolicitud.id_organizacion = user.organizacion.id_organizacion;
      this.nuevaSolicitud.titulo = `${this.nameCtrl.value} ${this.primerapCtrl.value} ${this.segundoapCtrl.value}`;
      this.nuevaSolicitud.materia = 'articulo 44';
      let data = $("#ticketForm").serializeArray();

      let formData: any = {};
      data.forEach(d => {

        formData[d.name] = d.value;
      });

      this.nuevaSolicitud.tramite = formData;
      this.nuevaSolicitud.tramite.emailCtrl = this.emailCtrl.value.trim() == '' ? 'sincorreo@registrocivil.cl' : this.emailCtrl.value;
      this.nuevaSolicitud.tramite.esMenor = this.isMenor;
      if (this.nuevaSolicitud.tipo == 'urgente') {
        this.nuevaSolicitud.tramite.regionCtrl = this.regionAtencion;
        this.nuevaSolicitud.tramite.comunaCtrl = this.comunaAtencion;
        this.nuevaSolicitud.tramite.motivoUrgencia = this.motivoUrgenciaCtrl.value;

      }

      this.solService.updateSolicitud(this.nuevaSolicitud).subscribe((ret: any) => {

        this.editMode = false;

        this.voucher = this.nuevaSolicitud.id;
        this.updateTicket = true;
        this.loadSolicitud();
        //this.showTicket();
        // window.location.reload();
      }, (err: any) => {
        console.log('ERROR', err);

      });
      let hoy = new Date();
      this.fechaSolicitud = this.setDateFormat({ day: hoy.getDate(), month: hoy.getMonth() + 1, year: hoy.getFullYear() });

    }
  }


  showTicket() {
    let theDoc = this.doc.querySelector(".overlay");
    if (theDoc) {
      theDoc.style.display = 'block';

    }
    this.displayTicket = true;
  }

  getErrorDescription(err: any) {
    let errLabel = '';
    switch (err) {
      case 'ER_DUP_ENTRY':
        errLabel = 'Solicitud Duplicada';
        break;
      default:
        errLabel = 'Error Interno'
        break;
    }
    return errLabel;
  }

  changeRegion() {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == this.regionCtrl.value);
    if (filterComunas) {
      this.comunas = filterComunas["comunas"];
      this.comunaCtrl.setValue(-1);
    } else {
      this.comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
      this.comuna = {};
    }

  }

  validForm() {

    if (this.nameCtrl.valid && this.sexoCtrl.valid && this.primerapCtrl.valid &&
      this.fecNacDisplay.trim() != '' && this.nacionalCtrl.valid) {
      if (this.nuevaSolicitud.tipo == 'urgente') {
        if (this.motivoUrgenciaCtrl.value != -1 && this.justUrgenciaCtrl.value != '' && this.justUrgenciaCtrl.valid &&
          this.direccionAtencion != '' &&
          this.regionAtencion != -1 &&
          this.comunaAtencion != -1) {
          this.comunaAtencionCtrl.setValue(this.comunaAtencion);
          this.regionAtencionCtrl.setValue(this.regionAtencion);
          /* this.comunaCtrl.setValue(this.comunaAtencion);
          this.regionCtrl.setValue(this.regionAtencion); */
          this.comuna = this.comunaAtencion;

          this.formValid = true;
        } else {
          this.formValid = false;
        }
      } else {
        if (this.comunaCtrl.valid && this.regionCtrl.valid) {
          this.formValid = true;

        } else {
          this.formValid = false;
        }
      }

      if (this.isMenor) {
        if (this.proge1Ctrl.value != '' || this.proge2Ctrl.value || (this.acompanianteMenor.value != '' && this.relacionMenor.value != '')) {
          this.formValid = true;
        } else {
          this.formValid = false;
        }
      }


    } else {
      this.formValid = false;
    }

  }

  loadRegiones() {
    let that = this;
    this.regiones = environment.regiones;
    this.paises = environment.paises; //.map( p => p.name);
  }

  changeComuna() {
    this.comuna = this.comunaCtrl.value;
  }

  countryLookup(evt: any) {
    evt.preventDefault();
    let valor = evt.target.value.toLowerCase();
    this.cVal = valor;
    this.cFound = true;

    if (valor.trim() == '') {
      this.cLookup = [];
    } else {
      this.cLookup = this.paises.filter((p: any) => p.name.toLowerCase().indexOf(valor) >= 0);

      if (this.cLookup.length == 0) {
        this.cFound = false;
        this.cLookup.push("No se encontraron coincidencias")
      } else {

        this.cFound = true;
      }
    }

  }

  selectCountry(evt: any, cId: any) {
    evt.preventDefault();
    let valor = evt.target.innerText;
    this.nacionalCtrl.setValue(valor);
    this.cLookup = [];

  }

  checkFinalizar() {
    if (["6", "7", "8", "9"].indexOf(this.solicitud.estado) >= 0) {
      this.showFinalizar = true;
      this.nextStatus = "12";
    }
    if (this.solicitud.tipo == "normal" && this.solicitud.estado == "11") {
      this.showFinalizar = true;
      this.nextStatus = "13";
    }
    if (this.solicitud.tipo == "urgente" && this.solicitud.estado == "10") {
      this.showFinalizar = true;
      this.nextStatus = "14";
    }
  }

  finalizarSolicitud(evt: any) {
    this.loading = true;
    evt.preventDefault();
    evt.stopPropagation();
    this.loading = true;
    this.showFinDialog = true;

  }

  okFinSolicitud() {
    this.solicitud.estado = this.nextStatus;



    this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {

      this.searchAgain();
      this.showFinDialog = false;
      this.loading = false;
    })

  }

  cleanSpaces(formControl: FormControl) {
    let value = formControl.value;

    value = value.trim().toUpperCase();
    value = value.replace(/\s+/g, ' ');

    formControl.setValue(value);
  }

  goBack() {

    if (parseInt(this.nuevaSolicitud.estado) >= 12) {
      this.router.navigate(['historico']);
    } else {
      this.router.navigate(['solicitudes']);

    }

  }
}
