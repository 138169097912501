<div class="homeContainer">
  <div class="landing">
    <blockquote>
      A través de esta aplicación, instituciones tanto públicas como privadas podrán solicitar el Rol Único Nacional (RUN) para personas extranjeras que no cuenten con dicho número, con lo que se da cumplimiento a lo establecido en el Artículo 44 de la Ley N° 21.325 de Migración y Extranjería.
    </blockquote>
    <div class="landing-img">
      <img src="assets/landing_img.png" alt="Angular App">
    </div>
  </div>
</div>

