<h3>Usuarios Registrados</h3>
<div *ngIf="!orgId; else usuariosOrganizacion">
  <blockquote>Módulo de gestión de usuarios</blockquote>
  <div class="actions" *ngIf="canCreate">
    <a #usrBtn href="javascript:;" (click)="crearUsuario(modalCrearUsuario)" class="btn btn-primary btn-sm">
      <i class="bi bi-person-plus-fill"></i> Nuevo Usuario</a>
  </div>

  <table class="table table-striped table-bordered table-sm row-border hover" id="dataTable">
    <thead>
      <tr>
        <th>Organización</th>
        <th>Rol</th>
        <th>Usuario</th>
        <th>Nombre</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of users" (id)="item.username">
        <td>{{item.organizacionName}}</td>
        <td>{{item.roleName}}</td>
        <td>{{item.username}}</td>
        <td>{{item.firstName}} {{item.lastName}}</td>
        <td>{{item.email}}</td>

        <td class="actions">
          <a href="javascript:;" (click)="editarUsuario(modalEditarUsuario, item.username)" alt="Editar"
            title="Editar Usuario">
            <i class="bi bi-pencil-square"></i>
          </a>
          <a href="javascript:;" (click)="borrarUsuario(modalDeleteUsuario, item.username)"
            *ngIf="item.username != usrData.username" alt="Borrar" title="Eliminar Usuario">
            <i class="bi bi-trash"></i>
          </a>
        </td>

      </tr>
    </tbody>
  </table>
  <h5 *ngIf="loading" class="text-center m-3 p-3"> <img src="assets/loading.gif" class="mb-3" /> <br /><small>Cargando
      Usuarios</small></h5>
</div>

<!-- Template para ususarios de la organización -->
<ng-template #usuariosOrganizacion>
  <div class="actions">
    <a #usrBtn href="javascript:;" (click)="crearUsuario(modalCrearUsuario)" class="btn btn-primary btn-sm">
      <i class="bi bi-person-plus-fill"></i> Nuevo Usuario</a>
  </div>
  <table class="table table-striped table-sm table-bordered table-sm row-border hover" id="dataTable">
    <thead>
      <tr>
        <th>Organización</th>
        <th>Rol</th>
        <th>Usuario</th>
        <th>Nombre</th>
        <th>Email</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of users" (id)="item.username">
        <td>{{item.organizacionName}}</td>
        <td>{{item.roleName}}</td>
        <td>{{item.username}}</td>
        <td>{{item.firstName}} {{item.lastName}}</td>
        <td>{{item.email}}</td>
        <td class="actions">
          <a href="javascript:;" (click)="editarUsuario(modalEditarUsuario, item.username)" alt="Editar"
            title="Editar Usuario">
            <i class="bi bi-pencil-square"></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</ng-template>

<!--  MODAL PARA CREAR USUARIO -->
<ng-template #modalCrearUsuario let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="bi bi-person"></i> Crear usuario
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
    </div>
    <div class="container">
      <form class="row" (ngSubmit)="crearUsuarioSubmit($event)">
        <small class='my-2'>
          <span class="required">*</span> Campos Requeridos
        </small>
        <div class="form-group" *ngIf="usrObj && usrObj.rol.codigo == 0">
          <label for="organizacion">Organización</label>
          <select (change)="changeOrg()" [(ngModel)]="nuevoUsuario.organizacion" class="form-control"
            name="organizacion">
            <option value="0">- Seleccione Organización -</option>
            <option value="1" *ngIf="usrObj.rol.codigo==0">SRCeI</option>
            <option *ngFor="let organizacion of organizaciones" [value]="organizacion.id_organizacion">
              {{organizacion.nombre}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="firstName"><span class="required">*</span> Nombre</label>
          <input [(ngModel)]="nuevoUsuario.firstName" class="form-control" type="text" name="firstName"
            placeholder="Nombre" />
        </div>
        <div class="form-group">
          <label for="lastName"><span class="required">*</span> Apellido</label>
          <input [(ngModel)]="nuevoUsuario.lastName" class="form-control" type="text" name="lastName"
            placeholder="Apellido" />
        </div>

        <div class="form-group">
          <label for="username"><span class="required">*</span> Run Usuario</label>
          <input (blur)="valRut($event, 'nuevoUsuario')" [(ngModel)]="nuevoUsuario.username" class="form-control"
            name="username" type="text" placeholder="Run del usuario" />
        </div>
        <div class="form-group">
          <label for="email"><span class="required">*</span> Email</label>
          <input [(ngModel)]="nuevoUsuario.email" class="form-control" name="email" type="email"
            (blur)='valEmail($event)' placeholder="Email del usuario" onpaste="return false;" oncopy="return false"
            oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />
        </div>
        <div class="form-group">
          <label for="email"><span class="required">*</span> Confirmar Email</label>
          <input [(ngModel)]="nuevoUsuario.confirEmail" class="form-control" name="confirEmail" type="email"
            (blur)='confirEmail($event)' placeholder="Confirmar email" onpaste="return false;" oncopy="return false"
            oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />
        </div>
        <div class="form-group">
          <label for="rol"><span class="required">*</span> Rol</label>

          <select [(ngModel)]="nuevoUsuario.rol" class="form-control" name="rol">
            <option value="-1">- Seleccione un Rol -</option>
            <option *ngFor="let rol of settings.roles" [value]="rol.id">
              {{rol.glosa}}
            </option>
          </select>
        </div>

        <div class="form-group" *ngIf="nuevoUsuario.organizacion == 1">
          <label for="region"><span class="required">*</span> Región de Atención</label>
          <select [(ngModel)]="nuevoUsuario.region" class="form-control" name="region">
            <option value="-1" selected>- Seleccione Región -</option>
            <option *ngFor="let region of regiones" [value]="region.codigo_region">
              {{region.nombre_region}}
            </option>
          </select>

        </div>

        <div class="col-12 mt-2 text-right">
          <button type="submit" class="btn btn-primary">
            <i class="bi bi-plus-lg"></i> Crear</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<!--  MODAL PARA EDITAR USUARIO -->
<ng-template #modalEditarUsuario let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="bi bi-person"></i> Editar usuario
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="mensajes text-center" [ngClass]="{'active': showMsg}">{{uiMsg}}
    </div>
    <div class="container">
      <form class="row" (ngSubmit)="editarUsuarioSubmit($event)">
        <div class="form-group" *ngIf="usrObj && usrObj.rol.codigo == 0">
          <label for="organizacion">Organización</label>
          <select (change)="changeOrg()" [(ngModel)]="currentUser.organizacion" class="form-control"
            name="organizacion">
            <option value="0">- Seleccione Organización -</option>
            <option value="1" *ngIf="usrObj.rol.codigo==0">SRCeI</option>
            <option *ngFor="let organizacion of organizaciones" [value]="organizacion.id_organizacion">
              {{organizacion.nombre}}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="firstName">Nombre</label>
          <input [(ngModel)]="currentUser.firstName" class="form-control" type="text" name="firstName"
            placeholder="Nombre" />
        </div>
        <div class="form-group">
          <label for="lastName">Apellido</label>
          <input [(ngModel)]="currentUser.lastName" class="form-control" type="text" name="lastName"
            placeholder="Apellido" />
        </div>

        <div class="form-group">
          <label for="username">Nombre de usuario</label>
          <input disabled (blur)="valRut($event,'current')" [(ngModel)]="currentUser.username"
            class="form-control bg-white" name="username" type="text" placeholder="Nombre de usuario" />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input [(ngModel)]="currentUser.email" class="form-control" name="email" type="email"
            placeholder="Email del usuario" onpaste="return false;" oncopy="return false" oncut="return false"
            autocomplete="off" ondrop="return false" ondrag="return false" />
        </div>
        <div class="form-group">
          <label for="email">Confirmar Email</label>
          <input [(ngModel)]="currentUser.confirEmail_update" class="form-control" (blur)="confirEmail_update($event)"
            name="confirEmail_update" type="email" placeholder="Confirmar email" onpaste="return false;"
            oncopy="return false" oncut="return false" autocomplete="off" ondrop="return false" ondrag="return false" />

        </div>
        <div class="form-group">
          <label for="rol">Rol</label>

          <select [(ngModel)]="currentUser.rol" class="form-control" name="rol">
            <option value="-1">- Seleccione un Rol -</option>
            <option *ngFor="let rol of settings.roles" [value]="rol.id">
              {{rol.glosa}}
            </option>
          </select>
        </div>

        <div class="form-group" *ngIf="currentUser.organizacion == 1">
          <label for="region"><span class="required">*</span> Región de Atención</label>
          <select [(ngModel)]="currentUser.region" class="form-control" name="region">
            <option value="-1" selected>- Seleccione Región -</option>
            <option value="">Nivel Central</option>
            <option *ngFor="let region of regiones" [value]="region.codigo_region">
              {{region.nombre_region}}
            </option>
          </select>

        </div>

        <div class="col-12 mt-2 text-right">
          <button type="submit" class="btn btn-primary">
            <i class="bi bi-check-lg"></i> Actualizar</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>


<!--  MODAL PARA ELIMINAR USUARIO -->
<ng-template #modalDeleteUsuario let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <i class="bi bi-person-x"></i> Eliminar usuario
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container">
      <form class="row" (ngSubmit)="deleteUsuarioSubmit($event)">

        <div class="form-group">
          <label for="firstName">Nombre</label>
          <input disabled [(ngModel)]="currentUser.firstName" class="form-control" type="text" name="firstName"
            placeholder="Nombre" />
        </div>
        <div class="form-group">
          <label for="lastName">Apellido</label>
          <input disabled [(ngModel)]="currentUser.lastName" class="form-control" type="text" name="lastName"
            placeholder="Apellido" />
        </div>

        <div class="form-group">
          <label for="username">Nombre de usuario</label>
          <input disabled [(ngModel)]="currentUser.username" class="form-control" name="username" type="text"
            placeholder="Nombre de usuario" />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input disabled [(ngModel)]="currentUser.email" class="form-control" name="email" type="email"
            placeholder="Email del usuario" />
        </div>
        <div class="form-group">
          <label for="rol">Rol</label>

          <select disabled [(ngModel)]="currentUser.rol" class="form-control" name="rol">
            <option value="-1">- Seleccione un Rol -</option>
            <option *ngFor="let rol of settings.roles" [value]="rol.id">
              {{rol.glosa}}
            </option>
          </select>
        </div>
        <div class="col-12 mt-2 text-right">
          <button type="submit" class="btn btn-primary">
            <i class="bi bi-person-x"></i> Eliminar</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>