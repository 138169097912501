import { Component, OnInit } from '@angular/core';

import { RutserviceService } from '../rutservice.service';
import { UsersService } from '../shared/users.service'
import { Router } from '@angular/router';
import { validate, format } from 'rut.js';

@Component({
  selector: 'app-resetpass',
  templateUrl: './resetpass.component.html',
  styleUrls: ['./resetpass.component.css']
})
export class ResetpassComponent implements OnInit {

  constructor(
    public router: Router,
    private usrService: UsersService,
    private rutval: RutserviceService
  ) { }

  username: string = '';
  email: string = '';

  usrok: boolean = false;

  nextOK: boolean = false;
  resetOK: boolean = false;
  uiMsg = "";
  showMsg = false;

  ngOnInit(): void {

  }

  ngDoCheck(): void {
    this.nextOK = this.username.trim() == '' ? false : true;
    this.resetOK = this.email.trim() == '' ? false : true;
  }

  nextPass() {
    this.usrService.findUser(this.username).subscribe((retUsr: any) => {
      this.usrok = retUsr;
    })
  }

  resetPass() {
    this.usrService.findEmail(this.email).subscribe((retUsr: any) => {

      this.closeMsg();
      sessionStorage.removeItem('boToken');
      sessionStorage.removeItem('idOrganizacion');
      sessionStorage.removeItem('boLogin');
      sessionStorage.removeItem('boOpt');
      sessionStorage.removeItem("boData");
      sessionStorage.setItem('boReset', "true");
      sessionStorage.setItem('boData', JSON.stringify(retUsr));
      this.router.navigate(["/checkCode"])
    },
      (error: any) => {
        let that = this;
        this.showMsg = true;
        this.uiMsg = 'Los datos ingresados no corresponden.';
        setTimeout(() => {
          that.closeMsg();

        }, 3000)
      })
  }

  valRut(evt: Event, context: string) {
    let el: any = evt.target;
    if (validate(el.value.trim())) {
      el.value = format(el.value, { dots: false });
    }
    if (!validate(el.value.trim())) {
      this.uiMsg = "Debe ingresar un Usuario válido"
      this.showMsg = true;
      el.focus();
    } else {
      this.uiMsg = ""
      this.showMsg = false;
      if (context === "username") this.username = el.value;
    }
  }

  valEmail(evt: Event) {
    let el: any = evt.target;
    if (el.value.match(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,5}$/)) {
      this.uiMsg = ""
      this.showMsg = false;
      this.email = el.value;
    } else {
      this.uiMsg = "Debe ingresar un correo electrónico válido para el usuario a crear"
      this.showMsg = true;

    }
  }

  closeMsg() {
    this.showMsg = false;
    this.uiMsg = '';
  }


}
