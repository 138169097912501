import { Component, OnInit, HostListener } from '@angular/core';
import { SolicitudesService } from '../shared/solicitudes.service';
import { LanguageApp } from '../shared/dataTableLang';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../shared/settings.service';
import { UsersService } from '../shared/users.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  allSolicitudes,
} from '../store/solicitudes.selector';
import { SolicitudModel } from './solicitud.model';
import { Observable, TimeInterval } from 'rxjs';
import { collapseTextChangeRangesAcrossMultipleVersions } from 'typescript';
import { OrganizacionesService } from '../shared/organizaciones.service';

import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import userdata from '../store/userdata/userdata.interface';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { selectUserData } from '../store/userdata/userdata.selector';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})

export class SolicitudesComponent implements OnInit {
  loading: boolean = true;
  solicitudes!: any[];
  urgentes: number[] = [];
  solicitud: any = null;
  newStatus: any;
  closeModal: string = "";
  canUpdate: boolean = false;
  canUpdateOwner: boolean = false;
  canCreate: boolean = false;
  updateFlag: any = null;
  filterNotFound = false;
  filtering: boolean = false;
  filter: any = {
    tipo: '',
    estado: '',
    region: '',
    organizacion: ''
  };
  regiones: any = [];
  isAdmin: boolean = false;
  // isGestor: boolean = false;
  usrData: any;
  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: []
  }
  users: any = [];
  public List$: Observable<userdata>;
  constructor(
    private solService: SolicitudesService,
    private settingService: SettingsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private orgServices: OrganizacionesService,
    private router: Router,
    private store: Store<IUserDataState>
  ) {

    this.List$ = this.store.pipe(select(selectUserData))
  }

  organizaciones: any = [];
  orgsSelect: any = [];
  changeStatusForm!: FormGroup;
  assignOwnerForm!: FormGroup;

  showAgendaPopup: boolean = false;
  posTop: any = null;
  posLeft: any = null;

  agendaPopup: any;
  agendaFecha: string = '';
  agendaHora: string = '';
  agendaDireccion: string = '';

  historicos: boolean = false;

  searchTerm: string = '';
  searchResults: any = []

  //Variables Pagitaor
  total: number = 0;
  page: number = 1;
  size: number = 10;
  desde: number = 1 + (this.page - 1) * this.size;
  hasta: number = this.page * this.size;
  totalPages: number = Math.ceil(this.total / this.size);


  prevPage() {
    this.page = this.page > 1 ? (this.page - 1) : 1;

    if (this.searchTerm != "") {
      this.globalSearch();

    } else if (this.filter.tipo == '' && this.filter.estado == '' && this.filter.region == '' && this.filter.organizacion == '') {
      this.loadSolicitudes();

    } else {
      this.filterGrid();
    }
  }

  nextPage() {

    this.page = this.page < this.totalPages ? (this.page + 1) : this.totalPages;

    if (this.searchTerm != "") {
      this.globalSearch();

    } else if (this.filter.tipo == '' && this.filter.estado == '' && this.filter.region == '' && this.filter.organizacion == '') {
      
      this.loadSolicitudes();

    } else {
      this.filterGrid();
    }
  }

  changeFilter(evt: Event) {
    this.total = 0;
    this.page = 1;
    this.desde = 1 + (this.page - 1) * this.size;
    this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
    this.totalPages = Math.ceil(this.total / this.size);

    this.searchTerm = '';
    sessionStorage.setItem('term', '');
    this.filterGrid();
  }

  clearSearch() {
    this.searchTerm = '';
    this.page = 1;
    sessionStorage.setItem('term', '');
    this.cleanFilters();
  }

  cleanFilters() {
    this.filter.tipo = '';
    this.filter.estado = '';
    this.filter.region = '';
    this.filter.organizacion = '';
    sessionStorage.setItem("filter", JSON.stringify(this.filter))
  }

  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => { }, (res) => {
      //Reset Values
      this.solicitud = null;
      this.canUpdate = false;
      this.canUpdateOwner = false;
    });
  }

  getStatus(status: any) {
    const sts = this.settings.estados.find((e: any) => e.codigo == status);
    return sts.glosa;
  }

  getRol(rol: number) {
    let rolObj = this.settings.find((r: any) => r.type == 'rol' && r.codigo == rol);
    if (rolObj) {
      return rolObj.glosa;
    }
  }

  getUserName(username: any) {

    if (username) {
      let usr = this.users.find((u: any) => u.username == username);
      if (usr) {
        return usr.firstName + " " + usr.lastName;
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  loadUsers() {
    this.usersService.loadUsers().subscribe((users: any) => {
      this.users = users;
      this.users.sort((a: any, b: any) => a.role > b.role ? 1 : -1);

      this.searchTerm = sessionStorage.getItem("term") || '';
      // this.filter = JSON.parse(sessionStorage.getItem("filter") || '{}') || null;

      if (this.searchTerm != '') {
        this.globalSearch();
      } else {
        this.loadSolicitudes();
      }

    });

  }

  printSolicitud() {
    window.print();
  }

  globalSearch() {
    this.loading = true;
    this.solicitudes = [];
    this.filterNotFound = false;
    this.page = 1;
    this.solService.globalSearch(this.searchTerm, this.page).subscribe((resp: any) => {
      sessionStorage.setItem('term', this.searchTerm);
      
      const r = resp.data;

      this.total = resp.total;

      this.desde = 1 + (this.page - 1) * this.size;
      this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
      this.totalPages = Math.ceil(this.total / this.size);
      if (r.length > 0) {
        this.solicitudes = r

        //Update status labels
        this.solicitudes.forEach((sol: any) => {

          sol.estadoLabel = this.getStatus(sol.estado);
          sol.responsableLabel = this.getUserName(sol.owner);
          sol.autor = sol.run_solicitante,
            sol.autorLabel = this.getUserName(sol.autor);
          sol.organizacion = {
            comuna: sol.comuna,
            comuna_representante: sol.comuna_representante,
            correo_administrador: sol.correo_administrador,
            correo_representante: sol.correo_representante,
            direccion: sol.direccion,
            direccion_representante: sol.direccion_representante,
            estado: sol.estado,
            fecha_creacion: sol.fecha_creacion,
            fecha_modificacion: sol.fecha_modificacion,
            fecha_resolucion_convenio: sol.fecha_resolucion_convenio,
            fecha_subscripcion_convenio: sol.fecha_subscripcion_convenio,
            id_organizacion: sol.id_organizacion,
            nombre: sol.nombre,
            nombre_administrador: sol.nombre_administrador,
            nombre_representante: sol.nombre_representante,
            numero_subscripcion_convenio: sol.numero_subscripcion_convenio,
            region: sol.region,
            region_representante: sol.region_representante,
            run: sol.run,
            run_administrador: sol.run_administrador,
            run_representante: sol.run_representante,
            telefono_administrador: sol.telefono_administrador,
            telefono_representante: sol.telefono_representante
          }
        });

        this.loading = false;
      } else {
        this.filterNotFound = true;
        this.loading = false;

      }
      this.searchResults = r;
    });
  }

  searchSolicitud(evt: any) {
    this.searchResults = [];
    if (this.searchTerm == '') {
      this.searchTerm = '';
      sessionStorage.setItem('term', '');
    }
    if (evt.type === 'keyup' && evt.key === 'Enter') {
      this.cleanFilters();
      this.globalSearch();
    } else if (evt.type === 'click') {
      this.globalSearch()
    }
  }

  filterGrid() {

    this.loading = true;
    this.solicitudes = [];
    this.filterNotFound = false;
    $('#dataTableSolicitudes').DataTable().destroy();

    if (this.usrData.rol.codigo == 35) {
      this.filter.region = this.usrData.region;
    }

    this.solService.filterGrid(this.filter, this.page).subscribe((resp: any) => {
      sessionStorage.setItem("filter", JSON.stringify(this.filter))
      const r = resp.data;

      this.total = resp.total;
      this.desde = 1 + (this.page - 1) * this.size;
      this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
      this.totalPages = Math.ceil(this.total / this.size);
      if (r.length > 0) {
        this.solicitudes = r
        //Update status labels
        this.solicitudes.forEach((sol: any) => {

          sol.estadoLabel = this.getStatus(sol.estado);
          sol.responsableLabel = this.getUserName(sol.owner);
          sol.autor = sol.run_solicitante,
            sol.autorLabel = this.getUserName(sol.autor);
          sol.organizacion = {
            comuna: sol.comuna,
            comuna_representante: sol.comuna_representante,
            correo_administrador: sol.correo_administrador,
            correo_representante: sol.correo_representante,
            direccion: sol.direccion,
            direccion_representante: sol.direccion_representante,
            estado: sol.estado,
            fecha_creacion: sol.fecha_creacion,
            fecha_modificacion: sol.fecha_modificacion,
            fecha_resolucion_convenio: sol.fecha_resolucion_convenio,
            fecha_subscripcion_convenio: sol.fecha_subscripcion_convenio,
            id_organizacion: sol.id_organizacion,
            nombre: sol.nombre,
            nombre_administrador: sol.nombre_administrador,
            nombre_representante: sol.nombre_representante,
            numero_subscripcion_convenio: sol.numero_subscripcion_convenio,
            region: sol.region,
            region_representante: sol.region_representante,
            run: sol.run,
            run_administrador: sol.run_administrador,
            run_representante: sol.run_representante,
            telefono_administrador: sol.telefono_administrador,
            telefono_representante: sol.telefono_representante
          }
        });

        this.loading = false;
      } else {
        this.filterNotFound = true;
        this.loading = false;

      }
    });

    this.desde = 1 + (this.page - 1) * this.size;
    this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
  }

  exportGrid() {
    if (this.usrData.rol.codigo == 35) {
      this.filter.region = this.usrData.region;
    }

    this.solService.exportGrid(this.filter);
  }

  checkUpdates() {
    setInterval(()=>{
      if(this.urgentes.length > 0){
        this.solService.checkUrgentes(this.urgentes).subscribe((res:any) => {
          res.forEach((r:any) => {
            if(r.run_ciudadano != ""){
              let sol = this.solicitudes.find((s:any) => s.id == r.id);
              
              sol.run_ciudadano = r.run_ciudadano;
              this.urgentes = this.urgentes.filter((u:any) => u != r.id)
            }
          });
          
        }) 
      }

    }, 3000)
  }


  private itera: number = 0;

  updateUrgentes(surg: [any]){
    
    surg.forEach((sur:any) => {
      let sol = this.urgentes.find((s:any)=> s == sur.id);
      if(!sol){
        this.urgentes.push(sur.id);
      }
    });


  }

  loadSolicitudes() {

    this.loading = true;
    this.solicitudes = [];


    if (this.historicos) {
      this.solService.loadSolicitudesHistoricos(this.page).subscribe((solicitudes: any) => {
        sessionStorage.setItem("currentPageHistoricos", JSON.stringify(this.page));
        //Validar si hay cambios antes de refrescar
        this.solicitudes = solicitudes.data || [];
        this.total = solicitudes.total;
        this.totalPages = Math.ceil(this.total / this.size);
        this.desde = 1 + (this.page - 1) * this.size;
        this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
        //Update status labels
        if (this.solicitudes) {
          this.solicitudes.forEach((sol: any) => {
            sol.estadoLabel = this.getStatus(sol.estado);
            sol.responsableLabel = this.getUserName(sol.owner);
            sol.autorLabel = this.getUserName(sol.autor);

          });
        }


        this.loading = false;

      });
    } else {
      this.solService.loadSolicitudes(this.page).subscribe((solResponse: any) => {
        //Validar si hay cambios antes de refrescar
        sessionStorage.setItem("currentPage", JSON.stringify(this.page));
        const solicitudes = solResponse.data;
        const urgentes = solicitudes.filter((s:any) => s.tipo == 'urgente' && s.run_ciudadano == '');
        
        if(urgentes.length > 0){
          this.updateUrgentes(urgentes);
        }

        this.total = solResponse.total;
        this.totalPages = Math.ceil(this.total / this.size);
        this.desde = 1 + (this.page - 1) * this.size;
        this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);

        this.loading = false;

        
          this.solicitudes = solicitudes;

          this.solicitudes.forEach((sol: any) => {
            sol.estadoLabel = this.getStatus(sol.estado);
            sol.responsableLabel = this.getUserName(sol.owner);
            sol.autorLabel = this.getUserName(sol.autor);
          });

      });

    }

  }

  checkUserRole() {
    this.List$.subscribe(data => {

      this.usrData = data;
      switch (this.usrData.rol.codigo) {
        case 0:
          this.isAdmin = true;
          break;
        case 1:
          // this.isGestor = true;
          this.canCreate = true;
          break;
        case 2:
          this.canCreate = true;
          break;
        case 35:
          this.isAdmin = true;
          this.filter.region = this.usrData.region;


          break;
        case 40:
          this.isAdmin = true;
          break;
      }

      if (this.usrData.organizacion.estado == 0) {
        this.canCreate = false;
      }
    })

  }

  ngDoCheck(): void {
    if (this.usrData.rol.codigo == 35) {
      if (this.filter.tipo != '' || this.filter.estado != '' || this.filter.organizacion != '') {
        this.filtering = true;
      } else {
        this.filtering = false;
      }
    } else if (this.filter.tipo != '' || this.filter.estado != '' || this.filter.region != '' || this.filter.organizacion != '') {
      if (this.filter.region != '') {
        this.filter.organizacion = '';
        this.orgsSelect = this.organizaciones.filter((o: any) => {
          return o.region == this.filter.region;
        });
      } else {
        this.orgsSelect = this.organizaciones;
      }
      this.filtering = true;
    } else {
      this.orgsSelect = this.organizaciones;
      this.filtering = false;
    }
  }

  ngOnInit(): void {
    let currentPage = sessionStorage.getItem("currentPage");
    let currentPageHistoricos = sessionStorage.getItem("currentPageHistoricos");

    this.checkUserRole();
    const resp = this.settingService.loadSettings().toPromise();
    this.regiones = environment.regiones;
    resp.then((settings: any) => {

      this.settings.roles = settings.filter((s: any) => s.type == 'rol');
      this.settings.estados = settings.filter((s: any) => s.type == 'estado');
      this.settings.materias = settings.filter((s: any) => s.type == 'materia');
      this.settings.slas = settings.filter((s: any) => s.type == 'sla');

      //Carga USuarios y luego las soliciltudes
      this.loadUsers();

      this.users.forEach((u: any) => {
        u.roleName = this.getRol(u.role);
      });

    });
    this.orgServices.loadOrganizaciones().toPromise().then((orgs: any) => {
      if (this.usrData.rol.codigo == 35) {
        this.organizaciones = orgs.filter((o: any) => {
          return o.region == this.filter.region;
        });
        this.orgsSelect = this.organizaciones;
      } else {
        this.organizaciones = orgs;
        this.orgsSelect = orgs;
      }
    });

    if (this.router.url.indexOf("/solicitudes") > -1) {
      this.agendaPopup = document.querySelector('.showAgenda');
      document.onmousemove = this.handleMouseMove;
      document.querySelectorAll("#sidebar, #filters, div.actions").forEach((el: any) => {
        if (el) {
          el.onmouseenter = () => {
            this.showAgendaPopup = false
          }
        }
        sessionStorage.removeItem("currentPageHistoricos");

      })
    } else {
      this.historicos = true;
      sessionStorage.removeItem("currentPage");

    }

    if (this.historicos && currentPageHistoricos) {
      this.page = parseInt(currentPageHistoricos, 10);

    } else if (!this.historicos && currentPage) {
      this.page = parseInt(currentPage, 10);

    } else {
      this.page = 1;
    }

    this.checkUpdates();

  }

  ngOnDestroy() {
    clearInterval(this.updateFlag);

    document.removeEventListener('onmousemove', this.handleMouseMove);
  }

  newStatusChange(evt: any) {
    this.canUpdate = parseInt(evt.target.value) != parseInt(this.solicitud.estado);
    this.solicitud.estado = evt.target.value;
  }

  changeStatus(content: any, solId: any): void {

    let sol = this.solicitudes.find(s => s.id == solId);
    this.solicitud = { ...sol };

    this.triggerModal(content);
  }

  onChangeStatusSubmit() {

    this.solService.updateStatus(this.solicitud).subscribe((resp: any) => {

      this.loadSolicitudes();
      this.modalService.dismissAll();

    })
  }

  assignOwner(content: any, solId: any): void {
    let sol = this.solicitudes.find(s => s.id == solId);
    this.solicitud = { ...sol };
    this.triggerModal(content);

  }

  newOwnerChange(evt: any) {
    this.canUpdateOwner = evt.target.value != this.solicitud.owner.id;

    let usr = this.users.find((u: any) => u.id == evt.target.value);
    this.solicitud.owner = usr;

    //this.solicitud.responsableLabel = this.getUserName(evt.target.value);

  }

  showAgenda(evt: any, id: any) {
    if (this.historicos) {
      return;
    }
    let sol = this.solicitudes.find((sol: any) => sol.id == id);
    if (sol.fecha_voucher) {

      let elm = evt.target;
      let pos = elm.getBoundingClientRect();
      this.showAgendaPopup = true;
      let ag = this.agendaPopup;

      this.agendaFecha = sol.fecha_voucher;
      this.agendaHora = sol.hora_voucher;
      this.agendaDireccion = sol.direccion_voucher;

      ag.style.top = parseInt(evt.clientY) - 150 + 'px';
      ag.style.left = parseInt(evt.clientX) - 80 + 'px';
    }
  }

  movePopup(evt: any) {
    let ag = this.agendaPopup;
    evt.stopPropagation();
    evt.preventDefault();
    ag.style.top = (evt.clientY - 150) + 'px';
    ag.style.left = (evt.clientX - 150) + 'px';
  }

  handleMouseMove(event: any) {

    let elm = event.target;
    let pos = elm.getBoundingClientRect();
    //console.log(elm.getBoundingClientRect());

    var eventDoc, doc, body;

    event = event || window.event; // IE-ism

    // If pageX/Y aren't available and clientX/Y are,
    // calculate pageX/Y - logic taken from jQuery.
    // (This is to support old IE)
    if (event.pageX == null && event.clientX != null) {
      eventDoc = (event.target && event.target.ownerDocument) || document;
      doc = eventDoc.documentElement;
      body = eventDoc.body;

      elm.X = event.clientX +
        (doc && doc.scrollLeft || body && body.scrollLeft || 0) -
        (doc && doc.clientLeft || body && body.clientLeft || 0);
      elm.Y = event.clientY +
        (doc && doc.scrollTop || body && body.scrollTop || 0) -
        (doc && doc.clientTop || body && body.clientTop || 0);
    }

    // Use event.pageX / event.pageY here
    this.posLeft = pos.x + 'px';
    this.posTop = pos.y + 'px';

    // console.log(this.posTop, this.posLeft);
  }

  onAssignOwnerSubmit() {
    this.solService.updateOwner(this.solicitud).subscribe((resp: any) => {

      this.loadSolicitudes();
      this.modalService.dismissAll();

    })
  }
}
