import { Component, OnInit, ElementRef } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { OrganizacionesService } from '../shared/organizaciones.service';
import { LanguageApp } from '../shared/dataTableLang';
import { SettingsService } from '../shared/settings.service';
import { UsersService } from '../shared/users.service'
import {
  allOrganizaciones,
} from '../store/organizaciones.selector';
import { OrganizacionModel } from './organizaciones.model';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { RutserviceService } from '../rutservice.service';
import { Router } from '@angular/router';
import { validate, format } from 'rut.js';

@Component({
  selector: 'app-organizacion',
  templateUrl: './organizacion.component.html',
  styleUrls: ['./organizaciones.component.css']
})

export class OrganizacionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private dom: ElementRef,
    private orgService: OrganizacionesService,
    private settingService: SettingsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private rutval: RutserviceService,
    private router: Router
  ) { this.validatorRut = rutval }
  private validatorRut: RutserviceService;

  doc!: any;
  id_organizacion!: any;
  organizaciones!: any[];
  organizacion: any = null;
  nuevaOrg = {
    orgName: '',
    orgRun: '',
    orgAddress: '',
    orgComuna: '',
    orgRegion: '',
    repFirstName: '',
    repLastName: '',
    repRun: '',
    repAddress: '',
    repComuna: '',
    repRegion: '',
    admFirstName: '',
    admLastName: '',
    admRun: '',
    correo_representante: '',
    confirEmail_rep: '',
    telefono_representante: '',
    correo_administrador: '',
    confirEmail_adm: '',
    telefono_administrador: '',
    fecha_subscripcion_convenio: '',
    fecha_resolucion_convenio: '',
    numero_subscripcion_convenio: '',
  };
  editForm: boolean = false;
  regiones: any = [{ codigo_region: -1, nombre_region: 'Seleccione Región', comunas: [] }];
  comunas = [{ codigo_comuna: -1, nombre_comuna: 'Seleccione Comuna' }];
  region: any = {
    codigo_region: -1,
    comunas: []
  };
  comuna: any = {};

  uiMsg = 'Mensaje de prueba';
  showMsg = false;
  newStatus: any;
  canUpdate: boolean = false;
  canUpdateOwner: boolean = false;
  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: []
  }
  users: any = [];

  changeStatusForm!: FormGroup;
  assignOwnerForm!: FormGroup;


  dialogBloqueo(modal: any) {
    this.triggerModal(modal);
  }

  dialogDesbloqueo(modal: any) {
    this.triggerModal(modal);
  }

  bloquearIngreso() {

    const updOrg: any = {
      id_organizacion: this.organizacion.id_organizacion,
      estado: 0
    }

    this.orgService.updateOrganizacion(updOrg).subscribe((res: any) => {
      window.location.reload();
    });

  }

  desbloquearIngreso() {

    const updOrg: any = {
      id_organizacion: this.organizacion.id_organizacion,
      estado: 1
    }
    this.orgService.updateOrganizacion(updOrg).subscribe((res: any) => {
      window.location.reload();
    });

  }

  closeModal() {
    this.modalService.dismissAll();
  }

  triggerModal(content: any) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title' }).result.then((res) => {
     
    }, (res) => {
     
    });
  }

  changeRegion() {
    const filterComunas: any = this.regiones.find((r: any) => r.codigo_region == parseInt(this.organizacion.region));
    this.comunas = filterComunas["comunas"];
  }

  loadRegiones() {
    this.regiones = environment.regiones;
    this.region = this.regiones.find((reg: any) => reg.codigo_region == this.organizacion.region);
    this.comunas = this.region.comunas;

    this.comuna = this.region.comunas.find((com: any) => com.codigo_comuna == this.organizacion.comuna);

    let reg = this.regiones.find((r: any) => {
      return r.codigo_region == this.organizacion.region;
    })
    let com = reg.comunas.find((c: any) => {
      return c.codigo_comuna == this.organizacion.comuna;
    })
    this.organizacion.region_label = reg.nombre_region;
    this.organizacion.comuna_label = com.nombre_comuna;

    //this.organizacion.region_label;
  }

  loadOrganizaciones() {
    let that = this;
    this.orgService.loadOrganizaciones().subscribe((organizaciones: any) => {
      this.organizaciones = organizaciones;
      this.organizacion = this.organizaciones.find((o: any) => o.id_organizacion == this.id_organizacion)
     
      $('#dataTable').DataTable().destroy();

      setTimeout(function () {
        $('#dataTable').DataTable({
          pagingType: 'full_numbers',
          pageLength: 5,
          processing: true,
          lengthMenu: [3, 5, 10],
          language: LanguageApp.spanish_datatables
        });
        that.loadRegiones();
      }, 0);

    });
  }


  valRut(evt: Event, context: string) {
    let el: any = evt.target;
    if (el.value.trim() == '') {
      this.uiMsg = "Debe completar el campo Run "
      this.showMsg = true;
      el.focus();
    }
    else {
      if (validate(el.value.trim())) {
        el.value = format(el.value, { dots: false });
      }
      if (!validate(el.value.trim())) {
        this.uiMsg = "Debe ingresar un RUN válido"
        this.showMsg = true;
        el.focus();
        document.addEventListener('click', this.cancelClickEvent);
      } else {
        this.uiMsg = ""
        this.showMsg = false;
        if (context === "orgRun") this.organizacion.run = el.value;
        if (context === "repRun") this.organizacion.run_representante = el.value;
        if (context === "admRun") this.organizacion.run_administrador = el.value;

      }
    }
  }


  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.id_organizacion = params.get('id_organizacion');
      sessionStorage.idOrganizacion = this.id_organizacion;
    })
    this.doc = this.dom.nativeElement;
    this.loadOrganizaciones();

    let fS = document.querySelector("#fecha_sub");
    let fC = document.querySelector("#fecha_conv");



  }

  crearOrganizacion(content: any) {
    this.triggerModal(content);
  }

  showMessage(txt: string) {
    this.uiMsg = txt;
    this.showMsg = true;
  }

  closeMsg() {
    this.showMsg = false;
    this.uiMsg = '';
  }

  validateField(evt: any) {
    let fld: HTMLInputElement = evt.target;
    if (fld.value.trim() == '') {
      this.uiMsg = "Debe completar el campo " + fld.getAttribute("placeholder")
      this.showMsg = true;
      fld.focus();
    } else {
      this.uiMsg = "";
      this.showMsg = false;
    }
  }
  validateRut(evt: any) {
    let fld: HTMLInputElement = evt.target;
    if (fld.value.trim() == '') {
      this.uiMsg = "Debe completar el campo Run "
      this.showMsg = true;
      fld.focus();
    } else {
      if (!this.rutValidator(fld.value.trim())) {
        this.uiMsg = "El dato ingresado no es un RUN válido."
        this.showMsg = true;
        fld.focus();
      } else {
        this.uiMsg = "";
        this.showMsg = false;

      }
    }
  }
  rutValidator(rutCompleto: string) {
    function dv(T: any): any {
      var M = 0, S = 1;
      for (; T; T = Math.floor(T / 10))
        S = (S + T % 10 * (9 - M++ % 6)) % 11;
      return S ? S - 1 : 'k';
    }

    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto))
      return false;
    var tmp = rutCompleto.split('-');
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == 'K') digv = 'k';
    return (dv(rut) == digv);
  }


  validaEditOrg(o: OrganizacionModel) {
    if (o.run == '' || o.nombre == '' || o.direccion == '' ||
      o.comuna == '' || o.region == '' || o.run_representante == '' ||
      o.run_administrador == '' || o.numero_subscripcion_convenio == '' ||
      o.correo_administrador == '' || o.correo_representante == '' ||
      o.telefono_administrador == '' || o.telefono_representante == '' ||
      o.fecha_resolucion_convenio == '' || o.fecha_subscripcion_convenio == '') {
      this.showMessage("Debe completar los campos requeridos");
      return false;
    } else {

      if (this.checkDates()) {

        //Validar Org Existente      
        let fOrg = this.organizaciones.find(o => o.run == this.nuevaOrg.orgRun);

        if (fOrg) {
          this.showMessage("Ya existe un convenio para este rut. Valide la información e intente nuevamente.");
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  checkDates() {
    let fS: any = document.querySelector("#fecha_sub");
    let fC: any = document.querySelector("#fecha_conv");

    let FS = new Date(fS.value);
    let FC = new Date(fC.value);
    let ret = FS < FC;
    if (FS && FC && !ret) {

      this.showMessage("La fecha de Resolución no puede ser menor a la fecha de Subscripción");
    } else {

      this.closeMsg();
    }
    return ret;
  }

  editarOrganizacionSubmit(evt: Event) {
    evt.preventDefault();
    evt.stopPropagation();
    const newOrg: OrganizacionModel = {
      id_organizacion: this.organizacion.id_organizacion,
      run: this.organizacion.run,
      nombre: this.organizacion.nombre,
      direccion: this.organizacion.direccion,
      comuna: this.organizacion.comuna,
      region: this.organizacion.orgRegion,
      run_representante: this.organizacion.run_representante,
      nombre_representante: this.organizacion.nombre_representante,
      apellido_representante: this.organizacion.apellido_representante,
      direccion_representante: this.organizacion.direccion_representante,
      comuna_representante: this.organizacion.comuna_representante,
      region_representante: this.organizacion.region_representante,
      correo_representante: this.organizacion.correo_representante,
      telefono_representante: this.organizacion.telefono_representante,
      run_administrador: this.organizacion.run_administrador,
      nombre_administrador: this.organizacion.nombre_administrador,
      apellido_administrador: this.organizacion.apellido_administrador,
      correo_administrador: this.organizacion.correo_administrador,
      telefono_administrador: this.organizacion.telefono_administrador,
      fecha_subscripcion_convenio: this.organizacion.fecha_subscripcion_convenio,
      fecha_resolucion_convenio: this.organizacion.fecha_resolucion_convenio,
      numero_subscripcion_convenio: this.organizacion.numero_subscripcion_convenio
    }

    if (this.validaEditOrg(newOrg)) {
      this.orgService.updateOrganizacion(newOrg).subscribe((resp: any) => {
        this.router.navigateByUrl("/organizaciones");
      });
    }
  }

  confirEmail_rep = (evt: any) => {
    let repEmail: HTMLInputElement = evt.target
    if (repEmail.value === '') {
      this.uiMsg = "Debe completar el campo"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else if (repEmail.value.toLowerCase().trim() != this.organizacion.correo_representante.toLowerCase().trim()) {
      this.uiMsg = "Los correos deben coincidir"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else {
      this.uiMsg = "";
      this.showMsg = false;
      document.removeEventListener('click', this.cancelClickEvent);
    }
  }
  cancelClickEvent = (event: Event) => {
    if (this.showMsg) {
      event.preventDefault();
    }
  }

  confirEmail_adm = (evt: any) => {
    let admEmail: HTMLInputElement = evt.target
    if (admEmail.value === '') {
      this.uiMsg = "Debe completar el campo"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    }
    if (admEmail.value.toLowerCase().trim() != this.organizacion.correo_administrador.toLowerCase().trim()) {
      this.uiMsg = "Los correos deben coincidir"
      this.showMsg = true;
      document.addEventListener('click', this.cancelClickEvent);
    } else {
      this.uiMsg = "";
      this.showMsg = false;
      document.addEventListener('click', this.cancelClickEvent);
    }
  }

}