<div id="voucher" *ngIf="tramite">
  <!--  Log + Titulo + CodigoQR -->
  <div class="row">
    <div class="col-3">
      <img class="logoBW" src="/assets/logo_regcivil_bw.png" alt="">
    </div>
    <div class="col-6 text-left pt-4">
      <h3>Solicitud de RUN para personas extranjeras</h3>
      <h5>(Artículo 44 Ley 21.325)</h5>
    </div>
    <div class="col-3 qrContainer">
      <ngx-qrcode [value]="qrData" [width]="200" cssClass="qrCode">
      </ngx-qrcode>
      <div class="fImp"><strong>Emisión</strong>: {{printTimeStamp}}</div>
    </div>
  </div>
  <!-- URGENTE -->
  <div class='text-center text-danger' *ngIf="tramite.solicitud.tipo == 'urgente'">
    <h4>* URGENTE *</h4>
  </div>
  <div class="row data">
    <div class="col col-2"><strong>N° Solicitud</strong></div>
    <div class="col col-3 text-center">
      <h4>{{voucher}}</h4>
    </div>
    <div class="col col-2"></div>
    <div class="col col-2"><strong>Fecha Solicitud</strong></div>
    <div class="col col-3">{{tramite.solicitud.fecha_creacion}}</div>

  </div>

  <!-- DATOS ORGANIZACION -->
  <div class="row data">
    <div class="col col-3"><strong>Entidad Solicitante</strong></div>
    <div class="col col-5">{{tramite.organizacion ? tramite.organizacion.nombre : ""}}</div>
    <div class="col col-2"><strong>RUT</strong></div>
    <div class="col col-2">{{tramite.organizacion ? tramite.organizacion.run : ""}}</div>
  </div>

  <!-- DATOS DEL EXTRANJERO -->
  <h5><strong>Datos del extranjero</strong></h5>
  <div class="row data ">
    <div class="col col-12 no-borders">
      <div class="row">
        <div class="col col-md-3"><strong>Apellido 1</strong></div>
        <div class="col col-md-3">{{tramite.paterno}}</div>
        <div class="col col-md-3"><strong>Apellido 2</strong> </div>
        <div class="col col-md-3">{{tramite.materno}}</div>
      </div>
      <div class="row">
        <div class="col col-md-3"><strong>Nombres</strong></div>
        <div class="col col-md-9">{{tramite.nombre}}</div>
      </div>

      <div class="row">
        <div class="col col-md-3"><strong>Fecha Nacimiento</strong></div>
        <div class="col col-md-3">{{tramite.fecha_nac}}</div>
        <div class="col col-md-3"><strong>Sexo</strong></div>
        <div class="col col-md-3">{{tramite.sexo}}</div>
      </div>
      <div class="row">
        <div class="col col-md-3"><strong>País de Origen</strong></div>
        <div class="col col-md-3">{{tramite.nacionalidad}}</div>
        <div class="col col-md-3"><strong>Email</strong></div>
        <div class="col col-md-3">{{tramite.email}}</div>
      </div>

      <div class="row" *ngIf="tramite.solicitud.tipo == 'urgente'">
        <div class="col col-md-3"><strong>Fono</strong></div>
        <div class="col col-md-3">{{tramite.telefono || 'SIN TELÉFONO'}}</div>
        <div class="col col-md-3"><strong>Dirección</strong></div>
        <div class="col col-md-3">{{tramite.direccion_atencion}}</div>
      </div>
    </div>
  </div>

  <!-- INDICACIONES -->
  <div class="row" *ngIf="tramite.solicitud.tipo == 'normal'; else indUrgente">
    <div class='col-md-12 my-2 text-justify'><strong>Indicaciones:</strong>

      Usted debe presentarse entre las <strong> {{ horaInicio}} y las {{ horaFin }} horas</strong> en una oficina habilitada del Servicio de Registro Civil e Identificación, para revisar las oficinas revise aquí:  <strong>{{ url_lista_oficinas }}</strong>
      <br />

      <br />El RUN le permitirá interactuar ante la Administración del Estado, instituciones u organismos previsionales
      o de salud y establecimientos de educación públicos o privados.

      <br /><br />
      Si usted no concurre a realizar el enrolamiento dentro de un plazo de {{plazo_presentacion}} días hábiles, se dejará sin efecto la
      solicitud, situación que será comunicada al organismo solicitante.
      <br /><br />

      El otorgamiento de este RUN no exime al extranjero/a del deber de iniciar los procedimientos necesarios ante la
      autoridad migratoria, de acuerdo con lo dispuesto en Decreto N°106 de la Ley Nº 21.325.


      <h3 class="m-3 text-center">ESTA SOLICITUD NO IMPLICA LA ENTREGA DE UN DOCUMENTO CÉDULA DE IDENTIDAD PARA
        EXTRANJERO</h3>

    </div>
    <div class='col-md-12 mt-2 text-center'>
      <strong>
        Este comprobante debe ser presentado al momento de la atención en la oficina del Servicio de Registro Civil e
        Identificación.
      </strong>
    </div>
  </div>


  <ng-template #indUrgente>
    <div class="row">
      <p><strong>Indicaciones:</strong> Dentro de 10 días hábiles (entre 08:30 y 17:00 horas) usted será contactado por
        el equipo de Atención en Terreno del Servicio de Registro Civil e Identificación, con el fin de coordinar día y
        hora que concurrirán a la dirección indicada en este comprobante para realizar el enrolamiento que permitirá
        confirmar el RUN asignado o informar el existente.
      </p>
      <p>
        Si por causas imputables al extranjero/a no fuere posible completar el enrolamiento, se dejará sin efecto el RUN
        asignado, situación que será comunicada al organismo solicitante.
      </p>
      <p>
        El otorgamiento de este RUN no exime al extranjero/a del deber de iniciar los procedimientos necesarios ante la
        autoridad migratoria, de acuerdo con lo dispuesto en Decreto N°106 de la Ley Nº 21.325.
      </p>

      <h3 class="m-5 text-center">ESTA SOLICITUD NO IMPLICA LA ENTREGA DE UN DOCUMENTO CÉDULA DE IDENTIDAD PARA
        EXTRANJERO</h3>

      <div class='col-md-12 my-2 text-center'>
        <strong>
          Este comprobante debe ser presentado al momento de la atención en la oficina del Servicio de Registro Civil e
          Identificación.
        </strong>
      </div>
    </div>
  </ng-template>

  <!-- ACCIONES -->
  <div class="actions no-print">
    <a class="btn btn-danger btn-sm  no-print" (click)="closeVoucher()">Cerrar</a>
    <button class="btn btn-success btn-sm no-print" (click)="printVoucher()">Imprimir</button>
  </div>
</div>