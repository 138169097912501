<div class="passwordContainer">
  <div class="passwordPanel">
    <img src="assets/logo-registro-civil-300-jpg.jpg?timestamp=20180610163906" alt="Registro Civil" />
    <h3>Bienvenido</h3>
    <blockquote>Ingrese una contraseña para comenzar a usar el sistema</blockquote>
    <form>
      <label for="password">Password</label>
      <input type="password" name="password" placeholder="Contraseña" [(ngModel)]="password">
      <input type="password" name="passwordcheck" class="mt-2" [(ngModel)]="passwordcheck"
        placeholder="Reingrese su Contraseña">
      <button (click)="updatePassword($event)" [ngClass]="{disabled: !pwdOk }"
        class="btn btn-sm btn-primary">Actualizar</button>

    </form>
    <div class="warning" *ngIf="passwordMsg!=''">
      {{passwordMsg}}
    </div>
  </div>
</div>