import { IUserDataState } from './../../store/userdata/userdata.reducer';
import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { selectUserData } from 'src/app/store/userdata/userdata.selector';
import userdata from 'src/app/store/userdata/userdata.interface';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {
  userData: any = { username: '' };
  appTitle: string = environment.appTitle;

  showActions: boolean = false;
  public List$: Observable<userdata>;

  constructor(private store: Store<IUserDataState>, private router: Router) {
    this.List$ = this.store.pipe(select(selectUserData))

  }


  closeToolbar() {
    this.showActions = false;
  }
  toolbarActions() {

    this.showActions = !this.showActions;
  }
  doLogout(): void {
    sessionStorage.removeItem("boData");
    sessionStorage.removeItem("boLogin");
    sessionStorage.removeItem("boToken");
    sessionStorage.removeItem("boOpt");
    sessionStorage.removeItem("usrData");
    sessionStorage.removeItem("currentPage");
    sessionStorage.removeItem("currentPageHistoricos");
    this.router.navigate(["/inicio"]);
  }

  ngOnInit(): void {
    let userData = sessionStorage.getItem('boData');

    this.userData = JSON.parse(userData || "{}");

    this.List$.subscribe((usrData: any) => {
      this.userData = usrData;

    })


  }
}
