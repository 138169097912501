<div class="overlay" [ngClass]="{show: showOverlay}">
    <img src="assets/loading.gif" alt="Login....">
    Ingresando
</div>
<div class='container'>
    <div class='toolbar'>
        <a routerLink="/" routerLinkActive="active">
            <img alt="Logo" src="/assets/logo_regcivil.png" />
        </a>
        <div class='text-center'>
            <h3>Solicitudes de RUN para extranjeros</h3>
            <blockquote>(Artículo 44 Ley 21.325)</blockquote>
        </div>
    </div>
    <div class='content'>
        <div>
            Ingreso de Organizaciones para la aplicación de solicitud de RUN para extranjeros. Utilice sus credenciales
            para ingresar al sistema.
            <div class='login-cu'>
                <a class="btn btn-sm btn-primary mt-3" href='/login'>
                    Login
                    <!-- <img src="assets/claveunica.png" alt="Acceso con Clave Única" /> -->
                </a>
                <br />
                <!-- <a href="/login" class="btn btn-primary">Login</a> -->
            </div>
            <div class="error-msg mt-2 alert-warning text-center">
                Credenciales Inválidas
            </div>
        </div>
        <div class="landing-img">
            <img src="assets/landing_img.png" alt="Angular App">
        </div>
    </div>
    <div class='footer'>
        Servicio de Registro Civil e Identificación @2021
    </div>
</div>