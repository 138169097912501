import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login, SetPassword } from '../shared/login';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LoginService {
  apiURL = environment.boBackendAPI;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  /**
   * 
   * 'client_id' => 'e3e84c62a62b45cba01cbc8326665a50',
    'client_secret' => '2499064d941949c485626769586f3e09',
    'redirect_uri' => 'https%3A%2F%2Fsolicitarunqa.registrocivil.cl',
    'grant_type' => 'authorization_code',
    'code' => $_GET["code"] ,
    'state' => $_GET["state"]

   */
  LoginClaveUnica(cuArr: any): any {
    let { code, state } = cuArr;
    let loginCU = 'https://accounts.claveunica.gob.cl/openid/token/';
    let loginCUBody = `client_id=${environment.claveUnica_client_id}` +
      `&client_secret=${environment.claveUnica_client_secret}` +
      `&redirect_uri=${encodeURIComponent(environment.claveUnica_redirect_uri)}` +
      `&grant_type=authorization_code` +
      `&code=${code}` +
      `&state=${state}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'content-type': 'application/x-www-form-urlencoded;charset=UTF-8'
      })
    };

    fetch(loginCU, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
      },
      body: loginCUBody
    }).then((d: any) => d.json()).then((r: any) => {

      let dataClaveUnica = {
        tt: 'cu',
        tk: r.access_token, // || 'd32b2c39392243d29071a568f36adda6'
      }

      let headers = new HttpHeaders();
      headers = headers.set('Content-Type', 'application/json; charset=utf-8');

      this.http.post<any>(this.apiURL + '/auth/logincu', JSON.stringify(dataClaveUnica), { headers: headers })
        .subscribe((data: any) => {

          let resPath = 'error';
          if (data && data.accessToken) {
            sessionStorage.setItem("boCUat", r.access_token);
            sessionStorage.setItem("boData", JSON.stringify(data))
            sessionStorage.setItem("boToken", data.accessToken);
            sessionStorage.setItem("boOpt", data.opt);
            if (data.userStatus == 0) {

              sessionStorage.setItem("boLogin", 'true');
              this.router.navigate(["/setpassword"]);
              resPath = 'setpassword'
            } else {

              sessionStorage.setItem("boLogin", 'true');
              this.router.navigate(["/home"]);
              resPath = 'home'
            }

          } else {

          }
          return resPath;
        },

          err => {
            let ovl = document.querySelector(".overlay");
            let msg = document.querySelector(".error-msg");
            ovl?.classList.remove('show');
            msg?.classList.add('show');
          })
    })
  }

  ServerStatus(): any {
    return this.http.get(this.apiURL + '/status')
  }

  UserLogin(login: any): any {
    let resp: any;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    resp = this.http.post<Login>(this.apiURL + '/auth/login', JSON.stringify(login), { headers: headers });

    return resp;
  }

  SetPassword(userData: any): any {
    let resp: any;
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/json; charset=utf-8').set('x-token', userData.token);
    resp = this.http.put<SetPassword>(`${this.apiURL}/auth/setpassword/${userData.username}`, JSON.stringify(userData), { headers: headers });
    return resp;
  }

  handleError(error: any): any {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }
}
