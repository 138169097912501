import { Component, OnInit } from '@angular/core';
import { SolicitudesService } from '../shared/solicitudes.service';
import { LanguageApp } from '../shared/dataTableLang';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsService } from '../shared/settings.service';
import { UsersService } from '../shared/users.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import {
  allSolicitudes,
} from '../store/solicitudes.selector';

import { SolicitudModel } from '../solicitudes/solicitud.model';
import { OrganizacionesService } from '../shared/organizaciones.service';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import userdata from '../store/userdata/userdata.interface';
import { IUserDataState } from '../store/userdata/userdata.reducer';
import { selectUserData } from '../store/userdata/userdata.selector';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-urgencias',
  templateUrl: './urgencias.component.html',
  styleUrls: ['./urgencias.component.css']
})
export class UrgenciasComponent implements OnInit {
  loading: boolean = true;
  solicitudes!: any[];
  solicitud: any = null;
  newStatus: any;
  closeModal: string = "";
  canUpdate: boolean = false;
  canUpdateOwner: boolean = false;
  usrData: any = {};

  updateFlag: any = null;

  isAdmin: boolean = false;
  filterNotFound = false;
  filtering: boolean = false;
  filter: any = {
    tipo: 'urgente',
    estado: '',
    region: '',
    organizacion: ''
  };
  organizaciones: any = [];
  regiones: any = [];
  region: any = {};
  settings: any = {
    roles: [],
    estados: [],
    materias: [],
    slas: []
  }
  users: any = [];
  public List$: Observable<userdata>
  constructor(
    private solService: SolicitudesService,
    private settingService: SettingsService,
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private orgServices: OrganizacionesService,
    private store: Store<IUserDataState>
  ) {

    this.List$ = this.store.pipe(select(selectUserData))
  }

  changeStatusForm!: FormGroup;
  assignOwnerForm!: FormGroup;

  grilla: any;
  searchTerm: string = '';
  searchResults: any = []

  total: number = 0;
  page: number = 1;
  size: number = 10;
  desde: number = 1 + (this.page - 1) * this.size;
  hasta: number = this.page * this.size;
  totalPages: number = Math.ceil(this.total / this.size);


  prevPage() {
    this.page = this.page > 1 ? (this.page - 1) : 1;

    if (this.searchTerm != "") {
      this.globalSearch();

    } else if (this.filter.tipo == '' && this.filter.estado == '' && this.filter.region == '' && this.filter.organizacion == '') {
      this.loadSolicitudes();

    } else {
      this.filterGrid();
    }
  }

  nextPage() {

    this.page = this.page < this.totalPages ? (this.page + 1) : this.totalPages;

    if (this.searchTerm != "") {
      this.globalSearch();

    } else if (this.filter.tipo == '' && this.filter.estado == '' && this.filter.region == '' && this.filter.organizacion == '') {
      this.loadSolicitudes();

    } else {
      this.filterGrid();
    }
  }

  printSolicitud() {
    window.print();
  }

  globalSearch() {
    this.loading = true;
    this.solicitudes = [];
    this.filterNotFound = false;

    this.solService.globalSearch(this.searchTerm, this.page).subscribe((resp: any) => {
      sessionStorage.setItem('term', this.searchTerm);
      const r = resp.data;

      this.total = resp.total;

      this.desde = 1 + (this.page - 1) * this.size;
      this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
      this.totalPages = Math.ceil(this.total / this.size);
      if (r.length > 0) {
        this.solicitudes = r

        //Update status labels
        this.solicitudes.forEach((sol: any) => {

          sol.estadoLabel = this.getStatus(sol.estado);
          sol.responsableLabel = this.getUserName(sol.owner);
          sol.autor = sol.run_solicitante,
            sol.autorLabel = this.getUserName(sol.autor);
          sol.organizacion = {
            comuna: sol.comuna,
            comuna_representante: sol.comuna_representante,
            correo_administrador: sol.correo_administrador,
            correo_representante: sol.correo_representante,
            direccion: sol.direccion,
            direccion_representante: sol.direccion_representante,
            estado: sol.estado,
            fecha_creacion: sol.fecha_creacion,
            fecha_modificacion: sol.fecha_modificacion,
            fecha_resolucion_convenio: sol.fecha_resolucion_convenio,
            fecha_subscripcion_convenio: sol.fecha_subscripcion_convenio,
            id_organizacion: sol.id_organizacion,
            nombre: sol.nombre,
            nombre_administrador: sol.nombre_administrador,
            nombre_representante: sol.nombre_representante,
            numero_subscripcion_convenio: sol.numero_subscripcion_convenio,
            region: sol.region,
            region_representante: sol.region_representante,
            run: sol.run,
            run_administrador: sol.run_administrador,
            run_representante: sol.run_representante,
            telefono_administrador: sol.telefono_administrador,
            telefono_representante: sol.telefono_representante
          }
        });

        this.loading = false;
      } else {
        this.filterNotFound = true;
        this.loading = false;

      }
      this.searchResults = r;
    });
  }

  searchSolicitud(evt: any) {
    this.searchResults = [];
    if (this.searchTerm == '') {
      this.searchTerm = '';
      sessionStorage.setItem('term', '');
    }
    if (evt.type === 'keyup' && evt.key === 'Enter') {
      this.cleanFilters();
      this.globalSearch();
    } else if (evt.type === 'click') {
      this.globalSearch()
    }
  }

  clearSearch() {
    this.searchTerm = '';
    sessionStorage.setItem('term', '');
    this.cleanFilters();
  }

  cleanFilters() {
    this.filter.estado = '';
    this.filter.region = '';
    this.filter.organizacion = '';
    sessionStorage.setItem("filter", JSON.stringify(this.filter))
  }

  filterGrid() {

    this.loading = true;
    this.solicitudes = [];
    this.filterNotFound = false;
    this.solService.filterGridUrgencias(this.filter, this.page).subscribe((resp: any) => {
      const r = resp.data;

      this.total = resp.total;
      this.desde = 1 + (this.page - 1) * this.size;
      this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
      this.totalPages = Math.ceil(this.total / this.size);

      if (r.length > 0) {
        this.solicitudes = r

        //Update status labels
        this.solicitudes.forEach((sol: any) => {

          sol.estadoLabel = this.getStatus(sol.estado);
          sol.responsableLabel = this.getUserName(sol.owner);
          sol.autor = sol.run_solicitante,
            sol.autorLabel = this.getUserName(sol.autor);
          sol.organizacion = {
            comuna: sol.comuna,
            comuna_representante: sol.comuna_representante,
            correo_administrador: sol.correo_administrador,
            correo_representante: sol.correo_representante,
            direccion: sol.direccion,
            direccion_representante: sol.direccion_representante,
            estado: sol.estado,
            fecha_creacion: sol.fecha_creacion,
            fecha_modificacion: sol.fecha_modificacion,
            fecha_resolucion_convenio: sol.fecha_resolucion_convenio,
            fecha_subscripcion_convenio: sol.fecha_subscripcion_convenio,
            id_organizacion: sol.id_organizacion,
            nombre: sol.nombre,
            nombre_administrador: sol.nombre_administrador,
            nombre_representante: sol.nombre_representante,
            numero_subscripcion_convenio: sol.numero_subscripcion_convenio,
            region: sol.region,
            region_representante: sol.region_representante,
            run: sol.run,
            run_administrador: sol.run_administrador,
            run_representante: sol.run_representante,
            telefono_administrador: sol.telefono_administrador,
            telefono_representante: sol.telefono_representante
          }
        });
        this.loading = false;

      } else {
        this.filterNotFound = true;
        this.loading = false;

      }
    });

    this.desde = 1 + (this.page - 1) * this.size;
    this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);
  }


  triggerModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((res) => { }, (res) => {
      //Reset Values
      this.solicitud = null;
      this.canUpdate = false;
      this.canUpdateOwner = false;
    });
  }

  getStatus(status: any) {
    const sts = this.settings.estados.find((e: any) => e.codigo == status);
    return sts.glosa;
  }

  getRol(rol: number) {
    let rolObj = this.settings.find((r: any) => r.type == 'rol' && r.codigo == rol);
    if (rolObj) {
      return rolObj.glosa;
    }
  }

  getUserName(username: any) {
    if (username) {
      let usr = this.users.find((u: any) => u.username == username);

      if (usr) {
        return usr.firstName || '' + " " + usr.lastName;
      } else {
        return "Sin Asignar";

      }
    } else {
      return "Sin Asignar";
    }
  }

  loadUsers() {
    this.usersService.loadUsers().subscribe((users: any) => {
      this.users = users;
      this.users.sort((a: any, b: any) => a.role > b.role ? 1 : -1);

      this.loadSolicitudes();
    });
  }

  checkUpdates(newSols: any) {


    let resp = false;
    if (!this.solicitudes || this.solicitudes.length == 0 || this.solicitudes.length != newSols.length) {
      resp = true;
    } else {
      newSols.forEach((ns: any) => {
        let fSol = this.solicitudes.find(s => (s.id == ns.id) &&
          (s.estado != ns.estado || s.run_ciudadano != ns.run_ciudadano));
        if (fSol) {
          resp = true;
        }
      });
    }

    return resp;
  }

  loadSolicitudes() {
    this.loading = true;
    this.filtering = false;
    this.solicitudes = [];

    this.solService.loadUrgencias(this.page).subscribe((solResponse: any) => {

      const solicitudes = solResponse.data || [];
      this.total = solResponse.total;

      this.totalPages = Math.ceil(this.total / this.size);

      this.desde = 1 + (this.page - 1) * this.size;
      this.hasta = (this.page * this.size) > this.total ? this.total : (this.page * this.size);

      this.loading = false;

      if (this.checkUpdates(solicitudes)) {
        this.solicitudes = solicitudes;
        //Update status labels
        if (this.solicitudes) {
          this.solicitudes.forEach((sol: any) => {
            sol.estadoLabel = this.getStatus(sol.estado);
            sol.responsableLabel = this.getUserName(sol.owner);
            sol.autorLabel = this.getUserName(sol.autor);

          });
        }

        this.solicitudes = this.solicitudes.sort((a, b) => a.run_ciudadano > b.run_ciudadano ? 1 : -1)


      }
    });
  }

  checkUserRole() {
    this.List$.subscribe(data => {

      this.usrData = data;
      if (this.usrData.rol.codigo == 0) {
        this.isAdmin = true;
      }
      let region = environment.regiones.find((r: any) => r.codigo_region == this.usrData.region);
      this.region = region;
    })


  }

  ngOnInit(): void {
    this.checkUserRole();
    const resp = this.settingService.loadSettings().toPromise();
    this.regiones = environment.regiones;
    resp.then((settings: any) => {

      this.settings.roles = settings.filter((s: any) => s.type == 'rol');
      this.settings.estados = settings.filter((s: any) => s.type == 'estado');
      this.settings.materias = settings.filter((s: any) => s.type == 'materia');
      this.settings.slas = settings.filter((s: any) => s.type == 'sla');

      //Carga USuarios y luego las soliciltudes
      this.loadUsers();

      this.users.forEach((u: any) => {
        u.roleName = this.getRol(u.role);
      });

    });

    this.orgServices.loadOrganizaciones().toPromise().then((orgs: any) => {
      this.organizaciones = orgs;
    });

  }

  ngOnDestroy() {
    clearInterval(this.updateFlag);
  }

  ngDoCheck(): void {
    if (this.filter.estado !== '' || this.filter.region !== '' || this.filter.organizacion !== '') {
      this.filtering = true
    }

  }

  exportGrid() {
    if (this.usrData.rol.codigo == 35) {
      this.filter.region = this.usrData.region;
    }

    this.solService.exportGrid(this.filter);
  }
}
