<button class="btn btn-sm no-print" style='float: right;' (click)="exportGrid()"><i class="bi bi-box-arrow-down"></i>
  Descarga archivo
</button>
<div class="title">
  <h3>Solicitudes Urgentes</h3>
  <blockquote *ngIf="this.usrData.rol.codigo == 30">
    Para atención en terreno
    <span>{{region.nombre_region}}</span>
  </blockquote>
</div>
<!-- BUSCADOR -->
<div *ngIf="isAdmin" class="no-print">
  <h5>Buscar Solicitudes</h5>
  Utilice el buscador para encontrar una solicitud por nombre o RUN, o bien filtre los resultados por alguno de los
  criterios disponibles:
  <div class="row">
    <div class="col-md-6">
      <div class="search-wrapper">
        <input type="text" [(ngModel)]="searchTerm" class="search-input" placeholder="Buscar solicitudes"
          (keyup)="searchSolicitud($event)" />
        <button class="btn btn-sm btn-warning" (click)="clearSearch(); loadSolicitudes()"
          *ngIf="searchTerm != ''">Limpiar
          búsqueda</button>
      </div>
    </div>
  </div>
</div>

<!-- FILTROS -->
<div class="no-print">
  <div class="filtros row my-3" *ngIf="isAdmin">
    <div class="col">
      <strong>Estado</strong>
      <select name="fEstado" class="form-control" [(ngModel)]='filter.estado' (change)='filterGrid()'>
        <option value="" selected>Todos</option>
        <option *ngFor="let e of settings.estados" [value]="e.id">{{e.glosa}}</option>

      </select>
    </div>
    <div class="col">
      <strong>Región de Atención</strong>
      <select name="fRegion" class="form-control" [(ngModel)]='filter.region' (change)='filterGrid()'>
        <option value="" selected>Todos</option>
        <option *ngFor="let region of regiones" [value]="region.codigo_region">
          {{region.nombre_region}}
        </option>
      </select>
    </div>
    <div class="col">
      <strong>Organización</strong>
      <select name="fOrganizacion" class="form-control" [(ngModel)]='filter.organizacion' (change)='filterGrid()'>
        <option value="" selected>Todos</option>
        <option *ngFor="let organizacion of organizaciones" [value]="organizacion.id_organizacion">
          {{organizacion.nombre}}
        </option>
      </select>
    </div>
    <div class="col-md-2 text-center">
      <br />
      <button *ngIf="filtering" class="btn btn-warning" (click)='cleanFilters(); loadSolicitudes()'>Ver Todo</button>
    </div>
  </div>
  <h4 *ngIf="filterNotFound && !loading && solicitudes.length ==0" class="text-center m-3 p-3">No se encontraron
    solicitudes </h4>
</div>

<div *ngIf="!loading && searchResults.length > 0  && searchTerm.trim() !== ''" class="p-2 my-2 alert-info no-print"
  role="alert">
  Resultados para su búsqueda: <strong>{{ searchTerm}}</strong>
</div>

<!-- TABLA PLANA -->
<div class="row no-print" *ngIf="!filterNotFound && (solicitudes && solicitudes.length > 0)">
  <div class="col">
    Mostrando de {{desde}} a {{hasta}} de {{ total }} solicitudes
  </div>
  <div class="col-3 text-right">
    <!-- BUSCADOR SEARCH  -->
    <button class="btn btn-sm btn-primary mr-2" (click)="prevPage()" [disabled]="page == 1">Anterior</button>
    <button class="btn btn-sm btn-primary mr-2" (click)="nextPage()" [disabled]="page == totalPages">Siguiente</button>

  </div>
</div>
<div class="table-container mt-2">
  <table class="table table-bordered row-border">
    <thead *ngIf="(solicitudes && solicitudes.length > 0)">
      <tr>
        <th>#</th>
        <th>Fecha Solicitud</th>
        <th>Solicitante</th>
        <th>Nombre Extranjero</th>
        <th>Teléfono Contacto</th>
        <th>Estado</th>
        <th>RUN Asignado</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="9">
          <h5 class="text-center m-3 p-3"> <img src="assets/loading.gif" class="mb-3" /> <br /><small>Cargando
              Solicitudes</small></h5>
        </td>
      </tr>
      <tr *ngFor="let item of solicitudes" (id)="item.id">
        <td>{{item.id}}</td>
        <td>{{(item.fecha_creacion.includes('T')) ?
          item.fecha_creacion.split("T")[0].split("-").reverse().join("/") :
          item.fecha_creacion.split(" ")[0].split("-").reverse().join("/")}}
        </td>
        <td>{{item.nombre.toUpperCase()}}</td>
        <td>{{item.titulo.toUpperCase()}}</td>
        <td class="text-center">{{item.telefono == '' ? 'SIN TELEFONO' : item.telefono }}</td>
        <td>{{item.estadoLabel}}</td>
        <td class='run'>
          <span *ngIf="item.run_ciudadano">{{item.run_ciudadano}}</span>
          <span *ngIf="!item.run_ciudadano"><i class="bi bi-hourglass"></i></span>
        </td>
        <ng-template [ngIf]="isAdmin" [ngIfElse]="tdLinkNoAdmin">
          <td class="rowLink no-print"><a href="javascript:;" [routerLink]="['/solicitudes/' + item.id]"
              routerLinkActive="router-link-active"><i class="bi bi-file-earmark-text"></i> </a></td>
        </ng-template>

        <ng-template #tdLinkNoAdmin>
          <td class="rowLink no-print"><a href="javascript:;" [routerLink]="['/solicitudes/' + item.id]"
              routerLinkActive="router-link-active"><i class="bi bi-file-earmark-text"></i></a></td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>

<div class="paginator" *ngIf="(solicitudes && solicitudes.length > 0)">
  <div class="row no-print">
    <div class="col-9">
      Página {{ page }} de {{ totalPages}}
    </div>
    <div class="col-3 text-right">
      <button class="btn btn-sm btn-primary mr-2" (click)="prevPage()" [disabled]="page == 1">Anterior</button>
      <button class="btn btn-sm btn-primary mr-2" (click)="nextPage()"
        [disabled]="page == totalPages">Siguiente</button>
    </div>
  </div>
</div>