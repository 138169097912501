<button class="btn btn-sm no-print" style='float: right;' (click)="exportGrid()"><i class="bi bi-box-arrow-down"></i>
  Descarga archivo
</button>
<div class="title">
  <h3>Solicitudes {{historicos? 'Cerradas': 'Ingresadas'}}</h3>
  {{usrData.organizacion.nombre}}
</div>
<div class="actions no-print" *ngIf="canCreate">
  <a #usrBtn routerLink="/solicitar" href="javascript:;" class="btn btn-primary btn-sm">
    <i class="bi bi-file-earmark-text"></i> Ingresar Solicitud
  </a>
</div>


  <div class="no-print">
  <h5>Buscar Solicitudes</h5>
  Utilice el buscador para encontrar una solicitud por nombre o RUN 
  <div class="row">
    <div class="col-md-6">
      <div class="search-wrapper">
        <input type="text" [(ngModel)]="searchTerm" class="search-input" placeholder="Buscar solicitudes"
          (keyup)="searchSolicitud($event)" />
        <button class="btn btn-sm btn-warning" (click)="clearSearch(); loadSolicitudes()"
          *ngIf="searchTerm != ''">Limpiar
          búsqueda</button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading && searchResults.length > 0" class="p-2 my-2 alert-info no-print" role="alert">
  Resultados para su búsqueda: <strong>{{ searchTerm}}</strong>
</div>

<h4 *ngIf="filterNotFound && !loading && solicitudes.length ==0 || !loading && solicitudes.length == 0"
    class="text-center m-3 p-3">No se encontraron solicitudes</h4>

<!-- TABLA PLANA -->
<div class="row no-print" *ngIf="!filterNotFound && (solicitudes && solicitudes.length > 0)">
  <div class="col">
    Mostrando de {{desde}} a {{hasta}} de {{ total }} solicitudes
  </div>
  <div class="col-3 text-right">
    <!-- BUSCADOR SEARCH  -->
    <button class="btn btn-sm btn-primary mr-2" (click)="prevPage()" [disabled]="page == 1">Anterior</button>
    <button class="btn btn-sm btn-primary mr-2" (click)="nextPage()" [disabled]="page == totalPages">Siguiente</button>

  </div>
</div>
<div class="table-container mt-2">
  <table class="table table-bordered row-border">
    <thead *ngIf="(solicitudes && solicitudes.length > 0)">
      <tr>
        <th>#</th>
        <th>Fecha Solicitud</th>
        <th *ngIf="isAdmin">Organización</th>
        <th>Solicitante</th>
        <th>Tipo</th>
        <th>Nombre Extranjero</th>
        <th>Fecha Enrolamiento</th>
        <th>RUN Asignado</th>
        <th>&nbsp;</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="loading">
        <td colspan="9">
          <h5 class="text-center m-3 p-3"> <img src="assets/loading.gif" class="mb-3" /> <br /><small>Cargando
              Solicitudes</small></h5>
        </td>
      </tr>
      <tr *ngFor="let item of solicitudes" (id)="item.id" [ngClass]="{urgent: item.tipo == 'urgente'}">
        <td>{{item.id}}</td>
        <td>{{(item.fecha_creacion.includes('T')) ?
          item.fecha_creacion.split("T")[0].split("-").reverse().join("/") :
          item.fecha_creacion.split(" ")[0].split("-").reverse().join("/")}}
        </td>
        <td *ngIf='isAdmin'>{{item.organizacion.nombre}}</td>
        <td>{{item.autorLabel.toUpperCase()}}</td>
        <td class="solType">{{item.tipo}}</td>
        <td>{{item.titulo.toUpperCase()}}</td>
        <td>{{item.fecha_atencion ? item.fecha_atencion.split(" ")[0].split("-").reverse().join("/") : ' '}}</td>
        <td class='run' (mouseenter)="showAgendaPopup = false">
          <span *ngIf="item.run_ciudadano">{{item.run_ciudadano}}</span>
          <span *ngIf="!item.run_ciudadano">

            <i *ngIf="item.tipo=='urgente'" class="bi bi-hourglass"></i>

          </span>
        </td>
        <ng-template [ngIf]="isAdmin" [ngIfElse]="tdLinkNoAdmin">
          <td (mouseenter)="showAgendaPopup = false" class="rowLink no-print"><a href="javascript:;"
              [routerLink]="['/solicitudes/' + item.id]" routerLinkActive="router-link-active"><i
                class="bi bi-file-earmark-text"></i> </a></td>
        </ng-template>

        <ng-template #tdLinkNoAdmin>
          <td (mouseenter)="showAgendaPopup = false" class="rowLink no-print"><a href="javascript:;"
              [routerLink]="['/solicitudes/' + item.id]" routerLinkActive="router-link-active"><i
                class="bi bi-file-earmark-text"></i></a></td>
        </ng-template>
      </tr>
    </tbody>
  </table>
</div>

<div class="paginator no-print" *ngIf="(solicitudes && solicitudes.length > 0)">
  <div class="row">
    <div class="col-9">
      Página {{ page }} de {{ totalPages}}
    </div>
    <div class="col-3 text-right">
      <button class="btn btn-sm btn-primary mr-2" (click)="prevPage()" [disabled]="page == 1">Anterior</button>
      <button class="btn btn-sm btn-primary mr-2" (click)="nextPage()"
        [disabled]="page == totalPages">Siguiente</button>
    </div>
  </div>
</div>



<div class=" showAgenda" [ngClass]="{show: showAgendaPopup}">
  <div class="card">
    <div class="card-body">
      <strong>Atención agendada:</strong><br />
      {{agendaFecha}} - {{agendaHora}}<br />
      Oficina: {{agendaDireccion}}
    </div>
  </div>
  <div class="tipDown"></div>
</div>