import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {

  constructor( public router: Router) { }


  opts: any = [];

  
  uniqueByRuta(items: any[]) {
    const seen = new Set();
    return items.filter(item => {
      if (!seen.has(item.ruta)) {
        seen.add(item.ruta);
        return true;
      }
      return false;
    });
  }



  processOpt() {
    let oStr = "";
    let bopt = sessionStorage.boOpt;
    for(let x=bopt.length; x>=0; x--){
      oStr += bopt.charAt(x);
    }
    this.opts = JSON.parse(decodeURIComponent(atob(oStr)));

    this.opts = this.uniqueByRuta(this.opts);
  }
  
  ngOnInit(): void {this.processOpt()}

}
