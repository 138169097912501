<div class="loginContainer">
    <div class="loginPanel">
        <img src="assets/logo-registro-civil-300-jpg.jpg?timestamp=20180610163906" alt="Registro Civil" />

        <h5 class="text-center">Verificación</h5>
        <p>Se le ha enviado un código a su Correo Electrónico</p>
        <form>
            <input type="text" class="form-control" placeholder="Ingrese el código" (keyup)="enterCode($event)">
            <button [ngClass]="!nextOK ? 'disabled' : ''" (click)="valCode($event)"
                class="btn btn-primary btn-reset">Continuar</button>
        </form>
        <div class="actions">
            <a routerLink="/resetpass" routerLinkActive="active">Volver</a>
        </div>

        <div class="mensajes mt-3" [ngClass]="{'active': showMsg}">{{uiMsg}}</div>
    </div>
</div>