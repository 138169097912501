import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { data } from 'jquery';
@Injectable()
export class SettingsService {
  constructor(private http: HttpClient) { }

  loadSettings(): Observable<any> {
    const resp = this.http.get(`${environment.boBackendAPI}/settings`);

    return resp;

  }

  postSettings(settings: any) {
    let serviceURL: string = environment.boBackendAPI;
    switch (settings.type) {
      case 'estado':
        serviceURL += '/estados';
        break;
      case 'rol':
        serviceURL += '/roles';
        break;
      case 'materia':
        serviceURL += '/materias';
        break;
      case 'menu':
        serviceURL += '/menu';
        break;
      case 'voucher':
        serviceURL += '/voucher';
        break;
      case 'motivo-urgencia':
        serviceURL += '/motivos-urgencia';
        break;
      default:
        break;
    }

    console.log(serviceURL, settings);

    const resp = this.http.post(serviceURL, settings)
      .pipe(map((data) => data || {}));
    return resp;
  }


  updateSettings(settings: any) {
    const resp = this.http.put(`${environment.boBackendAPI}/settings`, settings)
      .pipe(map((data) => data || {}));
    return resp;
  }

}
